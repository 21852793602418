import React, { useState } from 'react';
import { IInheritHasPerson } from '../../../../../../../Interfaces/IPerson';
import { IInherit } from '../../../../../../../Interfaces/IInherit';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { InheritPerson } from '../InheritPerson';
import { IRequiredFiles } from '../InheritDocument';
import { IPersonHasDocument } from '../../../../../../../Interfaces/IPersonHasDocument';
import { checkIsDisabled } from '../../../../../../core/getDisabledHelper_Inherit';

interface IProps {
  index: number;
  currentPerson: IInheritHasPerson;
  inheritObject: IInherit;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  setIsOpenNew?: Function;
}

export const StrangersRow: React.FC<IProps> = ({
  index,
  currentPerson,
  inheritObject,
  personArray,
  setPersonArray,
  personHasDocument,
  setPersonHasDocument,
  setIsOpenNew,
}) => {
  const [editPerson, setEditPerson] = useState(currentPerson);
  //
  const [isDeath, setIsDeath] = useState(false);
  const [isOpen, setIsOpen] = useState(setIsOpenNew !== undefined);

  const handleOpen = () => {
    setEditPerson(currentPerson);
    setIsOpen(true);
  };

  const handleClose = () => {
    if (setIsOpenNew !== undefined) {
      setIsOpenNew(false);
    }
    setIsOpen(false);
  };

  const handleRemove = () => {
    setPersonArray([
      ...personArray.filter(x => x.idPerson !== currentPerson.idPerson),
    ]);
  };

  const handleSave = () => {
    const testObject = personArray.find(
      x => x.idPerson === editPerson.idPerson
    );

    if (testObject === undefined) {
      setPersonArray([...personArray, { ...editPerson, isStranger: true }]);
    } else {
      setPersonArray([
        ...personArray.map(x =>
          x.idPerson === editPerson.idPerson ? editPerson : x
        ),
      ]);
    }
    handleClose();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth='lg' fullWidth>
        <DialogContent>
          <InheritPerson
            currentPerson={editPerson}
            setCurrentPerson={setEditPerson}
            inheritObject={inheritObject}
            personArray={personArray}
            personHasDocument={personHasDocument}
            setPersonHasDocument={setPersonHasDocument}
            requiredFiles={[] as IRequiredFiles[]}
            title='Sonstige Person'
            showDeathSettings={true}
            setIsDeath={setIsDeath}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>
            Abbruch
          </Button>
          <Button
            variant='contained'
            disabled={checkIsDisabled(inheritObject, isDeath, editPerson)}
            onClick={handleSave}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{currentPerson.FirstName}</TableCell>
        <TableCell>{currentPerson.LastName}</TableCell>
        <TableCell>
          {currentPerson.Birthday === undefined ||
          currentPerson.Birthday === '' ? (
            <>k.A.</>
          ) : (
            new Date(currentPerson.Birthday).toLocaleDateString()
          )}
        </TableCell>
        <TableCell>
          <IconButton onClick={handleOpen}>
            <Edit />
          </IconButton>
          <IconButton onClick={handleRemove}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
