import React, { useState } from 'react';
import { IInheritHasPerson } from '../../../../../../Interfaces/IPerson';
import { IChildren } from '../../../../../../Interfaces/IChildren';
import { IPersonHasDocument } from '../../../../../../Interfaces/IPersonHasDocument';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { IRequiredFiles } from '../Components/InheritDocument';
import { checkIsDisabled } from '../../../../../core/getDisabledHelper_Inherit';
import { InheritPerson } from '../Components/InheritPerson';
import { IInherit } from '../../../../../../Interfaces/IInherit';
import { foundParent } from '../../../../../../services/dialog.service';

interface IProps {
  inheritObject: IInherit;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  handleNext: Function;
  handleBack: Function;
}

export const Parents: React.FC<IProps> = ({
  inheritObject,
  personArray,
  setPersonArray,
  childrenArray,
  setChildrenArray,
  personHasDocument,
  setPersonHasDocument,
  handleNext,
  handleBack,
}) => {
  const [firstParent, setFirstParent] = useState(
    foundParent(true, personArray, childrenArray)
  );
  const [secondParent, setSecondParent] = useState(
    foundParent(false, [...personArray, firstParent], childrenArray)
  );
  const [documentArray, setDocumentArray] = useState<IPersonHasDocument[]>([]);
  //
  const [isDeathFirstParent, setIsDeathFirstParent] = useState(
    firstParent.DateOfDeath !== null
  );
  const [isDeathSecondParent, setIsDeathSecondParent] = useState(
    secondParent.DateOfDeath !== null
  );
  //
  const [isPageFirstParent, setIsPageFirstParent] = useState(true);

  const getIsDisabled = () => {
    return isPageFirstParent
      ? checkIsDisabled(inheritObject, isDeathFirstParent, firstParent)
      : checkIsDisabled(inheritObject, isDeathSecondParent, secondParent);
  };

  const handleSubmit = () => {
    if (isPageFirstParent) {
      setIsPageFirstParent(false);
    } else {
      let currentPersonArray = [...personArray];
      const foundFirstParend = personArray.find(
        x => x.idPerson === firstParent.idPerson
      );
      const foundSecondParend = personArray.find(
        x => x.idPerson === secondParent.idPerson
      );
      const parentAsChild = childrenArray.find(x => x.Children_idPerson === -1);

      if (foundFirstParend === undefined) {
        currentPersonArray.push(firstParent);
      } else {
        currentPersonArray = currentPersonArray.map(x =>
          x.idPerson === firstParent.idPerson ? firstParent : x
        );
      }

      if (foundSecondParend === undefined) {
        currentPersonArray.push(secondParent);
      } else {
        currentPersonArray = currentPersonArray.map(x =>
          x.idPerson === secondParent.idPerson ? secondParent : x
        );
      }

      setPersonHasDocument([
        ...personHasDocument.filter(
          x =>
            x.idPerson !== firstParent.idPerson &&
            x.idPerson !== secondParent.idPerson
        ),
        ...documentArray,
      ]);

      setPersonArray([...currentPersonArray]);

      if (parentAsChild === undefined) {
        const tmpNewObject = {
          Parent1_idPerson: firstParent.idPerson,
          Parent2_idPerson: secondParent.idPerson,
          Children_idPerson: -1,
          isAdopted: false,
          Marriage_idMarriage: null,
        } as IChildren;

        setChildrenArray([...childrenArray, tmpNewObject]);
      }

      if (isDeathFirstParent && isDeathSecondParent) {
        handleNext();
      } else {
        handleNext(7);
      }
    }
  };

  return (
    <>
      <Box sx={{ mt: 5 }} />
      <Typography variant='h5'>
        Erfassen der Eltern des Erblassers / der Erblasserin
      </Typography>

      <Box sx={{ mt: 5 }} />

      <Collapse in={isPageFirstParent} appear={false}>
        <InheritPerson
          personArray={personArray}
          key='firstParent-1'
          currentPerson={firstParent}
          setCurrentPerson={setFirstParent}
          inheritObject={inheritObject}
          personHasDocument={documentArray}
          setPersonHasDocument={setDocumentArray}
          title='Erstes Elternteil'
          showDeathSettings
          requiredFiles={
            isDeathFirstParent
              ? ([
                  {
                    Title: 'Sterbeurkunde',
                    isOptional: false,
                    idPersonDocumentType: 1,
                  },
                ] as IRequiredFiles[])
              : []
          }
          setIsDeath={setIsDeathFirstParent}
        />
      </Collapse>

      <Collapse in={!isPageFirstParent}>
        <InheritPerson
          personArray={personArray}
          key='firstParent-2'
          currentPerson={secondParent}
          setCurrentPerson={setSecondParent}
          inheritObject={inheritObject}
          personHasDocument={documentArray}
          setPersonHasDocument={setDocumentArray}
          title='Zweites Elternteil'
          showDeathSettings
          requiredFiles={
            isDeathSecondParent
              ? ([
                  {
                    Title: 'Sterbeurkunde',
                    isOptional: false,
                    idPersonDocumentType: 1,
                  },
                ] as IRequiredFiles[])
              : []
          }
          setIsDeath={setIsDeathSecondParent}
        />
      </Collapse>

      <Box sx={{ mt: 10 }} />
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          variant='outlined'
          onClick={() =>
            isPageFirstParent ? handleBack() : setIsPageFirstParent(true)
          }
          sx={{ mr: 1 }}
        >
          Zurück
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          onClick={handleSubmit}
          variant='contained'
          disabled={getIsDisabled()}
        >
          Weiter
        </Button>
      </Box>
    </>
  );
};
