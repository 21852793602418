import { useQuery } from '@tanstack/react-query';
import { IDocument } from '../Interfaces/IDocument';
import type { ICompanyFormationOption } from '../Interfaces/ICompanyFormationOption';
import type { ICompanyFormationQuestion } from '../Interfaces/ICompanyFormationQuestion';
import type { IPropertyType } from '../Interfaces/IPropertyType';
import type { IInventoryOption } from '../Interfaces/IInventoryOption';
import type { IRealEstateUseType } from '../Interfaces/IRealEstateUseType';
import type { IPostcode } from '../Interfaces/IPostcode';

export const customFetcher = async ({
  endpoint,
  method = 'GET',
  body,
  signal,
}: {
  endpoint: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: unknown;
  signal?: AbortSignal;
}) => {
  const apiEndpoint = `/api${endpoint}`;

  const requestOptions: RequestInit = {
    method,
    body: body ? JSON.stringify(body) : null,
    headers: {
      'Content-Type': 'application/json',
      'X-APIKEY-LOCAL': '2YoqmmvC5upGaBedZ12ES8pZYj7FZqY',
    },
    signal,
  };

  const response = await fetch(apiEndpoint, requestOptions);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

export const useGetConfig = () => {
  const { data: config, isLoading: isLoadingConfig } = useQuery({
    queryKey: ['config'],
    queryFn: () =>
      customFetcher({
        endpoint: '/config',
      }),
  });

  return { config, isLoadingConfig };
};

export const useGetDocument = (idDocument?: string) => {
  const {
    data: document,
    isLoading: isLoadingDocument,
    refetch: refetchDocument,
  } = useQuery<IDocument>({
    queryKey: ['document', idDocument],
    queryFn: () => customFetcher({ endpoint: `/document/${idDocument}` }),
    enabled: !!idDocument,
  });

  return { document, isLoadingDocument, refetchDocument };
};

export const useGetCompanyFormationOptions = () => {
  const {
    data: companyFormationOptions,
    isLoading: isLoadingCompanyFormationOptions,
  } = useQuery<ICompanyFormationOption[]>({
    queryKey: ['companyFormationOptions'],
    queryFn: () => customFetcher({ endpoint: '/companyformationoption' }),
  });

  return { companyFormationOptions, isLoadingCompanyFormationOptions };
};

export const useGetCompanyFormationQuestions = () => {
  const {
    data: companyFormationQuestions,
    isLoading: isLoadingCompanyFormationQuestions,
  } = useQuery<ICompanyFormationQuestion[]>({
    queryKey: ['companyFormationQuestions'],
    queryFn: () => customFetcher({ endpoint: '/companyformationquestion' }),
  });

  return {
    companyFormationQuestions,
    isLoadingCompanyFormationQuestions,
  };
};

export const useGetLanguageSection = (idLanguageSection: number) => {
  const {
    data: languageSection,
    isLoading: isLoadingLanguageSection,
    refetch: refetchLanguageSection,
  } = useQuery({
    queryKey: ['languageSection', idLanguageSection],
    queryFn: () =>
      customFetcher({ endpoint: `/languagesection/${idLanguageSection}` }),
  });

  return { languageSection, isLoadingLanguageSection, refetchLanguageSection };
};

export const useGetPropertyTypes = () => {
  const { data: propertyTypes, isLoading: isLoadingPropertyTypes } = useQuery<
    IPropertyType[]
  >({
    queryKey: ['propertyTypes'],
    queryFn: () => customFetcher({ endpoint: '/propertytype' }),
  });

  return { propertyTypes, isLoadingPropertyTypes };
};

export const useGetInventoryOptions = () => {
  const { data: inventoryOptions, isLoading: isLoadingInventoryOptions } =
    useQuery<IInventoryOption[]>({
      queryKey: ['inventoryOptions'],
      queryFn: () => customFetcher({ endpoint: '/inventoryoption' }),
    });

  return { inventoryOptions, isLoadingInventoryOptions };
};

export const useGetRealEstateUseTypes = () => {
  const { data: realEstateUseTypes, isLoading: isLoadingRealEstateUseTypes } =
    useQuery<IRealEstateUseType[]>({
      queryKey: ['realEstateUseTypes'],
      queryFn: () => customFetcher({ endpoint: '/realestateusetype' }),
    });

  return { realEstateUseTypes, isLoadingRealEstateUseTypes };
};

export const useGetPublicPostcode = (selectedPostcode: string) => {
  const { data: postcode, isLoading: isLoadingPostcode } = useQuery<IPostcode>({
    queryKey: ['postcode', selectedPostcode],
    queryFn: () =>
      customFetcher({ endpoint: `/postcode/public/${selectedPostcode}` }),
  });

  return { postcode, isLoadingPostcode };
};

export const useGetPostcode = (selectedPostcode?: number) => {
  const { data: postcode, isLoading: isLoadingPostcode } = useQuery<IPostcode>({
    queryKey: ['postcode', selectedPostcode],
    queryFn: () => customFetcher({ endpoint: `/postcode/${selectedPostcode}` }),
    enabled: !!selectedPostcode,
  });

  return { postcode, isLoadingPostcode };
};

export const useContactInviteContactValidate = (token: string) => {
  const {
    data: contactInviteContactValidate,
    isLoading: isLoadingContactInviteContactValidate,
  } = useQuery({
    queryKey: ['contactInviteContactValidate', token],
    queryFn: () =>
      customFetcher({ endpoint: `/contactinvite/contact/validate/${token}` }),
  });

  return {
    contactInviteContactValidate,
    isLoadingContactInviteContactValidate,
  };
};

export const useContactInviteLegalTransactionValidate = (token: string) => {
  const {
    data: contactInviteLegalTransactionValidate,
    isLoading: isLoadingContactInviteLegalTransactionValidate,
  } = useQuery({
    queryKey: ['contactInviteLegalTransactionValidate', token],
    queryFn: () =>
      customFetcher({
        endpoint: `/contactinvite/legaltransaction/validate/${token}`,
      }),
  });

  return {
    contactInviteLegalTransactionValidate,
    isLoadingContactInviteLegalTransactionValidate,
  };
};

export const useRequestNewPassword = (idContact: string, token: string) => {
  const { data: requestNewPassword, isLoading: isLoadingRequestNewPassword } =
    useQuery({
      queryKey: ['requestNewPassword', idContact, token],
      queryFn: () =>
        customFetcher({
          endpoint: `/contact/requestNewPassword/${idContact}/${token}`,
        }),
    });

  return { requestNewPassword, isLoadingRequestNewPassword };
};

export const useGetReverseGeocode = (lat: number, lng: number) => {
  const { data: reverseGeocode, isLoading: isLoadingReverseGeocode } = useQuery(
    {
      queryKey: ['reverseGeocode', lat, lng],
      queryFn: async () => {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`,
          {
            headers: {
              'User-Agent': 'DiNoPublic',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        return response.json();
      },
    }
  );

  return { reverseGeocode, isLoadingReverseGeocode };
};
