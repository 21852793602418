import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { IPerson } from '../../../../../../../Interfaces/IPerson';
import { IPersonHasDocument } from '../../../../../../../Interfaces/IPersonHasDocument';
import { IMortgage } from '../../../../../../../Interfaces/IMortgage';
import { GridBaseData } from '../../../../../../Person/GridBaseData';

interface IProps {
  currentPerson: IPerson;
  setCurrentPerson: Function;

  mortgageObject: IMortgage;
  setMortgageObject?: Function;

  personArray: IPerson[];
  setPersonArray: Function;

  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;

  isDisabled: boolean;
  setIsDisabled: Function;
}

export const getMortgagePerson = (idPerson: number) => {
  return {
    idPerson: idPerson,
    idGender: 4,
    idAcademicTitle: 1,
    idPostcode: -1,
    idCountry: 1,
    FirstName: '',
    LastName: '',
    Surname: null,
    idBusinessForm: 1,
    Email: null,
    PhoneNumber: null,

    Postcode: '',
    City: '',
    Street: '',
    StreetAditional: '',
    StreetNr: '',

    TaxNumber: null,
    IBAN: null,
    BIC: null,
    Bank: null,
  } as IPerson;
};

export const MortgagePerson: React.FC<IProps> = props => {
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [isMinimalRequirementActiv, setIsMinimalRequirementActiv] =
    useState(false);

  useEffect(() => {
    const { idGender, FirstName, LastName, Birthday } = props.currentPerson;
    const allFieldsFilled = idGender && FirstName && LastName && Birthday;
    props.setIsDisabled(!allFieldsFilled);
  }, [props.currentPerson, props.setIsDisabled]);

  return (
    <>
      {props.mortgageObject.idPerson !== props.currentPerson.idPerson && (
        <Dialog open={isOpenWarning} onClose={() => setIsOpenWarning(false)}>
          <DialogContent>
            <Alert severity='warning'>
              Sobald Sie diese Option aktiveren, sind nur noch die Felder{' '}
              <i>Geschlecht</i>, <i>Vorname</i> und <i>Nachname</i> pflicht.
              <br />
              Wir weisen dringlichst darauf hin, dass Sie diese Option nur im
              Sonderfall aktivieren sollten, um eine schnelle und reibungslose
              Abwicklung zu gewährleisetn.
            </Alert>
            <Box sx={{ mt: 2 }} />
            Sollen die Pflichtfelder aktiviert oder deaktiviert werden?
          </DialogContent>

          <DialogActions>
            <Button
              variant='outlined'
              onClick={() => {
                setIsMinimalRequirementActiv(true);
                setIsOpenWarning(false);
              }}
            >
              Deaktivieren
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                setIsMinimalRequirementActiv(false);
                setIsOpenWarning(false);
              }}
            >
              Aktivieren
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Typography variant='h5' sx={{ marginBottom: 3 }}>
        Eigentümerdaten
      </Typography>

      <GridBaseData
        personObject={props.currentPerson}
        personArray={props.personArray}
        setPersonObject={props.setCurrentPerson}
        showDeathSettings={false}
        forceDeathSettings={false}
        titleAdress='Anschrift'
        setIsDeath={() => {}}
        disableEdit={false}
        forceAdress={true}
        disableDeathDateWarning={true}
        disableContactInformations={true}
      />
    </>
  );
};
