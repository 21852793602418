import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IPersonHasDocument } from '../../../../../../../Interfaces/IPersonHasDocument';
import { IPerson } from '../../../../../../../Interfaces/IPerson';
import { IMortgage } from '../../../../../../../Interfaces/IMortgage';
import { MortgageOwnerRow } from './MortageOwnerRow';
import { getMortgagePerson } from './MortgagePerson';
import { GridBaseData } from '../../../../../../Person/GridBaseData';

interface IProps {
  mortgageObject: IMortgage;
  setMortgageObject: Function;
  personArray: IPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
}

const getNextIdPerson = (personArray: IPerson[]): number => {
  let minId = Math.min(...personArray.map(x => x.idPerson)) - 1;
  if (minId > 0) {
    minId = -1;
  }
  return minId;
};

export const MortgageOwnerTable: React.FC<IProps> = props => {
  const formRef = useRef<HTMLFormElement>(null);
  const [isNewOpen, setIsNewOpen] = useState(false);
  const [editNewOwner, setEditNewOwner] = useState<IPerson>(() => {
    return getMortgagePerson(getNextIdPerson(props.personArray));
  });

  const handleAddNew = () => {
    props.setPersonArray([...props.personArray, editNewOwner]);
    setIsNewOpen(false);
  };

  const addNewOwner = () => {
    setEditNewOwner(getMortgagePerson(getNextIdPerson(props.personArray)));
    setIsNewOpen(true);
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleAddNew();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements).reverse();

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  const isFormValid = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Typography variant='h4' mt={6}>
        Eigentümer
      </Typography>

      <Typography variant='body1' sx={{ mt: 6, mb: 2 }}>
        Bitte erfassen Sie alle Eigentümer.
      </Typography>

      {isNewOpen && (
        <Dialog
          open={isNewOpen}
          maxWidth='md'
          fullWidth
          onClose={() => setIsNewOpen(false)}
        >
          <DialogContent>
            <Box sx={{ mt: 2 }} />
            <Typography variant='h5'>Eigentümer hinzufügen</Typography>
            <form ref={formRef}>
              <GridBaseData
                personObject={editNewOwner}
                personArray={props.personArray}
                setPersonObject={setEditNewOwner}
                showDeathSettings={false}
                forceDeathSettings={false}
                titleAdress='Anschrift'
                setIsDeath={() => {}}
                disableEdit={false}
                forceAdress={true}
                disableDeathDateWarning={true}
                disableContactInformations={true}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              onClick={() => {
                setIsNewOpen(false);
              }}
            >
              Abbruch
            </Button>
            <Button
              variant={isFormValid() ? 'contained' : 'outlined'}
              onClick={() => {
                handleOnSave();
              }}
            >
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={'25%'}>Nr.</TableCell>
            <TableCell width={'25%'}>Nachname </TableCell>
            <TableCell width={'25%'}>Vorname</TableCell>
            <TableCell width={'25%'}>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <MortgageOwnerRow
            mortgageObject={props.mortgageObject}
            setMortgageObject={props.setMortgageObject}
            personArray={props.personArray}
            setPersonArray={props.setPersonArray}
            personHasDocument={props.personHasDocument}
            setPersonHasDocument={props.setPersonHasDocument}
          />
        </TableBody>
        <br></br>
        <br></br>
        <Button
          variant='contained'
          onClick={() => addNewOwner()}
          sx={{ float: 'left' }}
        >
          Eigentümer Hinzufügen
        </Button>
        <br></br>
        <br></br>
        <br></br>
      </Table>
    </>
  );
};
