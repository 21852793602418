import React, { useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { MarriageRow } from './MarriageRow';
import { IInheritHasPerson } from '../../../../../../../Interfaces/IPerson';
import { IMarriage } from '../../../../../../../Interfaces/IMarriage';
import { IPersonHasDocument } from '../../../../../../../Interfaces/IPersonHasDocument';
import { IInherit } from '../../../../../../../Interfaces/IInherit';
import { getInheritPerson } from '../../../../../../../services/dialog.service';

interface IProps {
  inheritObject: IInherit;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray: IMarriage[];
  setMarriageArray: Function;
}

export const MarriageOverview: React.FC<IProps> = ({
  inheritObject,
  personArray,
  setPersonArray,
  personHasDocument,
  setPersonHasDocument,
  marriageArray,
  setMarriageArray,
}) => {
  const [openMarriageDialog, setOpenMarriageDialog] = useState(false);
  return (
    <>
      <Typography variant='h5'>Auflistung der Ehe(n)</Typography>

      <Typography variant='body1' sx={{ mt: 2, mb: 3 }}>
        Die Erfassung aller Ehen ist wichtig, um die etweigen Ansprüche von
        (ehemaligen) Ehepartnern, Witwen oder Witwern sowie möglichen Kindern
        aus vorherigen Ehen zu klären.
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={'20%'}>Nummer</TableCell>
            <TableCell width={'20%'}>Verheiratet</TableCell>
            <TableCell width={'20%'}>Geschieden</TableCell>
            <TableCell width={'20%'}>Name</TableCell>
            <TableCell width={'20%'}>Aktionen</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {openMarriageDialog && (
            <MarriageRow
              key='marriage-new'
              idMarriage={-1}
              index={0}
              inheritObject={inheritObject}
              inherit={getInheritPerson(-1, personArray)}
              marriageArray={marriageArray}
              personArray={personArray}
              personHasDocument={personHasDocument}
              setMarriageArray={setMarriageArray}
              setPersonArray={setPersonArray}
              setPersonHasDocument={setPersonHasDocument}
              setIsOpenNew={setOpenMarriageDialog}
            />
          )}

          {marriageArray
            .filter(
              x =>
                x.Person1_idPerson ===
                getInheritPerson(-1, personArray).idPerson
            )
            .sort((a, b) =>
              new Date(a.DateOfMarriage!) > new Date(b.DateOfMarriage!) ? 1 : -1
            )
            .map((x, i) => (
              <MarriageRow
                key={`marriage-${x.idMarriage}`}
                idMarriage={x.idMarriage}
                index={i}
                inheritObject={inheritObject}
                inherit={getInheritPerson(-1, personArray)}
                marriageArray={marriageArray}
                personArray={personArray}
                personHasDocument={personHasDocument}
                setMarriageArray={setMarriageArray}
                setPersonArray={setPersonArray}
                setPersonHasDocument={setPersonHasDocument}
              />
            ))}
        </TableBody>
        <br></br>
        <Button
          variant='contained'
          onClick={() => setOpenMarriageDialog(true)}
          sx={{ float: 'left' }}
        >
          Ehe(n) hinzufügen
        </Button>
      </Table>
    </>
  );
};
