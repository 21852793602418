import React, { useState } from 'react';
import { IInherit } from '../../../../../../../Interfaces/IInherit';
import { IInheritHasPerson } from '../../../../../../../Interfaces/IPerson';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { IPersonHasDocument } from '../../../../../../../Interfaces/IPersonHasDocument';
import { StrangersRow } from './StrangersRow';
import { useTranslation } from 'react-i18next';
import { checkTranslation } from '../../../../../../core/standardFunctions';
import { getInheritPerson } from '../../../../../../../services/dialog.service';

interface IProps {
  inheritObject: IInherit;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
}

export const StrangersTable: React.FC<IProps> = ({
  inheritObject,
  personArray,
  setPersonArray,
  personHasDocument,
  setPersonHasDocument,
}) => {
  const { t } = useTranslation();
  const [newPerson, setNewPerson] = useState(
    getInheritPerson(-1, personArray, true)
  );
  const [isNewOpen, setIsNewOpen] = useState(false);

  const handleOpenNew = () => {
    setNewPerson(getInheritPerson(-1, personArray, true));
    setIsNewOpen(true);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{checkTranslation(t, 'number', 'Nr.')}</TableCell>
            <TableCell>
              {checkTranslation(t, 'first_name', 'Vorname')}
            </TableCell>
            <TableCell>
              {checkTranslation(t, 'last_name', 'Nachname')}
            </TableCell>
            <TableCell>
              {checkTranslation(t, 'birth_date', 'Geburtstag')}
            </TableCell>
            <TableCell>{checkTranslation(t, 'actions', 'Aktionen')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isNewOpen && (
            <StrangersRow
              key={`stranger-new`}
              index={0}
              currentPerson={newPerson}
              inheritObject={inheritObject}
              personArray={personArray}
              setPersonArray={setPersonArray}
              personHasDocument={personHasDocument}
              setPersonHasDocument={setPersonHasDocument}
              setIsOpenNew={setIsNewOpen}
            />
          )}

          {personArray
            .filter(x => x.isStranger)
            .map((x, i) => (
              <StrangersRow
                key={`stranger-${x.idPerson}-${i}`}
                index={i}
                currentPerson={x}
                inheritObject={inheritObject}
                personArray={personArray}
                setPersonArray={setPersonArray}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
              />
            ))}
        </TableBody>
      </Table>
      <br></br>
      <Button
        variant='contained'
        onClick={handleOpenNew}
        sx={{ float: 'left' }}
      >
        {checkTranslation(t, 'add_person', 'Person hinzufügen')}
      </Button>
    </>
  );
};
