import React, { useEffect, useState } from 'react';
import { ICompanyFormation } from '../../../../Interfaces/ICompanyFormation';
import { IShareHolderFullObject } from '../../../../Interfaces/IShareHolder';
import { ICompanyFormationShare } from '../../../../Interfaces/ICompanyFormationShare';
import { ICompanyFormationHasShareHolder } from '../../../../Interfaces/ICompanyFormationHasShareHolder';
import { Alert, Step, StepLabel } from '@mui/material';
import { RestoreProgress } from '../RestoreProgress';
import { SaveProgress } from '../SaveProgress';
import { CustomeSlide } from '../../../core/CustomeSlide';
import Send from '../../../generic/Send';
import { ICompanyFormationAnswer } from '../../../../Interfaces/ICompanyFormationAnswer';
import { CustomCircularProgress } from '../../../generic/CustomCircularProgress';
import { RABaseInformation } from './Childs/RABaseInformation';
import { RABoardMemebers } from './Childs/RABoardMemebers';
import { RARepresentative } from './Childs/RARepresentative';
import { IDocument } from '../../../../Interfaces/IDocument';
import { RASummary } from './Childs/RASummary';
import { RAConstitution } from './Childs/RAConstitution';
import { useTranslation } from 'react-i18next';
import { IConfig } from '../../../../Interfaces/IConfig';
import { DialogFrame } from '../../../core/DialogFrame';
import {
  useGetCompanyFormationOptions,
  useGetCompanyFormationQuestions,
} from '../../../../hooks/data';
import { useGetLanguageSection } from '../../../../hooks/data';

interface IProps {
  configObject: IConfig;
}

export const FoundRegisteredAssociationDialog: React.FC<IProps> = ({
  configObject,
}) => {
  const { t, i18n } = useTranslation();

  const { languageSection, isLoadingLanguageSection } =
    useGetLanguageSection(2);

  const { companyFormationOptions, isLoadingCompanyFormationOptions } =
    useGetCompanyFormationOptions();
  const { companyFormationQuestions, isLoadingCompanyFormationQuestions } =
    useGetCompanyFormationQuestions();

  const [companyFormationObject, setCompanyFormationObject] =
    useState<ICompanyFormation>({
      idCompanyFormation: -1,
      idBusinessForm: 12,
      idLegalTransaction: -1,
      idPostcode: -1,
      CompanyName: '',
      DateOfFormation: new Date(),
      PurposeOfTheCompany: '',
      ShareCapital: 0,
      Street: '',
      StreetNr: '',
      AmountShares: 0,
      ValuePerShare: 0,
      useSampleProtocol: false,
      Remarks: '',
      AddressStreet: null,
      AddressStreetNr: null,
      Postcode_AddressidPostcode: null,
      isFounding: true,
    });
  const [shareHolderArray, setShareHolderArray] = useState<
    IShareHolderFullObject[]
  >([]);
  const [companyFormationShareArray, setCompanyFormationShareArray] = useState<
    ICompanyFormationShare[]
  >([]);
  const [shareHolderRelation, setShareHolderRelation] = useState<
    ICompanyFormationHasShareHolder[]
  >([]);
  const [companyFormationAnswerArray, setCompanyFormationAnswerArray] =
    useState<ICompanyFormationAnswer[]>([]);
  const [documentArray, setDocumentArray] = useState<IDocument[]>([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [lastCurrentPage, setLastCurrentPage] = useState(-1);
  const [txt, setTxt] = useState('');

  const [isImportedFromHandelsregister, setIsImportedFromHandelsregister] =
    useState(false);

  const getDataToSave = () => {
    const tempDataToSave = {
      CurrentPage: currentPage,
      lastCurrentPage: lastCurrentPage,
      CompanyFormationObject: companyFormationObject,
      ShareHolderArray: shareHolderArray,
      ShareHolderRelation: shareHolderRelation,
      DocumentArray: documentArray,
      CompanyFormationShareArray: companyFormationShareArray,
      CompanyFormationAnswerArray: companyFormationAnswerArray,
    };
    return tempDataToSave;
  };

  const restoreSetterFunction = (uplodatedFile: any) => {
    if (
      'CurrentPage' in uplodatedFile &&
      uplodatedFile['CurrentPage'] !== currentPage
    ) {
      setCurrentPage(uplodatedFile['CurrentPage']);
    }
    if ('lastCurrentPage' in uplodatedFile) {
      setLastCurrentPage(uplodatedFile['lastCurrentPage']);
    }
    if (
      'CompanyFormationObject' in uplodatedFile &&
      uplodatedFile['CompanyFormationObject'] !== companyFormationObject
    ) {
      setCompanyFormationObject(uplodatedFile['CompanyFormationObject']);
    }
    if (
      'ShareHolderArray' in uplodatedFile &&
      uplodatedFile['ShareHolderArray'] !== shareHolderArray
    ) {
      setShareHolderArray(uplodatedFile['ShareHolderArray']);
    }
    if (
      'ShareHolderRelation' in uplodatedFile &&
      uplodatedFile['ShareHolderRelation'] !== shareHolderRelation
    ) {
      setShareHolderRelation(uplodatedFile['ShareHolderRelation']);
    }
    if (
      'DocumentArray' in uplodatedFile &&
      uplodatedFile['DocumentArray'] !== documentArray
    ) {
      setDocumentArray(uplodatedFile['DocumentArray']);
    }
    if (
      'CompanyFormationShareArray' in uplodatedFile &&
      uplodatedFile['CompanyFormationShareArray'] !== companyFormationShareArray
    ) {
      setCompanyFormationShareArray(
        uplodatedFile['CompanyFormationShareArray']
      );
    }
    if (
      'CompanyFormationAnswerArray' in uplodatedFile &&
      uplodatedFile['CompanyFormationAnswerArray'] !==
        companyFormationAnswerArray
    ) {
      setCompanyFormationAnswerArray(
        uplodatedFile['CompanyFormationAnswerArray']
      );
    }
  };

  const handleBack = () => {
    setLastCurrentPage(currentPage);
    setCurrentPage(currentPage - 1);
  };
  const handleNext = () => {
    setLastCurrentPage(currentPage);
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (languageSection !== undefined) {
      i18n.addResourceBundle(
        i18n.language,
        'translation',
        languageSection[i18n.language],
        true,
        true
      );
      i18n.changeLanguage(i18n.language);
    }
  }, [i18n.language, languageSection]);

  if (
    isLoadingLanguageSection ||
    isLoadingCompanyFormationOptions ||
    isLoadingCompanyFormationQuestions
  ) {
    return <CustomCircularProgress />;
  }

  if (
    !languageSection ||
    !companyFormationOptions ||
    !companyFormationQuestions
  ) {
    return (
      <Alert severity='warning'>
        Unser Gründungsdialog ist vorübergehend nicht freigeschaltet.
        <br />
        Bitte versuchen Sie es später erneut.
      </Alert>
    );
  }

  return (
    <>
      <DialogFrame
        configObject={configObject}
        title={t('dialog_title')}
        currentPage={currentPage}
        stepperArray={[
          <Step key='founding1' completed={currentPage > 0}>
            <StepLabel>{t('menu_item_1')}</StepLabel>
          </Step>,
          <Step key='founding2' completed={currentPage > 1}>
            <StepLabel>{t('menu_item_2')}</StepLabel>
          </Step>,
          <Step key='founding3' completed={currentPage > 2}>
            <StepLabel>{t('menu_item_3')}</StepLabel>
          </Step>,
          <Step key='founding4' completed={currentPage > 3}>
            <StepLabel>{t('menu_item_4')}</StepLabel>
          </Step>,
          <Step key='founding5' completed={currentPage > 4}>
            <StepLabel>{t('menu_item_5')}</StepLabel>
          </Step>,
        ]}
        headerButtonArray={[
          //<LanguageDropdown/>,
          <RestoreProgress
            key='inherit-import'
            setterFunction={restoreSetterFunction}
          ></RestoreProgress>,
          <SaveProgress
            key='inherit-export'
            idLegaltransaction={1}
            dataToSave={getDataToSave()}
            filename={'Vereinsgründung'}
          ></SaveProgress>,
        ]}
        txt={txt}
      >
        <CustomeSlide
          currentPage={currentPage}
          pageNumber={0}
          lastCurrentPage={lastCurrentPage}
        >
          <RABaseInformation
            setTxt={setTxt}
            currentStep={currentPage}
            companyFormationObject={companyFormationObject}
            setCompanyFormationObject={setCompanyFormationObject}
            handleBack={handleBack}
            handleNext={handleNext}
            isImportedFromHandelsregister={isImportedFromHandelsregister}
            isTranslationEnabled={true}
          />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={1}
          lastCurrentPage={lastCurrentPage}
        >
          <RABoardMemebers
            companyFormationObject={companyFormationObject}
            setCompanyFormationObject={setCompanyFormationObject}
            setTxt={setTxt}
            currentStep={currentPage}
            handleBack={handleBack}
            handleNext={handleNext}
            shareHolderArray={shareHolderArray}
            setShareHolderArray={setShareHolderArray}
            companyFormationShareArray={companyFormationShareArray}
            setCompanyFormationShareArray={setCompanyFormationShareArray}
            shareHolderRelation={shareHolderRelation}
            setShareHolderRelation={setShareHolderRelation}
          />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={2}
          lastCurrentPage={lastCurrentPage}
        >
          <RARepresentative
            setTxt={setTxt}
            currentStep={currentPage}
            companyFormationObject={companyFormationObject}
            setCompanyFormationObject={setCompanyFormationObject}
            shareHolderArray={shareHolderArray}
            setShareHolderArray={setShareHolderArray}
            shareHolderRelation={shareHolderRelation}
            setShareHolderRelation={setShareHolderRelation}
            companyFormationAnswerArray={companyFormationAnswerArray}
            setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
            companyFormationOptionArray={companyFormationOptions}
            companyFormationQuestionArray={companyFormationQuestions}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={3}
          lastCurrentPage={lastCurrentPage}
        >
          <RAConstitution
            handleBack={handleBack}
            handleNext={handleNext}
            documentArray={documentArray}
            setDocumentArray={setDocumentArray}
            companyFormationAnswerArray={companyFormationAnswerArray}
            setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
            companyFormationOptionArray={companyFormationOptions}
            companyFormationQuestionArray={companyFormationQuestions}
          />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={4}
          lastCurrentPage={lastCurrentPage}
        >
          <RASummary
            configObject={configObject}
            handleBack={handleBack}
            handleNext={handleNext}
            companyFormationObject={companyFormationObject}
            setCompanyFormationObject={setCompanyFormationObject}
            companyFormationShareArray={companyFormationShareArray}
            shareHolderArray={shareHolderArray}
            setShareHolderArray={setShareHolderArray}
            shareHolderRelation={shareHolderRelation}
            companyFormationAnswerArray={companyFormationAnswerArray}
            companyFormationOptionArray={companyFormationOptions}
            companyFormationQuestionArray={companyFormationQuestions}
            documentArray={documentArray}
            setDocumentArray={setDocumentArray}
          />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={5}
          lastCurrentPage={lastCurrentPage}
        >
          <Send isTranslationEnabled={true} />
        </CustomeSlide>
      </DialogFrame>
    </>
  );
};
