import React, { useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

interface IProps {
  open: boolean;
  title: string;
  children: React.ReactNode;
  onSave: Function;
  onCancel: Function;
}

export const DialogStructure: React.FC<IProps> = ({
  open,
  title,
  children,
  onSave,
  onCancel,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const onSaveClicked = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      onSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  return (
    <Dialog open={open} maxWidth='lg' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h4' component='div' fontWeight='bold'>
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ marginTop: 1, marginLeft: 2, marginRight: 2 }}>
          <form ref={formRef}>{children}</form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSaveClicked}>Speichern</Button>
        <Button onClick={() => onCancel()}>Abbrechen</Button>
      </DialogActions>
    </Dialog>
  );
};
