import React from 'react';
import { IPurchaseContractHasPerson } from '../../../../../../Interfaces/IPerson';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { checkString } from '../../../../../core/standardFunctions';

interface IProps {
  title: string;
  idPruchaseContractRelationType: number;
  personArray: IPurchaseContractHasPerson[];
}

export const PurchaseContractRelationSummary: React.FC<IProps> = props => {
  return (
    <>
      <Typography variant='h6' sx={{ mt: 5 }}>
        {props.title}
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Steueridentifikationsnummer</TableCell>
            <TableCell>Vorname</TableCell>
            <TableCell>Nachname</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.personArray
            .filter(
              x =>
                x.idPurchaseContractRelationType ===
                props.idPruchaseContractRelationType
            )
            .map(x => (
              <TableRow>
                <TableCell>{checkString(x.TaxNumber)}</TableCell>
                <TableCell>{checkString(x.FirstName)}</TableCell>
                <TableCell>{checkString(x.LastName)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};
