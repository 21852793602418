import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Snackbar,
  Alert,
  useMediaQuery,
  ListItem,
  ListItemButton,
  ListItemText,
  InputAdornment,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

interface IProps {
  setterFunction(uploadObject: any): any;
}

export const RestoreProgress: React.FC<IProps> = props => {
  const isDesktop = useMediaQuery('(min-width:900px)');
  const [openImport, setOpenImport] = React.useState(false);
  const [password, setPassword] = useState('');
  const [currentFile, setCurrentFile] =
    useState<React.ChangeEvent<HTMLInputElement> | null>(null);
  const [wasUploaded, setWasUploaded] = useState(false);
  const [wasUploadedSuccessfully, setWasUploadedSuccessfully] = useState(false);
  const [passwordError, setPasswordError] = useState(false); // New state to track password errors
  const fileUploadElement = React.useRef<HTMLInputElement>(null);
  const [isShowPasswordEnabled, setisShowPasswordEnabled] =
    React.useState(false);

  const handleClickShowPassword = () => setisShowPasswordEnabled(show => !show);

  const handleCloseImport = () => {
    setOpenImport(false);
    setPasswordError(false); // Reset password error on dialog close
  };

  const wrapperHandleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFile(e);
    if (e.target.files !== undefined && e.target.value !== '') {
      setOpenImport(true);
      setPasswordError(false); // Reset password error when new file is chosen
    } else if (e.target.value === '') {
      setOpenImport(false);
    }
  };

  const base64ToBinary = (base64String: string) => {
    const byteCharacters = atob(String(base64String));
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'text/plain' });
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    const CryptoJS = require('crypto-js');
    if (e !== null && e.target.files) {
      const uploadedFile = e.target.files[0];

      if (uploadedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          const outerBlob = base64ToBinary(String(reader.result).split(',')[1]);
          outerBlob.text().then(res => {
            try {
              const bytes = CryptoJS.AES.decrypt(res, password);
              const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
              if (!decryptedText) {
                throw new Error('Decryption failed');
              }
              const uploadObject = JSON.parse(decryptedText);
              props.setterFunction(uploadObject);
              setWasUploadedSuccessfully(true);
              setPasswordError(false);
              handleCloseImport();
            } catch (error) {
              setWasUploadedSuccessfully(false);
              setPasswordError(true);
            } finally {
              setWasUploaded(true);
            }
          });
        };
        reader.readAsDataURL(uploadedFile);
      }
    } else {
      setWasUploadedSuccessfully(false);
      handleCloseImport();
    }
  };

  const antiEventBubbling = (event: any) => {
    event.stopPropagation(); // Unterbinde Event Bubbling
  };

  return (
    <>
      <input
        type='file'
        onChange={wrapperHandleFileUpload}
        hidden={true}
        ref={fileUploadElement}
        accept='.notar'
      />

      {isDesktop ? (
        <Button
          key='restoreProgress-Button'
          color='inherit'
          variant='outlined'
          sx={{ mr: 3 }}
          onClick={() => fileUploadElement.current?.click()}
        >
          Wiederherstellen
        </Button>
      ) : (
        <ListItem key='restoreProgress-Button' disablePadding>
          <ListItemButton onClick={() => fileUploadElement.current?.click()}>
            <ListItemText primary='Wiederherstellen' />
          </ListItemButton>
        </ListItem>
      )}

      <Dialog
        open={openImport}
        onClose={handleCloseImport}
        onClick={antiEventBubbling}
      >
        <DialogTitle variant='h4'>Importieren</DialogTitle>
        <DialogContent>
          <Typography>
            Bitte geben Sie das Passwort zum Entschlüsseln der Datei ein.
          </Typography>
          <TextField
            autoFocus
            margin='dense'
            id='password'
            label='Passwort'
            type={isShowPasswordEnabled ? 'string' : 'password'}
            fullWidth
            required
            variant='standard'
            error={passwordError} // Show error style if there is a password error
            helperText={
              passwordError
                ? 'Falsches Passwort, bitte versuchen Sie es erneut.'
                : ''
            }
            onChange={e => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    edge='end'
                  >
                    {!isShowPasswordEnabled ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImport}>Abbrechen</Button>
          <Button onClick={() => handleFileUpload(currentFile)}>
            Importieren
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={wasUploaded}
        autoHideDuration={6000}
        onClose={() => setWasUploaded(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setWasUploaded(false)}
          severity={wasUploadedSuccessfully ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {wasUploadedSuccessfully
            ? 'Erfolgreich importiert'
            : 'Leider ist ein Fehler aufgetreten'}
        </Alert>
      </Snackbar>
    </>
  );
};
