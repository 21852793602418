import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Alert,
  Box,
  Button,
  Step,
  StepLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ICompanyFormationOption } from '../../../../Interfaces/ICompanyFormationOption';
import { ICompanyFormationQuestion } from '../../../../Interfaces/ICompanyFormationQuestion';
import { ICompanyFormation } from '../../../../Interfaces/ICompanyFormation';
import { IShareHolderFullObject } from '../../../../Interfaces/IShareHolder';
import { ICompanyFormationShare } from '../../../../Interfaces/ICompanyFormationShare';
import { ICompanyFormationHasShareHolder } from '../../../../Interfaces/ICompanyFormationHasShareHolder';
import { ICompanyFormationAnswer } from '../../../../Interfaces/ICompanyFormationAnswer';
import { RestoreProgress } from '../RestoreProgress';
import { SaveProgress } from '../SaveProgress';
import { CustomeSlide } from '../../../core/CustomeSlide';
import { CustomCircularProgress } from '../../../generic/CustomCircularProgress';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RAChangeStart } from './RAChangeStart';
import { RABaseInformation } from '../FoundRegisteredAssociation/Childs/RABaseInformation';
import { RARepresentative } from '../FoundRegisteredAssociation/Childs/RARepresentative';
import { RAChangeSummary } from './RAChangeSummary';
import { IDocument } from '../../../../Interfaces/IDocument';
import { RABoardMemebers } from '../FoundRegisteredAssociation/Childs/RABoardMemebers';
import { RAConstitution } from '../FoundRegisteredAssociation/Childs/RAConstitution';
import { IConfig } from '../../../../Interfaces/IConfig';
import { DialogFrame } from '../../../core/DialogFrame';
import { HandelsregisterSearch } from '../../../generic/HandelsregisterSearch';
import Send from '../../../generic/Send';
import {
  useGetCompanyFormationOptions,
  useGetCompanyFormationQuestions,
} from '../../../../hooks/data';

interface IProps {
  configObject: IConfig;
}

export const RAChange: React.FC<IProps> = ({ configObject }) => {
  const { companyFormationOptions, isLoadingCompanyFormationOptions } =
    useGetCompanyFormationOptions();
  const { companyFormationQuestions, isLoadingCompanyFormationQuestions } =
    useGetCompanyFormationQuestions();

  const [companyFormationObject, setCompanyFormationObject] =
    useState<ICompanyFormation>({
      idCompanyFormation: -1,
      idBusinessForm: 12,
      idLegalTransaction: -1,
      idPostcode: -1,
      CompanyName: '',
      DateOfFormation: new Date(),
      PurposeOfTheCompany: '',
      ShareCapital: 25000,
      Street: '',
      StreetNr: '',
      AmountShares: 1,
      ValuePerShare: 25000,
      useSampleProtocol: false,
      Remarks: '',
      AddressStreet: null,
      AddressStreetNr: null,
      Postcode_AddressidPostcode: null,
      isFounding: false,
    });

  const [documentArray, setDocumentArray] = useState<IDocument[]>([]);
  const [shareHolderArray, setShareHolderArray] = useState<
    IShareHolderFullObject[]
  >([]);
  const [companyFormationShareArray, setCompanyFormationShareArray] = useState<
    ICompanyFormationShare[]
  >([]);
  const [shareHolderRelation, setShareHolderRelation] = useState<
    ICompanyFormationHasShareHolder[]
  >([]);
  const [companyFormationAnswerArray, setCompanyFormationAnswerArray] =
    useState<ICompanyFormationAnswer[]>([]);

  //Bevor den Änderungen
  const [shareHolderArrayOld, setShareHolderArrayOld] = useState<
    IShareHolderFullObject[]
  >([]);
  const [companyFormationShareArrayOld, setCompanyFormationShareArrayOld] =
    useState<ICompanyFormationShare[]>([]);
  const [shareHolderRelationOld, setShareHolderRelationOld] = useState<
    ICompanyFormationHasShareHolder[]
  >([]);
  const [companyFormationAnswerArrayOld, setCompanyFormationAnswerArrayOld] =
    useState<ICompanyFormationAnswer[]>([]);
  const [companyFormationOptionArrayOld, setCompanyFormationOptionArrayOld] =
    useState<ICompanyFormationOption[]>([]);
  const [
    companyFormationQuestionArrayOld,
    setCompanyFormationQuestionArrayOld,
  ] = useState<ICompanyFormationQuestion[]>([]);
  const [companyFormationObjectOld, setCompanyFormationObjectOld] =
    useState<ICompanyFormation>(companyFormationObject);

  const [currentPage, setCurrentPage] = useState(0);
  const [lastCurrentPage, setLastCurrentPage] = useState(-1);
  const [txt, setTxt] = useState('');

  const [stepFounding0, setStepFounding0] = useState('Vereinsänderung');
  const [stepFounding1, setStepFounding1] = useState('Änderungen');
  const [stepFounding2, setStepFounding2] = useState('Abschluss');

  const [
    wasSuccessfullImportedFromhandelsregister,
    setWasSuccessfullImportedFromhandelsregister,
  ] = useState(false);
  const [openImportFromHandelsregister, setOpenImportFromHandelsregister] =
    useState(true);

  const handleOpenImport = () => {
    setWasSuccessfullImportedFromhandelsregister(false);
    setOpenImportFromHandelsregister(true);
  };

  const handleBack = () => {
    setLastCurrentPage(currentPage);
    setCurrentPage(currentPage - 1);
  };
  const handleNext = () => {
    setLastCurrentPage(currentPage);
    setCurrentPage(currentPage + 1);
  };

  const getDataToSave = () => {
    const tempDataToSave = {
      CurrentPage: currentPage,
      LastCurrentPage: lastCurrentPage,
      CompanyFormationObject: companyFormationObject,
      ShareHolderArray: shareHolderArray,
      ShareHolderRelation: shareHolderRelation,
      DocumentArray: documentArray,
      CompanyFormationShareArray: companyFormationShareArray,
      CompanyFormationAnswerArray: companyFormationAnswerArray,
    };
    return tempDataToSave;
  };

  const restoreSetterFunction = (uplodatedFile: any) => {
    if ('CurrentPage' in uplodatedFile) {
      setCurrentPage(uplodatedFile['CurrentPage']);
    }
    if ('LastCurrentPage' in uplodatedFile) {
      setLastCurrentPage(uplodatedFile['LastCurrentPage']);
    }
    if (
      'CompanyFormationObject' in uplodatedFile &&
      uplodatedFile['CompanyFormationObject'] !== companyFormationObject
    ) {
      setCompanyFormationObject(uplodatedFile['CompanyFormationObject']);
    }
    if (
      'ShareHolderArray' in uplodatedFile &&
      uplodatedFile['ShareHolderArray'] !== shareHolderArray
    ) {
      setShareHolderArray(uplodatedFile['ShareHolderArray']);
    }
    if (
      'ShareHolderRelation' in uplodatedFile &&
      uplodatedFile['ShareHolderRelation'] !== shareHolderRelation
    ) {
      setShareHolderRelation(uplodatedFile['ShareHolderRelation']);
    }
    if (
      'DocumentArray' in uplodatedFile &&
      uplodatedFile['DocumentArray'] !== documentArray
    ) {
      setDocumentArray(uplodatedFile['DocumentArray']);
    }
    if (
      'CompanyFormationAnswerArray' in uplodatedFile &&
      uplodatedFile['CompanyFormationAnswerArray'] !==
        companyFormationAnswerArray
    ) {
      setCompanyFormationAnswerArray(
        uplodatedFile['CompanyFormationAnswerArray']
      );
    }
    if (
      'CompanyFormationShareArray' in uplodatedFile &&
      uplodatedFile['CompanyFormationShareArray'] !== companyFormationShareArray
    ) {
      setCompanyFormationShareArray(
        uplodatedFile['CompanyFormationShareArray']
      );
    }
  };

  useEffect(() => {
    setCompanyFormationObjectOld(companyFormationObject);
    setShareHolderArrayOld(shareHolderArray);
    setCompanyFormationShareArrayOld(companyFormationShareArray);
    setShareHolderRelationOld(shareHolderRelation);
    setCompanyFormationAnswerArrayOld(companyFormationAnswerArray);

    if (companyFormationOptions !== undefined) {
      setCompanyFormationOptionArrayOld(companyFormationOptions);
    }
    if (companyFormationQuestions !== undefined) {
      setCompanyFormationQuestionArrayOld(companyFormationQuestions);
    }
  }, [wasSuccessfullImportedFromhandelsregister]);

  if (isLoadingCompanyFormationOptions || isLoadingCompanyFormationQuestions) {
    return <CustomCircularProgress />;
  }

  if (!companyFormationOptions || !companyFormationQuestions) {
    return (
      <Alert severity='warning'>
        Unser Änderungsdialog ist vorübergehend nicht freigeschaltet.
        <br />
        Bitte versuchen Sie es später erneut.
      </Alert>
    );
  }

  return (
    <>
      {!wasSuccessfullImportedFromhandelsregister && (
        <HandelsregisterSearch
          searchLabel='Name des Vereins oder Schlagwörter'
          openImportFromHandelsregister={openImportFromHandelsregister}
          setOpenImportFromHandelsregister={setOpenImportFromHandelsregister}
          wasSuccessfullImportedFromhandelsregister={
            wasSuccessfullImportedFromhandelsregister
          }
          setWasSuccessfullImportedFromhandelsregister={
            setWasSuccessfullImportedFromhandelsregister
          }
          companyFormationObject={companyFormationObject}
          setCompanyFormationObject={setCompanyFormationObject}
          setShareHolderArray={setShareHolderArray}
          shareHolderArray={shareHolderArray}
          setShareHolderRelation={setShareHolderRelation}
          shareHolderRelation={shareHolderRelation}
          registerArt='VR'
        ></HandelsregisterSearch>
      )}

      <DialogFrame
        configObject={configObject}
        title='Formular zur Vereinsänderung'
        currentPage={currentPage}
        stepperArray={[
          <Step key='founding0' completed={currentPage > 0}>
            <StepLabel>{stepFounding0}</StepLabel>
          </Step>,
          <Step key='founding1' completed={currentPage > 1}>
            <StepLabel>{stepFounding1}</StepLabel>
          </Step>,
          <Step key='founding2' completed={currentPage > 2}>
            <StepLabel>{stepFounding2}</StepLabel>
          </Step>,
        ]}
        headerButtonArray={[
          <RestoreProgress
            key='inherit-import'
            setterFunction={restoreSetterFunction}
          ></RestoreProgress>,
          <SaveProgress
            key='inherit-export'
            idLegaltransaction={1}
            dataToSave={getDataToSave()}
            filename={'Firmengründung'}
          ></SaveProgress>,
        ]}
        txt={txt}
      >
        <CustomeSlide
          currentPage={currentPage}
          pageNumber={0}
          lastCurrentPage={lastCurrentPage}
          appear={false}
        >
          <RAChangeStart
            handleNext={handleNext}
            handleBack={handleBack}
            handleOpenImportDialog={handleOpenImport}
          />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={1}
          lastCurrentPage={lastCurrentPage}
        >
          <Box sx={{ mt: 5 }} />
          <Typography variant='h4' sx={{ mb: 3 }}>
            Vereinsänderung
          </Typography>

          <Typography>
            Unter den einzelnen Punkten können Sie Angaben Ihres Vereins ändern.
            Klappen Sie hierfür den passenden Punkt auf und ändern Sie den
            gewünschten Punkt an. Da die nur teilweise Daten im Handelsregister
            verfügbar sind, sind einige Felder leer, bitte füllen Sie diese
            entsprechend aus
            <p />
            <i>
              *Sie können ihren Fortschritt über "Sichern" exportieren und
              jederzeit nahtlos weitermachen
            </i>
          </Typography>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              Änderung Vereinsdaten
            </AccordionSummary>
            <AccordionDetails>
              <RABaseInformation
                setTxt={setTxt}
                currentStep={currentPage}
                companyFormationObject={companyFormationObject}
                setCompanyFormationObject={setCompanyFormationObject}
                handleBack={handleBack}
                handleNext={handleNext}
                buttonsDisabled={true}
                isTranslationEnabled={true}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              Änderung Vorstandsmitglieder
            </AccordionSummary>
            <AccordionDetails>
              <RABoardMemebers
                companyFormationObject={companyFormationObject}
                setCompanyFormationObject={setCompanyFormationObject}
                setTxt={setTxt}
                currentStep={currentPage}
                handleBack={handleBack}
                handleNext={handleNext}
                shareHolderArray={shareHolderArray}
                setShareHolderArray={setShareHolderArray}
                companyFormationShareArray={companyFormationShareArray}
                setCompanyFormationShareArray={setCompanyFormationShareArray}
                shareHolderRelation={shareHolderRelation}
                setShareHolderRelation={setShareHolderRelation}
                buttonsDisabled={true}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2-content'
              id='panel2-header'
            >
              Änderung Vertreungsregelung
            </AccordionSummary>
            <AccordionDetails>
              <RARepresentative
                handleBack={handleBack}
                handleNext={handleNext}
                shareHolderArray={shareHolderArray}
                setShareHolderArray={setShareHolderArray}
                shareHolderRelation={shareHolderRelation}
                setShareHolderRelation={setShareHolderRelation}
                companyFormationAnswerArray={companyFormationAnswerArray}
                setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
                companyFormationOptionArray={companyFormationOptions}
                companyFormationQuestionArray={companyFormationQuestions}
                companyFormationObject={companyFormationObject}
                currentStep={currentPage}
                setCompanyFormationObject={setCompanyFormationObject}
                setTxt={setTxt}
                buttonsDisabled={true}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              Satzungsänderung
            </AccordionSummary>
            <AccordionDetails>
              <RAConstitution
                handleBack={handleBack}
                handleNext={handleNext}
                documentArray={documentArray}
                setDocumentArray={setDocumentArray}
                companyFormationAnswerArray={companyFormationAnswerArray}
                setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
                companyFormationOptionArray={companyFormationOptions}
                companyFormationQuestionArray={companyFormationQuestions}
                buttonsDisabled={true}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2-content'
              id='panel2-header'
            >
              Sonstige Änderungen
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label='Anmerkungen'
                size='small'
                fullWidth
                value={
                  companyFormationObject.Remarks === null
                    ? ''
                    : companyFormationObject.Remarks
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCompanyFormationObject({
                    ...companyFormationObject,
                    Remarks:
                      event.target.value === '' ? null : event.target.value,
                  })
                }
                multiline
                rows={5}
              />
            </AccordionDetails>
          </Accordion>
          <Box sx={{ mt: 5, display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              variant='outlined'
              onClick={() => handleBack()}
              sx={{ mr: 1 }}
            >
              Zurück
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              onClick={() => handleNext()}
              type='submit'
              variant='contained'
            >
              Weiter
            </Button>
          </Box>
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={2}
          lastCurrentPage={lastCurrentPage}
        >
          <RAChangeSummary
            configObject={configObject}
            handleBack={handleBack}
            handleNext={handleNext}
            companyFormationObject={companyFormationObject}
            setCompanyFormationObject={setCompanyFormationObject}
            companyFormationShareArray={companyFormationShareArray}
            shareHolderArray={shareHolderArray}
            setShareHolderArray={setShareHolderArray}
            shareHolderRelation={shareHolderRelation}
            companyFormationAnswerArray={companyFormationAnswerArray}
            companyFormationOptionArray={companyFormationOptions}
            companyFormationQuestionArray={companyFormationQuestions}
            documentArray={documentArray}
            setDocumentArray={setDocumentArray}
            shareHolderArrayOld={shareHolderArrayOld}
            companyFormationShareArrayOld={companyFormationShareArrayOld}
            shareHolderRelationOld={shareHolderRelationOld}
            companyFormationAnswerArrayOld={companyFormationAnswerArrayOld}
            companyFormationOptionArrayOld={companyFormationOptionArrayOld}
            companyFormationQuestionArrayOld={companyFormationQuestionArrayOld}
            companyFormationObjectOld={companyFormationObjectOld}
          />
        </CustomeSlide>
        <CustomeSlide
          currentPage={currentPage}
          pageNumber={3}
          lastCurrentPage={lastCurrentPage}
        >
          <Send />
        </CustomeSlide>
      </DialogFrame>
    </>
  );
};
