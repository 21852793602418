import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ICompanyFormation } from '../../../../../Interfaces/ICompanyFormation';
import { IPostcode } from '../../../../../Interfaces/IPostcode';
import PostcodeSearch from '../../../../generic/PostcodeSearch';
import { businessForms } from '../../../../../constants';

interface IProps {
  companyFormationObject: ICompanyFormation;
  setCompanyFormationObject: Function;
  setShareHolderRelation: Function;
  setCompanyFormationShareArray: Function;
  setCompanyFormationAnswerArray: Function;
  setTxt: Function;
  currentStep: number;
  handleBack: Function;
  handleNext: Function;

  isImportedFromHandelsregister?: boolean;
}

export const handlePostcode = (companyFormationObject: ICompanyFormation) => {
  if (
    companyFormationObject.idPostcode !== null &&
    companyFormationObject.idPostcode > 0 &&
    companyFormationObject.City !== undefined &&
    companyFormationObject.Postcode !== undefined
  ) {
    return {
      idPostcode: companyFormationObject.idPostcode,
      City: companyFormationObject.City,
      Postcode: companyFormationObject.Postcode,
      idCountry: 1,
    } as IPostcode;
  } else {
    return null;
  }
};

const BaseInformation: React.FC<IProps> = props => {
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [postcodeObject, setPostcodeObject] = useState<
    IPostcode | null | undefined
  >(handlePostcode(props.companyFormationObject));

  const setPostcodeObjectWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject) {
      props.setCompanyFormationObject({
        ...props.companyFormationObject,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as ICompanyFormation);
    }
    setPostcodeObject(postcodeObject);
  };

  useEffect(() => {
    localStorage.setItem('wasHereBefore', '1');
    //Runs only on the first render
  }, []);

  const resetText = () => {
    props.setTxt(<></>);
  };

  const setHelpTextCompanyName = () => {
    props.setTxt(
      <>
        Ein Firmenname ist ein rechtlich geschützter Name, der einer Firma oder
        einem Unternehmen gegeben wird. Er ist das offizielle Kennzeichen des
        Unternehmens und dient dazu, es von anderen Unternehmen zu
        unterscheiden. Der Firmenname kann aus Buchstaben, Zahlen und anderen
        Symbolen bestehen und kann auch ein Logo oder ein Slogan enthalten.
        <p />
        Der Firmenname ist in der Regel beim Handelsregister angemeldet und
        geschützt, um sicherzustellen, dass kein anderes Unternehmen denselben
        Namen verwenden kann. Ein Firmenname kann auch eine Marke sein, die dem
        Unternehmen das exklusive Recht gibt, bestimmte Produkte oder
        Dienstleistungen unter diesem Namen zu verkaufen.
        <p />
        Ein guter Firmenname sollte leicht zu merken und einfach auszusprechen
        sein. Er sollte auch die Werte und die Identität des Unternehmens
        widerspiegeln und es den Kunden erleichtern, das Unternehmen zu finden
        und sich mit ihm zu identifizieren.
        <p />
        Insgesamt ist der Firmenname ein wichtiger Bestandteil der Identität
        eines Unternehmens und spielt eine wichtige Rolle bei der Schaffung
        eines Markenbewusstseins und der Schaffung einer starken Kundenbindung.
      </>
    );
  };

  const setHelpTextPurposeOfTheCompany = () => {
    props.setTxt(
      <>
        Der Geschäftszweck einer GmbH definiert die Tätigkeiten, die das
        Unternehmen ausübt, um seinen Unternehmensgegenstand zu erreichen. Es
        handelt sich dabei um den Zweck, für den das Unternehmen gegründet wurde
        und um den es sich in seinem Geschäftsbetrieb dreht. Der Geschäftszweck
        ist ein wesentliches Element des Gesellschaftsvertrags und wird bei der
        Gründung der GmbH festgelegt.
        <p />
        Der Geschäftszweck kann in der Satzung der GmbH oder im Handelsregister
        eingetragen sein. Er kann allgemein gehalten sein, wie zum Beispiel
        "Handel mit Waren aller Art", oder spezifischer, wie zum Beispiel
        "Entwicklung und Vertrieb von Softwarelösungen für Unternehmen". In
        jedem Fall muss der Geschäftszweck präzise genug definiert sein, um
        sicherzustellen, dass das Unternehmen seine Geschäftstätigkeit auf
        legale Weise ausübt.
        <p />
        Das Stammkapital muss bei der Gründung der GmbH in voller Höhe
        eingezahlt werden. Es kann jedoch auch später durch weitere Einlagen
        erhöht werden. Eine Erhöhung des Stammkapitals kann sinnvoll sein, um
        die finanzielle Basis der GmbH zu stärken oder um Investitionen zu
        tätigen.
        <p />
        Der Geschäftszweck kann im Laufe der Zeit geändert werden, falls das
        Unternehmen sein Tätigkeitsfeld erweitern oder ändern möchte. In diesem
        Fall muss eine Änderung der Satzung durchgeführt werden und
        gegebenenfalls eine Eintragung im Handelsregister erfolgen.
        <p />
        Insgesamt ist der Geschäftszweck einer GmbH von großer Bedeutung, da er
        das Herzstück des Unternehmens definiert und den Rahmen für seine
        Geschäftstätigkeit setzt. Eine klare Definition des Geschäftszwecks ist
        daher unerlässlich, um das Unternehmen erfolgreich zu führen und
        rechtliche Konflikte zu vermeiden.
      </>
    );
  };

  /*
    const handleBack = () => {
        props.setCurrentStep(props.currentStep-1);
    }
    const handleNext = () => {
        props.setCurrentStep(props.currentStep+1);
    }
    */

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (props.companyFormationObject.PurposeOfTheCompany === '') {
      setIsOpenWarning(true);
    } else {
      props.handleNext();
    }
  };

  useEffect(() => {
    if (props.companyFormationObject.idBusinessForm === 7) {
      props.setCompanyFormationObject({
        ...props.companyFormationObject,
        ShareCapital: 1000,
      });
    } else if (props.companyFormationObject.idBusinessForm === 8) {
      //props.setCompanyFormationObject({...props.companyFormationObject, ShareCapital: 25000})
    }
  }, [props.companyFormationObject.idBusinessForm]);

  return (
    <>
      <Dialog open={isOpenWarning} onClose={() => setIsOpenWarning(false)}>
        <DialogTitle>Gegenstand des Unternehmens</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sie haben für Ihr Unternehmen keinen Gegenstand angegeben.
            <br />
            Für eine schnellere Abwicklung empfehlen wir dessen Angabe.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleNext()}>Weiter</Button>
          <Button onClick={() => setIsOpenWarning(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>

      <form onSubmit={handleSubmit}>
        <Box sx={{ mt: 5 }} />
        <Typography variant='h4' sx={{ mb: 3 }}>
          Unternehmensdaten
        </Typography>
        <Typography variant='h6' sx={{ mb: 3 }}>
          Grunddaten
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label='Rechtsform'
              size='small'
              type='number'
              fullWidth
              value={props.companyFormationObject.idBusinessForm}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                props.setCompanyFormationObject({
                  ...props.companyFormationObject,
                  idBusinessForm: Number(event.target.value),
                });
                // Resete Anteile, Rollen und Antworten
                props.setShareHolderRelation([]);
                props.setCompanyFormationAnswerArray([]);
                props.setCompanyFormationShareArray([]);
              }}
              select
            >
              {businessForms.map(x => (
                <MenuItem
                  key={`companyFormationObject.idBusinessForm-${x.idBusinessForm}`}
                  value={x.idBusinessForm}
                  disabled={
                    x.idBusinessForm !== 8 &&
                    x.idBusinessForm !== 7 &&
                    x.idBusinessForm !== 3
                  }
                >
                  {x.BusinessForm}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label='Name des Unternehmens (Firma)'
              size='small'
              fullWidth
              value={props.companyFormationObject.CompanyName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.setCompanyFormationObject({
                  ...props.companyFormationObject,
                  CompanyName: event.target.value,
                })
              }
              required
              onFocus={setHelpTextCompanyName}
              onBlur={resetText}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 5 }} />
        <Typography variant='h6' sx={{ mb: 3 }}>
          Anschrift des Unternehmens
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <TextField
              label='Straße'
              size='small'
              fullWidth
              value={props.companyFormationObject.Street}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.setCompanyFormationObject({
                  ...props.companyFormationObject,
                  Street: event.target.value,
                })
              }
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label='Hausnummer'
              size='small'
              fullWidth
              value={props.companyFormationObject.StreetNr}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.setCompanyFormationObject({
                  ...props.companyFormationObject,
                  StreetNr: event.target.value,
                })
              }
              required
            />
          </Grid>

          <Grid item xs={12}>
            <PostcodeSearch
              postcodeObject={postcodeObject}
              setPostcodeObject={setPostcodeObjectWrapper}
              required={true}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 5 }} />
        <Typography variant='h6' sx={{ mb: 3 }}>
          Gegenstand des Unternehmens
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size='small'
              rows={5}
              multiline
              fullWidth
              value={props.companyFormationObject.PurposeOfTheCompany}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.setCompanyFormationObject({
                  ...props.companyFormationObject,
                  PurposeOfTheCompany: event.target.value,
                })
              }
              onFocus={setHelpTextPurposeOfTheCompany}
              onBlur={resetText}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            variant='outlined'
            disabled={props.currentStep === 0}
            onClick={() => props.handleBack()}
            sx={{ mr: 1 }}
          >
            Zurück
          </Button>

          <Box sx={{ flex: '1 1 auto' }} />
          <Button type='submit' variant='contained'>
            Weiter
          </Button>
        </Box>
      </form>
    </>
  );
};
export default BaseInformation;
