import React, { useEffect, useState } from 'react';
import { IShareHolderFullObject } from '../../../../../Interfaces/IShareHolder';
import { ICompanyFormationHasShareHolder } from '../../../../../Interfaces/ICompanyFormationHasShareHolder';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  deDE,
} from '@mui/x-data-grid';
import { Button, Grid, IconButton } from '@mui/material';
import { ShareHolderEdit } from './ShareHolder/ShareHolderEdit';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DialogResponsive } from '../../../../core/DialogResponsive';
import Delete from '@mui/icons-material/Delete';

interface IProps {
  closeDialog?: Function;
  idShareHolderType: number;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
  shareHolderRelation: ICompanyFormationHasShareHolder[];
  setShareHolderRelation: Function;
  disableAddNewButton?: boolean;
  enableAutoslectNewShareHolder?: boolean;
  disableSelectCompanyType?: boolean;
  resetCounter?: number;
  enableOneRole?: boolean;
  isTranslationEnabled?: boolean;
}

export const SelectShareHolderEdit: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([
      ...props.shareHolderRelation
        .filter(x => x.idShareHolderType === props.idShareHolderType)
        .map(x => x.idShareHolder),
    ]);

  const checkTransaltionEnabled = () => {
    if (
      !props.isTranslationEnabled ||
      props.isTranslationEnabled === undefined
    ) {
      return false;
    }
    if (props.isTranslationEnabled) {
      return true;
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'Company',
      headerName: checkTransaltionEnabled() ? t('ra_board_company') : 'Firma',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.idBusinessForm === 1 ? (
          <></>
        ) : (
          <>{params.row.CompanyObject.Company}</>
        ),
    },
    {
      field: 'FirstName',
      headerName: checkTransaltionEnabled() ? t('first_name') : 'Vorname',
      flex: 2,
    },
    {
      field: 'LastName',
      headerName: checkTransaltionEnabled() ? t('last_name') : 'Nachname',
      flex: 2,
    },
    {
      field: 'Actions',
      headerName: checkTransaltionEnabled() ? t('actions') : 'Aktionen',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton
            onClick={() => setIdShareHolder(Number(params.row.idShareHolder))}
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              props.setShareHolderArray(
                props.shareHolderArray.filter(
                  x => x.idShareHolder !== Number(params.row.idShareHolder)
                )
              );
              if (props.closeDialog) {
                props.closeDialog();
              }
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  const [idShareHolder, setIdShareHolder] = useState<number | null>(null);

  const handleClose = () => {
    setIdShareHolder(null);
  };

  const handleSave = () => {
    const tmpArray: ICompanyFormationHasShareHolder[] = [];

    rowSelectionModel.map(x => {
      tmpArray.push({
        idCompanyFormation: -1,
        idCompanyFormationHasShareHolder: -1,
        idShareHolder: Number(x),
        idShareHolderType: props.idShareHolderType,
        FreeText: null,
      } as ICompanyFormationHasShareHolder);
    });

    props.setShareHolderRelation([
      ...props.shareHolderRelation.filter(
        x => x.idShareHolderType !== props.idShareHolderType
      ),
      ...tmpArray,
    ]);
  };

  useEffect(() => {
    handleSave();
  }, [rowSelectionModel]);

  useEffect(() => {
    if (props.resetCounter !== undefined && props.resetCounter !== 0) {
      setRowSelectionModel([]);
    }
  }, [props.resetCounter]);

  return (
    <>
      <DialogResponsive
        open={idShareHolder !== null}
        onClose={() => setIdShareHolder(null)}
        maxWidth='md'
        title={
          idShareHolder === null || idShareHolder === 0 ? (
            <>{checkTransaltionEnabled() ? t('new_person') : 'Neue Person'}</>
          ) : (
            <>
              {checkTransaltionEnabled()
                ? t('edit_person')
                : 'Person bearbeiten'}
            </>
          )
        }
      >
        {idShareHolder !== null && (
          <ShareHolderEdit
            key={`idShareHolder-${idShareHolder}`}
            idShareHolder={idShareHolder}
            handleClose={handleClose}
            shareHolderArray={props.shareHolderArray}
            setShareHolderArray={props.setShareHolderArray}
            //
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            enableAutoslectNewShareHolder={props.enableAutoslectNewShareHolder}
            disableSelectCompanyType={props.disableSelectCompanyType}
          />
        )}
      </DialogResponsive>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {(props.disableAddNewButton === undefined ||
            props.disableAddNewButton === false) && (
            <Button
              variant='contained'
              sx={{ float: 'right' }}
              onClick={() => {
                setIdShareHolder(0);
              }}
            >
              {checkTransaltionEnabled()
                ? t('add_person')
                : 'Person hinzufügen '}
            </Button>
          )}
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            sx={{ mt: 2 }}
            autoHeight
            checkboxSelection
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={props.shareHolderArray}
            onRowSelectionModelChange={newRowSelectionModel => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            columns={columns}
            getRowId={row => row.idShareHolder}
            disableRowSelectionOnClick
            isRowSelectable={(params: GridRowParams) =>
              ((props.enableOneRole !== undefined &&
                props.shareHolderRelation
                  .filter(x => x.idShareHolderType !== 1)
                  .filter(x => x.idShareHolderType !== props.idShareHolderType)
                  .find(x => x.idShareHolder === params.row.idShareHolder) ===
                  undefined) ||
                props.enableOneRole === undefined) &&
              (props.disableSelectCompanyType === undefined ||
                props.disableSelectCompanyType === false ||
                Number(params.row.idBusinessForm) === 1)
            }
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
