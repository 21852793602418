import React, { useState } from 'react';
import {
  IContact,
  IContactFullObject,
} from '../../../../../../Interfaces/IContact';
import { IInheritHasPerson } from '../../../../../../Interfaces/IPerson';
import { IChildren } from '../../../../../../Interfaces/IChildren';
import { IPersonHasDocument } from '../../../../../../Interfaces/IPersonHasDocument';
import { ICompany } from '../../../../../../Interfaces/ICompany';
import { ClientInformation } from '../../../../../generic/ClientInformation';
import { IMarriage } from '../../../../../../Interfaces/IMarriage';
import { uploadFetch } from '../../../../../../hooks/useFetch';
import { Dialog, DialogContent } from '@mui/material';
import { CustomCircularProgress } from '../../../../../generic/CustomCircularProgress';
import {
  IInherit,
  IInheritFullObject,
} from '../../../../../../Interfaces/IInherit';
import { IConfig } from '../../../../../../Interfaces/IConfig';

interface IProps {
  configObject: IConfig;
  inheritObject: IInherit;
  personArray: IInheritHasPerson[];
  marriageArray: IMarriage[];
  childrenArray: IChildren[];
  personHasDocument: IPersonHasDocument[];
  handleNext: Function;
  handleBack: Function;
}

export const Summary: React.FC<IProps> = props => {
  const [isLoading, setIsLoading] = useState(false);

  const [personObject, setPersonObject] = useState<IContact>({
    idContact: -1,
    idCompany: -1,
    idGender: 1,
    idAcademicTitle: 1,
    FirstName: '',
    LastName: '',
    Email: '',
  } as IContact);

  const [companyObject, setCompanyObject] = useState<ICompany>({
    idCompany: -1,
    Company: '',
    idBusinessForm: 1,
    idPostcode: null,
    Street: '',
    StreetNr: '',
    StreetAditional: '',
    PhoneNumber: '',
    Website: '',
    RegisterCourt: null,
    RegisterNumber: null,
    TaxNumber: null,
    SalesTaxNumber: null,
  });

  async function handleSubmit() {
    setIsLoading(true);
    const uploadObject = {
      ...props.inheritObject,
      PersonArray: props.personArray,
      MarriageArray: props.marriageArray,
      ChildrenArray: props.childrenArray,
      PersonHasDocument: props.personHasDocument,
      ContactObject: {
        ...personObject,
        CompanyObject: companyObject,
      } as IContactFullObject,
    } as IInheritFullObject;
    uploadFetch(
      '/legaltransaction/createInherit',
      true,
      uploadObject,
      () => {},
      () => {},
      () => {
        setIsLoading(false);
        props.handleNext();
      }
    );
  }

  async function generatePDF() {
    const uploadObject = {
      ...props.inheritObject,
      PersonArray: props.personArray,
      MarriageArray: props.marriageArray,
      ChildrenArray: props.childrenArray,
      PersonHasDocument: props.personHasDocument,
      ContactObject: {
        ...personObject,
        CompanyObject: companyObject,
      } as IContactFullObject,
    } as IInheritFullObject;
    uploadFetch(
      '/legaltransaction/createPdf',
      true,
      uploadObject,
      () => {},
      () => {},
      () => {}
    );
  }

  return (
    <>
      <Dialog open={isLoading} maxWidth='xs' fullWidth>
        <DialogContent>
          <CustomCircularProgress />
        </DialogContent>
      </Dialog>

      <ClientInformation
        configObject={props.configObject}
        disableHasAccount={
          props.configObject.Module.find(x => x === 'inherit') === undefined
        }
        contactFullObject={
          {
            ...personObject,
            CompanyObject: companyObject,
          } as IContactFullObject
        }
        setContactFullObject={(localFullObject: IContactFullObject) => {
          setPersonObject(localFullObject);
          setCompanyObject(localFullObject.CompanyObject);
        }}
        handleBack={() => props.handleBack()}
        handleSave={handleSubmit}
        isTranslationEnabled={false}
      />
    </>
  );
};
