import React, { useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ChildrenRow } from './ChildrenRow';
import { IInheritHasPerson } from '../../../../../../../Interfaces/IPerson';
import { IPersonHasDocument } from '../../../../../../../Interfaces/IPersonHasDocument';
import { IChildren } from '../../../../../../../Interfaces/IChildren';
import { ChildrenAddDialog } from './ChildrenAddDialog';
import { IInherit } from '../../../../../../../Interfaces/IInherit';
import { IMarriage } from '../../../../../../../Interfaces/IMarriage';

export interface IChildrenTableColDef {
  titleFirstParent: string;
  isSecondParentEnabled: boolean;
}

interface IProps {
  inheritObject: IInherit;
  firstParentArray: IInheritHasPerson[];
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
  marraigeArray: IMarriage[];
}

export const ChildrenTable: React.FC<IProps> = ({
  inheritObject,
  firstParentArray,
  personArray,
  setPersonArray,
  personHasDocument,
  setPersonHasDocument,
  childrenArray,
  setChildrenArray,
  marraigeArray,
}) => {
  const [openChildrenDialog, setOpenChildrenDialog] = useState(false);

  const getLocalChildrenArray = () => {
    return childrenArray
      .filter(
        x =>
          firstParentArray.map(y => y.idPerson).indexOf(x.Parent1_idPerson) > -1
      )
      .filter(children => children.Children_idPerson !== -1);
  };
  console.log(getLocalChildrenArray());

  return (
    <>
      <Typography variant='h5'>Nachkommen</Typography>

      <Typography variant='body1' sx={{ mt: 2, mb: 3 }}>
        Bitte erfassen Sie alle Nachkommen von{' '}
        {firstParentArray
          .map(x => `${x.FirstName} ${x.LastName}`)
          .join(' und ')}
        .
      </Typography>

      {openChildrenDialog && (
        <ChildrenAddDialog
          inheritObject={inheritObject}
          parentPersonArray={firstParentArray}
          setIsOpenNew={setOpenChildrenDialog}
          personArray={personArray}
          setPersonArray={setPersonArray}
          personHasDocument={personHasDocument}
          setPersonHasDocument={setPersonHasDocument}
          childrenArray={childrenArray}
          setChildrenArray={setChildrenArray}
          marriageArray={marraigeArray}
        />
      )}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={'20%'}>Nr.</TableCell>
            <TableCell width={'20%'}>Elternteil 1</TableCell>
            <TableCell width={'20%'}>Elternteil 2</TableCell>
            <TableCell width={'20%'}>Name</TableCell>
            <TableCell width={'20%'}>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getLocalChildrenArray().map((x, idx) => (
            <ChildrenRow
              key={`idChildren-${x.idChildren}`}
              index={idx}
              currentChildren={x}
              inheritObject={inheritObject}
              personArray={personArray}
              setPersonArray={setPersonArray}
              personHasDocument={personHasDocument}
              setPersonHasDocument={setPersonHasDocument}
              childrenArray={childrenArray}
              setChildrenArray={setChildrenArray}
              marriageArray={marraigeArray}
              parentPersonArray={firstParentArray}
            />
          ))}
        </TableBody>
        <br></br>
        <Button
          variant='contained'
          onClick={() => setOpenChildrenDialog(true)}
          sx={{ float: 'left' }}
        >
          Kind Hinzufügen
        </Button>
      </Table>
    </>
  );
};
