import React, { useEffect, useState } from 'react';
import { ICompanyFormation } from '../../../../Interfaces/ICompanyFormation';
import { IShareHolderFullObject } from '../../../../Interfaces/IShareHolder';
import { ICompanyFormationShare } from '../../../../Interfaces/ICompanyFormationShare';
import { ICompanyFormationHasShareHolder } from '../../../../Interfaces/ICompanyFormationHasShareHolder';
import { Alert, Step, StepLabel } from '@mui/material';
import { RestoreProgress } from '../RestoreProgress';
import { SaveProgress } from '../SaveProgress';
import { CustomeSlide } from '../../../core/CustomeSlide';
import BaseInformation from './Childs/BaseInformation';
import { Shares } from './Childs/gmbh/Shares';
import { Representation } from './Childs/gmbh/Representation';
import { Summary } from './Childs/Summary';
import Send from '../../../generic/Send';
import { GbRShareholderWithoutShares } from './Childs/GbR/GbRShareholderWithoutShares';
import { GbRRepresentative } from './Childs/GbR/GbRRepresentative';
import { ICompanyFormationAnswer } from '../../../../Interfaces/ICompanyFormationAnswer';
import { CustomCircularProgress } from '../../../generic/CustomCircularProgress';
import { StartFounding } from './Childs/StartFounding';
import { IConfig } from '../../../../Interfaces/IConfig';
import { DialogFrame } from '../../../core/DialogFrame';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { decode as base64_decode } from 'base-64';
import {
  useGetCompanyFormationOptions,
  useGetCompanyFormationQuestions,
} from '../../../../hooks/data';

export interface IQontoWebHook {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  legal_form: 'ug' | 'gmbh' | string;
  partner: 'qonto';
}
function isIQontoWebHook(obj: any): obj is IQontoWebHook {
  return (
    typeof obj.first_name === 'string' &&
    typeof obj.last_name === 'string' &&
    typeof obj.email === 'string' &&
    typeof obj.phone_number === 'string' &&
    typeof obj.legal_form === 'string' &&
    (obj.legal_form === 'ug' || obj.legal_form === 'gmbh')
  );
}

interface IProps {
  configObject: IConfig;
}

export const FoundingDialog: React.FC<IProps> = ({ configObject }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigation = useNavigate();

  const { companyFormationOptions, isLoadingCompanyFormationOptions } =
    useGetCompanyFormationOptions();

  const { companyFormationQuestions, isLoadingCompanyFormationQuestions } =
    useGetCompanyFormationQuestions();

  const [companyFormationObject, setCompanyFormationObject] =
    useState<ICompanyFormation>({
      idCompanyFormation: -1,
      idBusinessForm: 8,
      idLegalTransaction: -1,
      idPostcode: -1,
      CompanyName: '',
      DateOfFormation: new Date(),
      PurposeOfTheCompany: '',
      ShareCapital: 25000,
      Street: '',
      StreetNr: '',
      AmountShares: 1,
      ValuePerShare: 25000,
      useSampleProtocol: false,
      Remarks: '',
      AddressStreet: null,
      AddressStreetNr: null,
      Postcode_AddressidPostcode: null,
      isFounding: true,
    });
  const [shareHolderArray, setShareHolderArray] = useState<
    IShareHolderFullObject[]
  >([]);
  const [companyFormationShareArray, setCompanyFormationShareArray] = useState<
    ICompanyFormationShare[]
  >([]);
  const [shareHolderRelation, setShareHolderRelation] = useState<
    ICompanyFormationHasShareHolder[]
  >([]);
  const [companyFormationAnswerArray, setCompanyFormationAnswerArray] =
    useState<ICompanyFormationAnswer[]>([]);
  // Qonto
  const [qontoData, setQontoData] = useState<undefined | IQontoWebHook>(
    undefined
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [lastCurrentPage, setLastCurrentPage] = useState(-1);
  const [txt, setTxt] = useState('');

  const [stepFounding0, setStepFounding0] = useState('Unternehmensgründung');
  const [stepFounding1, setStepFounding1] = useState('Grundinformationen');
  const [stepFounding2, setStepFounding2] = useState('Beteiligte');
  const [stepFounding3, setStepFounding3] = useState('Vertretung');
  const [stepFounding4, setStepFounding4] = useState('Abschluss');

  const [isImportedFromHandelsregister, setIsImportedFromHandelsregister] =
    useState(false);

  useEffect(() => {
    if (companyFormationObject.idBusinessForm === 3) {
      setStepFounding2('Gesellschafter');
    }
    if (
      companyFormationObject.idBusinessForm === 7 ||
      companyFormationObject.idBusinessForm === 8
    ) {
      setStepFounding2('Beteiligte');
    }
  });

  const getDataToSave = () => {
    const tempDataToSave = {
      CompanyFormationObject: companyFormationObject,
      CurrentPage: currentPage,
      LastCurrentPage: lastCurrentPage,
      ShareHolderArray: shareHolderArray,
      CompanyFormationShareArray: companyFormationShareArray,
      ShareHolderRelation: shareHolderRelation,
    };
    return tempDataToSave;
  };

  const [dataToSave, setDataToSave] = useState(getDataToSave);

  useEffect(() => {
    setDataToSave(getDataToSave());
  }, [
    companyFormationAnswerArray,
    companyFormationObject,
    companyFormationShareArray,
    shareHolderRelation,
    txt,
    shareHolderArray,
  ]);

  const restoreSetterFunction = (uplodatedFile: any) => {
    if ('CurrentPage' in uplodatedFile) {
      setCurrentPage(uplodatedFile['CurrentPage']);
    }
    if ('LastCurrentPage' in uplodatedFile) {
      setLastCurrentPage(uplodatedFile['LastCurrentPage']);
    }
    if (
      'ShareHolderArray' in uplodatedFile &&
      uplodatedFile['ShareHolderArray'] !== shareHolderArray
    ) {
      setShareHolderArray(uplodatedFile['ShareHolderArray']);
    }
    if (
      'CompanyFormationObject' in uplodatedFile &&
      uplodatedFile['CompanyFormationObject'] !== companyFormationObject
    ) {
      setCompanyFormationObject(uplodatedFile['CompanyFormationObject']);
    }
    if (
      'CompanyFormationShareArray' in uplodatedFile &&
      uplodatedFile['CompanyFormationShareArray'] !== companyFormationShareArray
    ) {
      setCompanyFormationShareArray(
        uplodatedFile['CompanyFormationShareArray']
      );
    }
    if (
      'ShareHolderRelation' in uplodatedFile &&
      uplodatedFile['ShareHolderRelation'] !== shareHolderRelation
    ) {
      setShareHolderRelation(uplodatedFile['ShareHolderRelation']);
    }
  };

  const handleBack = () => {
    setLastCurrentPage(currentPage);
    setCurrentPage(currentPage - 1);
  };
  const handleNext = () => {
    setLastCurrentPage(currentPage);
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    const partner = searchParams.get('partner');
    const fill_data = searchParams.get('fill');

    if (partner === 'qonto' && fill_data !== null) {
      const decoded = base64_decode(fill_data);
      const qontoObject = JSON.parse(decoded);
      const partnerObject = {
        ...qontoObject,
        partner: 'qonto',
      } as IQontoWebHook;
      if (isIQontoWebHook(partnerObject)) {
        setQontoData(partnerObject);
        navigation(`/founding`);

        setCompanyFormationObject({
          ...companyFormationObject,
          idBusinessForm: qontoObject.legal_form === 'ug' ? 7 : 8,
        } as ICompanyFormation);
      }
    }
  }, [searchParams]);

  if (isLoadingCompanyFormationOptions || isLoadingCompanyFormationQuestions) {
    return <CustomCircularProgress />;
  }

  if (!companyFormationOptions || !companyFormationQuestions) {
    return (
      <Alert severity='warning'>
        Unser Gründungsdialog ist vorübergehend nicht freigeschaltet.
        <br />
        Bitte versuchen Sie es später erneut.
      </Alert>
    );
  }

  return (
    <>
      <DialogFrame
        configObject={configObject}
        title='Formular zur Unternehmsgründung'
        currentPage={currentPage}
        stepperArray={[
          <Step key='founding0' completed={currentPage > 0}>
            <StepLabel>{stepFounding0}</StepLabel>
          </Step>,
          <Step key='founding1' completed={currentPage > 1}>
            <StepLabel>{stepFounding1}</StepLabel>
          </Step>,
          <Step key='founding2' completed={currentPage > 2}>
            <StepLabel>{stepFounding2}</StepLabel>
          </Step>,
          <Step key='founding3' completed={currentPage > 3}>
            <StepLabel>{stepFounding3}</StepLabel>
          </Step>,
          <Step key='founding4' completed={currentPage > 4}>
            <StepLabel>{stepFounding4}</StepLabel>
          </Step>,
        ]}
        headerButtonArray={[
          <RestoreProgress
            key='inherit-import'
            setterFunction={restoreSetterFunction}
          ></RestoreProgress>,
          <SaveProgress
            key='inherit-export'
            idLegaltransaction={1}
            dataToSave={dataToSave}
            filename={'Firmengründung'}
          ></SaveProgress>,
        ]}
        txt={txt}
      >
        <CustomeSlide
          currentPage={currentPage}
          pageNumber={0}
          lastCurrentPage={lastCurrentPage}
          appear={false}
        >
          <StartFounding handleNext={handleNext} handleBack={handleBack} />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={1}
          lastCurrentPage={lastCurrentPage}
        >
          <BaseInformation
            setTxt={setTxt}
            currentStep={currentPage}
            companyFormationObject={companyFormationObject}
            setCompanyFormationObject={setCompanyFormationObject}
            setShareHolderRelation={setShareHolderRelation}
            setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
            setCompanyFormationShareArray={setCompanyFormationShareArray}
            handleBack={handleBack}
            handleNext={handleNext}
            isImportedFromHandelsregister={isImportedFromHandelsregister}
          />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={2}
          lastCurrentPage={lastCurrentPage}
        >
          {companyFormationObject.idBusinessForm === 3 && (
            <GbRShareholderWithoutShares
              companyFormationObject={companyFormationObject}
              setCompanyFormationObject={setCompanyFormationObject}
              setTxt={setTxt}
              currentStep={currentPage}
              handleBack={handleBack}
              handleNext={handleNext}
              shareHolderArray={shareHolderArray}
              setShareHolderArray={setShareHolderArray}
              companyFormationShareArray={companyFormationShareArray}
              setCompanyFormationShareArray={setCompanyFormationShareArray}
              shareHolderRelation={shareHolderRelation}
              setShareHolderRelation={setShareHolderRelation}
            />
          )}

          {(companyFormationObject.idBusinessForm === 7 ||
            companyFormationObject.idBusinessForm === 8) && (
            <Shares
              companyFormationObject={companyFormationObject}
              setCompanyFormationObject={setCompanyFormationObject}
              handleBack={handleBack}
              handleNext={handleNext}
              shareHolderArray={shareHolderArray}
              setShareHolderArray={setShareHolderArray}
              companyFormationShareArray={companyFormationShareArray}
              setCompanyFormationShareArray={setCompanyFormationShareArray}
              shareHolderRelation={shareHolderRelation}
              setShareHolderRelation={setShareHolderRelation}
              minShareValue={
                companyFormationObject.idBusinessForm === 7
                  ? 100
                  : companyFormationObject.idBusinessForm === 8
                    ? 25000
                    : companyFormationObject.idBusinessForm === 9
                      ? 50000
                      : 0
              }
              minShareWarning={
                companyFormationObject.idBusinessForm === 7 ? 1000 : undefined
              }
              maxShareWarning={
                companyFormationObject.idBusinessForm === 7 ? 25000 : undefined
              }
            />
          )}
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={3}
          lastCurrentPage={lastCurrentPage}
        >
          {companyFormationObject.idBusinessForm === 3 && (
            <GbRRepresentative
              setTxt={setTxt}
              currentStep={currentPage}
              companyFormationObject={companyFormationObject}
              setCompanyFormationObject={setCompanyFormationObject}
              shareHolderArray={shareHolderArray}
              setShareHolderArray={setShareHolderArray}
              shareHolderRelation={shareHolderRelation}
              setShareHolderRelation={setShareHolderRelation}
              companyFormationAnswerArray={companyFormationAnswerArray}
              setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
              companyFormationOptionArray={companyFormationOptions}
              companyFormationQuestionArray={companyFormationQuestions}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )}
          {(companyFormationObject.idBusinessForm === 7 ||
            companyFormationObject.idBusinessForm === 8) && (
            <Representation
              isCompanyNew
              handleBack={handleBack}
              handleNext={handleNext}
              shareHolderArray={shareHolderArray}
              setShareHolderArray={setShareHolderArray}
              shareHolderRelation={shareHolderRelation}
              setShareHolderRelation={setShareHolderRelation}
              companyFormationAnswerArray={companyFormationAnswerArray}
              setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
              companyFormationOptionArray={companyFormationOptions}
              companyFormationQuestionArray={companyFormationQuestions}
            />
          )}
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={4}
          lastCurrentPage={lastCurrentPage}
        >
          <Summary
            configObject={configObject}
            handleBack={handleBack}
            handleNext={handleNext}
            companyFormationObject={companyFormationObject}
            setCompanyFormationObject={setCompanyFormationObject}
            companyFormationShareArray={companyFormationShareArray}
            shareHolderArray={shareHolderArray}
            setShareHolderArray={setShareHolderArray}
            shareHolderRelation={shareHolderRelation}
            companyFormationAnswerArray={companyFormationAnswerArray}
            setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
            companyFormationOptionArray={companyFormationOptions}
            companyFormationQuestionArray={companyFormationQuestions}
            fill_object={qontoData}
          />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={5}
          lastCurrentPage={lastCurrentPage}
        >
          <Send />
        </CustomeSlide>
      </DialogFrame>
    </>
  );
};
