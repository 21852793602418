import React, { useEffect, useState } from 'react';
import { IShareHolderFullObject } from '../../../../../../Interfaces/IShareHolder';
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { ICompanyFormation } from '../../../../../../Interfaces/ICompanyFormation';
import { ICompanyFormationOption } from '../../../../../../Interfaces/ICompanyFormationOption';
import { ICompanyFormationQuestion } from '../../../../../../Interfaces/ICompanyFormationQuestion';

import { SelectShareHolderEdit } from '../SelectShareHolderEdit';
import { ICompanyFormationHasShareHolder } from '../../../../../../Interfaces/ICompanyFormationHasShareHolder';
import { ICompanyFormationAnswer } from '../../../../../../Interfaces/ICompanyFormationAnswer';
import { getCompanyFormationAnswer } from '../../companyFormation_helpers';

interface IProps {
  companyFormationObject: ICompanyFormation;
  setCompanyFormationObject: Function;
  setTxt: Function;
  currentStep: number;
  handleBack: Function;
  handleNext: Function;
  shareHolderArray: IShareHolderFullObject[];
  shareHolderRelation: ICompanyFormationHasShareHolder[];
  setShareHolderRelation: Function;
  setShareHolderArray: Function;
  companyFormationAnswerArray: ICompanyFormationAnswer[];
  setCompanyFormationAnswerArray: Function;
  companyFormationQuestionArray: ICompanyFormationQuestion[];
  companyFormationOptionArray: ICompanyFormationOption[];
}

export const GbRRepresentative: React.FC<IProps> = props => {
  const [companyRepresentationAnswer, setCompanyRepresentationAnswer] =
    useState(getCompanyFormationAnswer(3, props.companyFormationAnswerArray));
  const [companyHasPropertyAnswer, setCompanyHasPropertyAnswer] = useState(
    getCompanyFormationAnswer(5, props.companyFormationAnswerArray, 15)
  );
  const [companyPropertyAnswer, setCompanyPropertyAnswer] = useState(
    getCompanyFormationAnswer(2, props.companyFormationAnswerArray)
  );

  const [resetCounter, setResetCounter] = useState(0);

  useEffect(() => {
    props.setCompanyFormationAnswerArray([
      companyRepresentationAnswer,
      companyHasPropertyAnswer,
      companyPropertyAnswer,
    ]);
  }, [
    companyRepresentationAnswer,
    companyHasPropertyAnswer,
    companyPropertyAnswer,
  ]);

  const getCompanyFormationOptionDescription = (
    idCompanyFormationQuestion: number,
    idCompanyFormationOption: number
  ) => {
    const companyformationquestionObject =
      props.companyFormationOptionArray.find(
        x => x.idCompanyFormationOption === idCompanyFormationOption
      );

    if (companyformationquestionObject === undefined) {
      return <>Fehler</>;
    } else {
      return String(companyformationquestionObject.Description);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant='h6'>Abstrakte Vertretungsregelung</Typography>
        </Grid>
        <Grid item sm={12}>
          <TextField
            label='Allgemeine Vertretungsregelung'
            size='small'
            type='number'
            fullWidth
            select
            value={
              companyRepresentationAnswer.idCompanyFormationOption < 0
                ? ''
                : companyRepresentationAnswer.idCompanyFormationOption
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              // Setzen des Values
              //setCurrentVertreterregelungsform(Number(event.target.value));
              setCompanyRepresentationAnswer({
                ...companyRepresentationAnswer,
                idCompanyFormationOption: Number(event.target.value),
              });
              // Zurücksetzen der ausgewählten Personen
              props.setShareHolderRelation([
                ...props.shareHolderRelation.filter(
                  x => x.idShareHolderType !== 4
                ),
              ]);
              // ResetCounter für Child-Componente erhöhen
              setResetCounter(resetCounter + 1);
            }}
          >
            {props.companyFormationOptionArray
              .filter(y => y.idCompanyFormationQuestion === 3)
              .map(x => (
                <MenuItem
                  title={String(x.Description)}
                  key={`VertreterregelungsformRepresentative-${x.idCompanyFormationOption}`}
                  value={x.idCompanyFormationOption}
                >
                  {x.CompanyFormationOption}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2 }} />
      <Collapse in={companyRepresentationAnswer.idCompanyFormationOption > 0}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Alert severity='info'>
              {getCompanyFormationOptionDescription(
                3,
                companyRepresentationAnswer.idCompanyFormationOption
              )}
            </Alert>
          </Grid>
        </Grid>
      </Collapse>

      <Box sx={{ mt: 2 }} />
      <Collapse in={companyRepresentationAnswer.idCompanyFormationOption === 2}>
        <Grid container spacing={2}>
          <Grid item sm={8}>
            Wie viele Gesellselschafter/innen dürfen gemeinsam vertreten?
          </Grid>

          <Grid item sm={4}>
            <TextField
              label='Anzahl der Gesellselschafter/innen'
              fullWidth
              type='number'
              size='small'
              error={companyRepresentationAnswer.NumberValue === null}
              value={
                companyRepresentationAnswer.NumberValue === null
                  ? ''
                  : companyRepresentationAnswer.NumberValue
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCompanyRepresentationAnswer({
                  ...companyRepresentationAnswer,
                  NumberValue:
                    event.target.value === ''
                      ? null
                      : Number(event.target.value),
                });
              }}
            />
          </Grid>
        </Grid>
      </Collapse>

      <Box sx={{ mt: 2 }} />
      <Collapse
        in={companyRepresentationAnswer.idCompanyFormationOption === 16}
      >
        <Grid container spacing={2}>
          <Grid item sm={12}>
            Bitte definieren Sie Ihre eigene abstrakte Vertretungsregelung.
            <br />
            Sie sollten ebenfalls erfassen, welche Bestimmungen die ausgewählten
            Gesellschafter/innen in der konkreten Vertreungsregelung
            unterliegen.
          </Grid>

          <Grid item sm={12}>
            <TextField
              label='Ihre Vertretungsregelung'
              fullWidth
              size='small'
              error={companyRepresentationAnswer.TextValue === null}
              value={
                companyRepresentationAnswer.TextValue === null
                  ? ''
                  : companyRepresentationAnswer.TextValue
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCompanyRepresentationAnswer({
                  ...companyRepresentationAnswer,
                  TextValue:
                    event.target.value === '' ? null : event.target.value,
                });
              }}
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
      </Collapse>

      <Box sx={{ mt: 5 }} />
      <Collapse in={companyRepresentationAnswer.idCompanyFormationOption > 0}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant='h6'>Konkrete Vertretungsregelung</Typography>
          </Grid>

          <Grid item sm={12}>
            <Typography>
              Neben der abstrakten Vertretungsregelung können Sie auch eine
              konkrete Vertretungsregelung für Gesellschafter/innen bestimmen.
              <br />
              {companyRepresentationAnswer.idCompanyFormationOption === 3 ? (
                <>
                  Die ausgewählten Gesellschafter/innen dürfen <u>nicht</u>{' '}
                  alleine vertreten.
                </>
              ) : companyRepresentationAnswer.idCompanyFormationOption ===
                16 ? (
                <></>
              ) : (
                <>
                  Die ausgewählten Gesellschafter/innen dürfen{' '}
                  <u>ebenfalls alleine</u> vertreten.
                </>
              )}
            </Typography>
          </Grid>

          <Grid item sm={12}>
            <SelectShareHolderEdit
              key='ceo'
              idShareHolderType={4}
              shareHolderArray={props.shareHolderArray}
              setShareHolderArray={props.setShareHolderArray}
              shareHolderRelation={props.shareHolderRelation}
              setShareHolderRelation={props.setShareHolderRelation}
              disableAddNewButton
              disableSelectCompanyType
              resetCounter={resetCounter}
            />
          </Grid>
        </Grid>
      </Collapse>

      <Box sx={{ mt: 5 }} />
      <Collapse in={companyRepresentationAnswer.idCompanyFormationOption > 0}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant='h6'>Grundbesitz der Gesellschaft</Typography>
          </Grid>
          <Grid item sm={12}>
            <FormControlLabel
              sx={{ float: 'left' }}
              control={
                <Switch
                  checked={
                    companyHasPropertyAnswer.idCompanyFormationOption === 14
                  }
                  onChange={() => {
                    setCompanyHasPropertyAnswer({
                      ...companyHasPropertyAnswer,
                      idCompanyFormationOption:
                        companyHasPropertyAnswer.idCompanyFormationOption === 14
                          ? 15
                          : 14,
                    });
                  }}
                  name='gilad'
                />
              }
              label='Gesellschaft hält Grundbesitz'
            />
          </Grid>
        </Grid>
      </Collapse>

      <Box sx={{ mt: 2 }} />
      <Collapse in={companyHasPropertyAnswer.idCompanyFormationOption === 14}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              id='AngabedesGrundbesitzes'
              multiline
              label='Angabe des Grundbesitzes'
              rows={4}
              fullWidth={true}
              placeholder='Beispiele:
                            Option 1: Grundbuch von Oranienburg (Amtsgericht Oranienburg) Blatt 5511
                            Option 2: Anschrift (Prenzlauer Straße 11 in 16515 Oranienburg)'
              variant='outlined'
              error={companyHasPropertyAnswer.TextValue === null}
              value={
                companyHasPropertyAnswer.TextValue === null
                  ? ''
                  : companyHasPropertyAnswer.TextValue
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyHasPropertyAnswer({
                  ...companyHasPropertyAnswer,
                  TextValue:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>

          <Grid item sm={12}>
            <>
              <TextField
                label='Eintragungsart'
                size='small'
                type='number'
                fullWidth
                select
                value={
                  companyPropertyAnswer.idCompanyFormationOption < 0
                    ? ''
                    : companyPropertyAnswer.idCompanyFormationOption
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  //setCurrentEintragungsart(Number(event.target.value))
                  setCompanyPropertyAnswer({
                    ...companyPropertyAnswer,
                    idCompanyFormationOption: Number(event.target.value),
                  })
                }
              >
                {props.companyFormationOptionArray
                  .filter(y => y.idCompanyFormationQuestion === 2)
                  .map(x => (
                    <MenuItem
                      key={`companyFormationObject.representativeOption-${x}`}
                      value={x.idCompanyFormationOption}
                    >
                      {x.CompanyFormationOption}
                    </MenuItem>
                  ))}
              </TextField>
            </>
          </Grid>
        </Grid>
      </Collapse>

      <Box sx={{ mt: 2 }} />
      <Collapse
        in={
          companyHasPropertyAnswer.idCompanyFormationOption === 14 &&
          companyPropertyAnswer.idCompanyFormationOption > 0
        }
      >
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Alert severity='info'>
              {getCompanyFormationOptionDescription(
                2,
                companyPropertyAnswer.idCompanyFormationOption
              )}
            </Alert>
          </Grid>
        </Grid>
      </Collapse>

      <Box sx={{ mt: 5, display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          variant='outlined'
          onClick={() => props.handleBack()}
          sx={{ mr: 1 }}
        >
          Zurück
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          onClick={() => props.handleNext()}
          disabled={
            companyRepresentationAnswer.idCompanyFormationOption <= 0 ||
            (companyRepresentationAnswer.idCompanyFormationOption === 2 &&
              companyRepresentationAnswer.NumberValue === null) ||
            (companyRepresentationAnswer.idCompanyFormationOption === 16 &&
              companyRepresentationAnswer.TextValue === null) ||
            (companyHasPropertyAnswer.idCompanyFormationOption === 14 &&
              companyHasPropertyAnswer.TextValue === null) ||
            (companyHasPropertyAnswer.idCompanyFormationOption === 14 &&
              companyPropertyAnswer.idCompanyFormationOption <= 0)
          }
          type='submit'
          variant='contained'
        >
          Weiter
        </Button>
      </Box>
    </>
  );
};
