import React, { useState } from 'react';
import { IShareHolderFullObject } from '../../../../../../Interfaces/IShareHolder';
import {
  Box,
  Button,
  Collapse,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { IPostcode } from '../../../../../../Interfaces/IPostcode';
import PostcodeSearch from '../../../../../generic/PostcodeSearch';
import { ICompany } from '../../../../../../Interfaces/ICompany';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import {
  academicTitles,
  businessForms,
  genders,
} from '../../../../../../constants';

interface IProps {
  idShareHolder: number;
  handleClose: Function;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
  rowSelectionModel?: GridRowSelectionModel;
  setRowSelectionModel?: Function;
  enableAutoslectNewShareHolder?: boolean;
  disableSelectCompanyType?: boolean;

  isTranslationEnabled?: boolean;
}

const searchShareHolder = (
  idShareHolder: number,
  shareHolderArray: IShareHolderFullObject[]
) => {
  const foundObject = shareHolderArray.find(
    x => x.idShareHolder === idShareHolder
  );

  if (foundObject !== undefined) {
    return foundObject;
  } else {
    let tmpId = Math.min(...shareHolderArray.map(x => x.idShareHolder)) - 1;

    if (tmpId >= 0) {
      tmpId = -1;
    }

    return {
      idShareHolder: tmpId,
      Birthday: null,
      Birthplace: null,
      Deposit: 0,
      FirstName: '',
      idAcademicTitle: 1,
      idGender: -1,
      idPostcode: -1,
      isPartPayment: false,
      isShareByNonCashResources: false,
      LastName: '',
      Shares: 0,
      Street: '',
      StreetAditional: '',
      StreetNr: '',
      idBusinessForm: 1,
      CompanyObject: {
        idCompany: -1,
        Company: '',
        idBusinessForm: 1,
        idPostcode: -1,
        RegisterCourt: null,
        RegisterNumber: null,
        Street: '',
        StreetAditional: '',
        StreetNr: '',
        TaxNumber: null,
        SalesTaxNumber: null,
      } as ICompany,
    } as IShareHolderFullObject;
  }
};

const handlePostcode = (localObject: IShareHolderFullObject | ICompany) => {
  if (
    localObject.idPostcode !== null &&
    localObject.idPostcode > 0 &&
    localObject.City !== undefined &&
    localObject.Postcode !== undefined
  ) {
    return {
      idPostcode: localObject.idPostcode,
      City: localObject.City,
      Postcode: localObject.Postcode,
      idCountry: 1,
    } as IPostcode;
  } else {
    return null;
  }
};

export const ShareHolderEdit: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const [shareHolder, setShareHolder] = useState(
    searchShareHolder(props.idShareHolder, props.shareHolderArray)
  );

  const [companyObject, setCompanyObject] = useState(shareHolder.CompanyObject);
  //
  const [postcodeObject, setPostcodeObject] = useState<
    IPostcode | null | undefined
  >(handlePostcode(shareHolder));
  const [postcodeObjectCompany, setPostcodeObjectCompany] = useState<
    IPostcode | null | undefined
  >(handlePostcode(companyObject));

  const setPostcodeObjectWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject) {
      setShareHolder({
        ...shareHolder,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as IShareHolderFullObject);
    }
    setPostcodeObject(postcodeObject);
  };
  const setPostcodeObjectCompanyWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject) {
      setCompanyObject({
        ...companyObject,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as ICompany);
    } else {
      setCompanyObject({
        ...companyObject,
        idPostcode: null,
        Postcode: undefined,
        City: undefined,
      } as ICompany);
    }
    setPostcodeObjectCompany(postcodeObject);
  };

  const getIsDisabled = () => {
    return (
      shareHolder.FirstName === '' ||
      shareHolder.LastName === '' ||
      //|| shareHolder.Street === ""
      //|| shareHolder.StreetNr === ""
      shareHolder.idGender < 1 ||
      shareHolder.idPostcode === null ||
      shareHolder.idPostcode < 1 ||
      (shareHolder.idBusinessForm !== 1 &&
        (companyObject.Company === null || companyObject.Company === ''))
    );
  };

  const handleAdd = () => {
    const localShareHolder = {
      ...shareHolder,
      CompanyObject: {
        ...companyObject,
        idBusinessForm: shareHolder.idBusinessForm,
      } as ICompany,
    } as IShareHolderFullObject;

    const testObject = props.shareHolderArray.find(
      x => x.idShareHolder === props.idShareHolder
    );

    if (testObject === undefined) {
      props.setShareHolderArray([...props.shareHolderArray, localShareHolder]);
    } else {
      props.setShareHolderArray([
        ...props.shareHolderArray.map(x =>
          x.idShareHolder === props.idShareHolder ? localShareHolder : x
        ),
      ]);
    }

    if (
      testObject === undefined &&
      props.enableAutoslectNewShareHolder !== undefined &&
      props.enableAutoslectNewShareHolder === true &&
      props.rowSelectionModel !== undefined &&
      props.setRowSelectionModel
    ) {
      props.setRowSelectionModel([
        ...props.rowSelectionModel,
        localShareHolder.idShareHolder,
      ]);
    }

    props.handleClose();
  };
  const currentYear = dayjs();

  const handleDateChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      // Hier das Datum in einen deutschen Datumsstring umwandeln
      const dateString = newValue.format('YYYY-MM-DD'); // Deutsches Datumsformat
      setShareHolder({
        ...shareHolder,
        Birthday: dateString, // Datum als String speichern
      });
    } else {
      setShareHolder({
        ...shareHolder,
        Birthday: '', // Falls Datum gelöscht wird
      });
    }
  };

  const getCompanyEdit = () => {
    return (
      <>
        <Typography sx={{ mt: 2, mb: 1 }}>
          {checkTransaltionEnabled() ? t('ra_board_company') : 'Firma'}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={
                checkTransaltionEnabled() ? t('ra_board_company') : 'Firma'
              }
              size='small'
              required
              fullWidth
              value={companyObject.Company}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyObject({
                  ...companyObject,
                  Company: event.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label='Handelsregisternummer'
              size='small'
              fullWidth
              value={
                companyObject.RegisterNumber === null
                  ? ''
                  : companyObject.RegisterNumber
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyObject({
                  ...companyObject,
                  RegisterNumber:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              label='Handelsregistergericht'
              size='small'
              fullWidth
              value={
                companyObject.RegisterCourt === null
                  ? ''
                  : companyObject.RegisterCourt
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyObject({
                  ...companyObject,
                  RegisterCourt:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label='Steuernummer'
              size='small'
              fullWidth
              value={
                companyObject.TaxNumber === null ? '' : companyObject.TaxNumber
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyObject({
                  ...companyObject,
                  TaxNumber:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='USt-IdNr.'
              size='small'
              fullWidth
              value={
                companyObject.SalesTaxNumber === null
                  ? ''
                  : companyObject.SalesTaxNumber
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyObject({
                  ...companyObject,
                  SalesTaxNumber:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const getBirthday = () => {
    return (
      <>
        <Typography sx={{ mt: 2, mb: 1 }}>
          {checkTransaltionEnabled()
            ? t('ra_birthinformation')
            : 'Geburtstag und -ort'}
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
              <DatePicker
                label='Geburtstag'
                maxDate={currentYear}
                openTo='year'
                views={['year', 'month', 'day']}
                value={
                  shareHolder.Birthday ? dayjs(shareHolder.Birthday) : null
                } // Default auf das heutige Datum, wenn kein Birthday gesetzt
                onChange={handleDateChange}
                slotProps={{
                  textField: { size: 'small', fullWidth: true },
                }}
              />
            </LocalizationProvider>

            {/*<TextField
              label={checkTransaltionEnabled() ? t("birth_date") : "Geburtstag"}
              size="small"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={shareHolder.Birthday === null ? "" : shareHolder.Birthday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({
                  ...shareHolder,
                  Birthday:
                    event.target.value === "" ? null : event.target.value,
                })
              }
            />*/}
          </Grid>
          <Grid item sm={8}>
            <TextField
              label={
                checkTransaltionEnabled() ? t('birth_place') : 'Geburtsort'
              }
              size='small'
              fullWidth
              value={
                shareHolder.Birthplace === null ? '' : shareHolder.Birthplace
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({
                  ...shareHolder,
                  Birthplace:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const getNameEdit = (title: string) => {
    return (
      <>
        <Typography sx={{ mt: 2, mb: 1 }}>{title}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} md={2}>
            <TextField
              label='Titel'
              size='small'
              fullWidth
              value={shareHolder.idAcademicTitle}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({
                  ...shareHolder,
                  idAcademicTitle: Number(event.target.value),
                })
              }
              select
            >
              {academicTitles.map(x => (
                <MenuItem
                  key={`idAcademicTitle-${x.idAcademicTitle}`}
                  value={x.idAcademicTitle}
                >
                  {x.AcademicTitle}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6} md={2}>
            <TextField
              label={checkTransaltionEnabled() ? t('Gender') : 'Geschlecht'}
              size='small'
              fullWidth
              required
              value={shareHolder.idGender < 1 ? '' : shareHolder.idGender}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({
                  ...shareHolder,
                  idGender: Number(event.target.value),
                })
              }
              select
            >
              {genders.map(x => (
                <MenuItem key={`idGender-${x.idGender}`} value={x.idGender}>
                  {x.Gender}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6} md={4}>
            <TextField
              label={checkTransaltionEnabled() ? t('first_name') : 'Vorname(n)'}
              size='small'
              required
              fullWidth
              value={shareHolder.FirstName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({
                  ...shareHolder,
                  FirstName: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              label={checkTransaltionEnabled() ? t('last_name') : 'Nachname'}
              size='small'
              required
              fullWidth
              value={shareHolder.LastName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({ ...shareHolder, LastName: event.target.value })
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const getAdressEdit = (
    title: string,
    currentObject: IShareHolderFullObject | ICompany,
    setCurrentObject: Function,
    isRequired: boolean,
    localPostcodeObject: IPostcode | null | undefined,
    setLocalPostcodeObject: Function,
    key: string = 'normalpostcode'
  ) => {
    return (
      <>
        <Typography sx={{ mt: 2, mb: 1 }}>{title}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              label={checkTransaltionEnabled() ? t('street') : 'Straße'}
              size='small'
              required={isRequired}
              fullWidth
              value={currentObject.Street}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentObject({
                  ...currentObject,
                  Street: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={checkTransaltionEnabled() ? t('number') : 'Hausnummer'}
              size='small'
              required={isRequired}
              fullWidth
              value={currentObject.StreetNr}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentObject({
                  ...currentObject,
                  StreetNr: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <PostcodeSearch
              key={key}
              postcodeObject={localPostcodeObject}
              setPostcodeObject={setLocalPostcodeObject}
              required={true}
              label={checkTransaltionEnabled() ? t('postcode') : 'Postleitzahl'}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const checkTransaltionEnabled = () => {
    if (
      !props.isTranslationEnabled ||
      props.isTranslationEnabled === undefined
    ) {
      return false;
    }
    if (props.isTranslationEnabled) {
      return true;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label='Form'
            size='small'
            fullWidth
            disabled={
              props.disableSelectCompanyType !== undefined &&
              props.disableSelectCompanyType !== false
            }
            value={shareHolder.idBusinessForm}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setShareHolder({
                ...shareHolder,
                idBusinessForm: Number(event.target.value),
                Company:
                  Number(event.target.value) === 1 ? null : shareHolder.Company,
              })
            }
            select
          >
            {businessForms.map(x => (
              <MenuItem
                key={`idBusinessForm-${x.idBusinessForm}`}
                value={x.idBusinessForm}
              >
                {x.BusinessForm}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Collapse in={shareHolder.idBusinessForm === 1}>
        <>
          {getNameEdit('Stammdaten')}
          {getBirthday()}
          {getAdressEdit(
            'Anschrift',
            shareHolder,
            setShareHolder,
            false,
            postcodeObject,
            setPostcodeObjectWrapper
          )}
        </>
      </Collapse>

      <Collapse in={shareHolder.idBusinessForm !== 1}>
        <>
          {getCompanyEdit()}
          {getAdressEdit(
            'Firmensitz',
            companyObject,
            setCompanyObject,
            false,
            postcodeObjectCompany,
            setPostcodeObjectCompanyWrapper,
            'companypostcode'
          )}
          {getNameEdit('Ansprechpartner')}
          {getAdressEdit(
            'Anschrift',
            shareHolder,
            setShareHolder,
            true,
            postcodeObject,
            setPostcodeObjectWrapper
          )}
        </>
      </Collapse>

      <Box sx={{ mt: 5, float: 'right' }}>
        <Button
          variant='outlined'
          sx={{ mr: 2 }}
          onClick={() => props.handleClose()}
        >
          {checkTransaltionEnabled() ? t('cancel') : 'Abbruch'}
        </Button>
        <Button
          variant='contained'
          disabled={getIsDisabled()}
          onClick={handleAdd}
        >
          {checkTransaltionEnabled() ? t('apply') : 'Übernehmen'}
        </Button>
      </Box>
    </>
  );
};
