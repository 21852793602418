import React, { useState } from 'react';
import { Alert, Step, StepLabel } from '@mui/material';
import { ICompanyFormation } from '../../../../Interfaces/ICompanyFormation';
import { IShareHolderFullObject } from '../../../../Interfaces/IShareHolder';
import { ICompanyFormationShare } from '../../../../Interfaces/ICompanyFormationShare';
import { ICompanyFormationHasShareHolder } from '../../../../Interfaces/ICompanyFormationHasShareHolder';
import { ICompanyFormationAnswer } from '../../../../Interfaces/ICompanyFormationAnswer';
import { RestoreProgress } from '../RestoreProgress';
import { SaveProgress } from '../SaveProgress';
import { CustomeSlide } from '../../../core/CustomeSlide';
import { CompanyChangeStart } from './CompanyChangeStart';
import BaseInformation from '../Founding/Childs/BaseInformation';
import { GbRRepresentative } from '../Founding/Childs/GbR/GbRRepresentative';
import { Representation } from '../Founding/Childs/gmbh/Representation';
import Send from '../../../generic/Send';
import { CustomCircularProgress } from '../../../generic/CustomCircularProgress';
import { SummaryCompanyChange } from './SummaryCompanyChange';
import { IConfig } from '../../../../Interfaces/IConfig';
import { DialogFrame } from '../../../core/DialogFrame';
import { HandelsregisterSearch } from '../../../generic/HandelsregisterSearch';
import {
  useGetCompanyFormationOptions,
  useGetCompanyFormationQuestions,
} from '../../../../hooks/data';

interface IProps {
  configObject: IConfig;
}

export const CompanyChange: React.FC<IProps> = ({ configObject }) => {
  const { companyFormationOptions, isLoadingCompanyFormationOptions } =
    useGetCompanyFormationOptions();
  const { companyFormationQuestions, isLoadingCompanyFormationQuestions } =
    useGetCompanyFormationQuestions();

  const [companyFormationObject, setCompanyFormationObject] =
    useState<ICompanyFormation>({
      idCompanyFormation: -1,
      idBusinessForm: 8,
      idLegalTransaction: -1,
      idPostcode: -1,
      CompanyName: '',
      DateOfFormation: new Date(),
      PurposeOfTheCompany: '',
      ShareCapital: 25000,
      Street: '',
      StreetNr: '',
      AmountShares: 1,
      ValuePerShare: 25000,
      useSampleProtocol: false,
      Remarks: '',
      AddressStreet: null,
      AddressStreetNr: null,
      Postcode_AddressidPostcode: null,
      isFounding: false,
    });
  const [shareHolderArray, setShareHolderArray] = useState<
    IShareHolderFullObject[]
  >([]);
  const [companyFormationShareArray, setCompanyFormationShareArray] = useState<
    ICompanyFormationShare[]
  >([]);
  const [shareHolderRelation, setShareHolderRelation] = useState<
    ICompanyFormationHasShareHolder[]
  >([]);
  const [companyFormationAnswerArray, setCompanyFormationAnswerArray] =
    useState<ICompanyFormationAnswer[]>([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [lastCurrentPage, setLastCurrentPage] = useState(-1);
  const [txt, setTxt] = useState('');

  const [stepFounding0, setStepFounding0] = useState('Unternehmensänderung');
  const [stepFounding1, setStepFounding1] = useState('Grundinformationen');
  const [stepFounding2, setStepFounding2] = useState('Vertretung');
  const [stepFounding3, setStepFounding3] = useState('Abschluss');

  const [openImportFromHandelsregister, setOpenImportFromHandelsregister] =
    useState(true);
  const [
    wasSuccessfullImportedFromhandelsregister,
    setWasSuccessfullImportedFromhandelsregister,
  ] = useState(false);

  const handleOpenImportDialog = () => {
    setWasSuccessfullImportedFromhandelsregister(false);
    setOpenImportFromHandelsregister(true);
  };

  const handleBack = () => {
    setLastCurrentPage(currentPage);
    setCurrentPage(currentPage - 1);
  };
  const handleNext = () => {
    setLastCurrentPage(currentPage);
    setCurrentPage(currentPage + 1);
  };

  const getDataToSave = () => {
    const tempDataToSave = {
      CurrentPage: currentPage,
      LastCurrentPage: lastCurrentPage,
      CompanyFormationObject: companyFormationObject,
      ShareHolderArray: shareHolderArray,
      ShareHolderRelation: shareHolderRelation,
      CompanyFormationShareArray: companyFormationShareArray,
    };
    return tempDataToSave;
  };

  const restoreSetterFunction = (uplodatedFile: any) => {
    if (
      'CurrentPage' in uplodatedFile &&
      uplodatedFile['CurrentPage'] !== currentPage
    ) {
      setCurrentPage(uplodatedFile['CurrentPage']);
    }
    if (
      'LastCurrentPage' in uplodatedFile &&
      uplodatedFile['LastCurrentPage'] !== lastCurrentPage
    ) {
      setLastCurrentPage(uplodatedFile['LastCurrentPage']);
    }
    if (
      'CompanyFormationObject' in uplodatedFile &&
      uplodatedFile['CompanyFormationObject'] !== companyFormationObject
    ) {
      setCompanyFormationObject(uplodatedFile['CompanyFormationObject']);
    }
    if (
      'ShareHolderArray' in uplodatedFile &&
      uplodatedFile['ShareHolderArray'] !== shareHolderArray
    ) {
      setShareHolderArray(uplodatedFile['ShareHolderArray']);
    }
    if (
      'ShareHolderRelation' in uplodatedFile &&
      uplodatedFile['ShareHolderRelation'] !== shareHolderRelation
    ) {
      setShareHolderRelation(uplodatedFile['ShareHolderRelation']);
    }
    if ('CompanyFormationShareArray' in uplodatedFile) {
      setCompanyFormationShareArray(
        uplodatedFile['CompanyFormationShareArray']
      );
    }
  };

  if (isLoadingCompanyFormationOptions || isLoadingCompanyFormationQuestions) {
    return <CustomCircularProgress />;
  }

  if (!companyFormationOptions || !companyFormationQuestions) {
    return (
      <Alert severity='warning'>
        Unser Änderungsdialog ist vorübergehend nicht freigeschaltet.
        <br />
        Bitte versuchen Sie es später erneut.
      </Alert>
    );
  }

  return (
    <>
      {!wasSuccessfullImportedFromhandelsregister && (
        <HandelsregisterSearch
          searchLabel='Name der Gesellschaft (Firma) oder Schlagwörter'
          openImportFromHandelsregister={openImportFromHandelsregister}
          setOpenImportFromHandelsregister={setOpenImportFromHandelsregister}
          wasSuccessfullImportedFromhandelsregister={
            wasSuccessfullImportedFromhandelsregister
          }
          setWasSuccessfullImportedFromhandelsregister={
            setWasSuccessfullImportedFromhandelsregister
          }
          companyFormationObject={companyFormationObject}
          setCompanyFormationObject={setCompanyFormationObject}
          setShareHolderArray={setShareHolderArray}
          shareHolderArray={shareHolderArray}
          setShareHolderRelation={setShareHolderRelation}
          shareHolderRelation={shareHolderRelation}
        ></HandelsregisterSearch>
      )}

      <DialogFrame
        configObject={configObject}
        title='Formular zur Unternehmensänderung'
        currentPage={currentPage}
        stepperArray={[
          <Step key='founding0' completed={currentPage > 0}>
            <StepLabel>{stepFounding0}</StepLabel>
          </Step>,
          <Step key='founding1' completed={currentPage > 1}>
            <StepLabel>{stepFounding1}</StepLabel>
          </Step>,
          <Step key='founding2' completed={currentPage > 2}>
            <StepLabel>{stepFounding2}</StepLabel>
          </Step>,
          <Step key='founding3' completed={currentPage > 3}>
            <StepLabel>{stepFounding3}</StepLabel>
          </Step>,
        ]}
        headerButtonArray={[
          <RestoreProgress
            key='inherit-import'
            setterFunction={restoreSetterFunction}
          ></RestoreProgress>,
          <SaveProgress
            key='inherit-export'
            idLegaltransaction={1}
            dataToSave={getDataToSave()}
            filename={'Firmengründung'}
          ></SaveProgress>,
        ]}
        txt={txt}
      >
        <CustomeSlide
          currentPage={currentPage}
          pageNumber={0}
          lastCurrentPage={lastCurrentPage}
          appear={false}
        >
          <CompanyChangeStart
            handleNext={handleNext}
            handleBack={handleBack}
            handleOpenImportDialog={handleOpenImportDialog}
          />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={1}
          lastCurrentPage={lastCurrentPage}
        >
          <BaseInformation
            setTxt={setTxt}
            currentStep={currentPage}
            companyFormationObject={companyFormationObject}
            setCompanyFormationObject={setCompanyFormationObject}
            setShareHolderRelation={setShareHolderRelation}
            setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
            setCompanyFormationShareArray={setCompanyFormationShareArray}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={2}
          lastCurrentPage={lastCurrentPage}
        >
          {companyFormationObject.idBusinessForm === 3 && (
            <GbRRepresentative
              setTxt={setTxt}
              currentStep={currentPage}
              companyFormationObject={companyFormationObject}
              setCompanyFormationObject={setCompanyFormationObject}
              shareHolderArray={shareHolderArray}
              setShareHolderArray={setShareHolderArray}
              shareHolderRelation={shareHolderRelation}
              setShareHolderRelation={setShareHolderRelation}
              companyFormationAnswerArray={companyFormationAnswerArray}
              setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
              companyFormationOptionArray={companyFormationOptions}
              companyFormationQuestionArray={companyFormationQuestions}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )}
          {(companyFormationObject.idBusinessForm === 7 ||
            companyFormationObject.idBusinessForm === 8) && (
            <Representation
              isCompanyNew={false}
              handleBack={handleBack}
              handleNext={handleNext}
              shareHolderArray={shareHolderArray}
              setShareHolderArray={setShareHolderArray}
              shareHolderRelation={shareHolderRelation}
              setShareHolderRelation={setShareHolderRelation}
              companyFormationAnswerArray={companyFormationAnswerArray}
              setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
              companyFormationOptionArray={companyFormationOptions}
              companyFormationQuestionArray={companyFormationQuestions}
            />
          )}
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={3}
          lastCurrentPage={lastCurrentPage}
        >
          <SummaryCompanyChange
            configObject={configObject}
            handleBack={handleBack}
            handleNext={handleNext}
            companyFormationObject={companyFormationObject}
            setCompanyFormationObject={setCompanyFormationObject}
            companyFormationShareArray={companyFormationShareArray}
            shareHolderArray={shareHolderArray}
            setShareHolderArray={setShareHolderArray}
            shareHolderRelation={shareHolderRelation}
            companyFormationAnswerArray={companyFormationAnswerArray}
            companyFormationOptionArray={companyFormationOptions}
            companyFormationQuestionArray={companyFormationQuestions}
          />
        </CustomeSlide>

        <CustomeSlide
          currentPage={currentPage}
          pageNumber={4}
          lastCurrentPage={lastCurrentPage}
        >
          <Send />
        </CustomeSlide>
      </DialogFrame>
    </>
  );
};
