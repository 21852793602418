import dagre from '@dagrejs/dagre';
import { Edge, Node } from 'reactflow';

const NodePositionService = {
  calculatePositions<T = unknown, G = unknown>(props: {
    nodes: Node<T>[];
    edges: Edge<G>[];
    width?: number;
    height?: number;
  }) {
    const { nodes, edges, width = 300, height = 250 } = props;

    const graph = new dagre.graphlib.Graph();

    graph.setGraph({});
    graph.setDefaultEdgeLabel(() => ({}));

    nodes.forEach((node: Node<T>) => {
      graph.setNode(node.id, { width: width, height: height });
    });

    edges.forEach((edge: Edge<G>) => {
      graph.setEdge(edge.source, edge.target);
    });

    dagre.layout(graph);

    return nodes.map((node: Node<T>) => ({
      ...node,
      position: {
        x: graph.node(node.id).x,
        y: graph.node(node.id).y,
      },
    }));
  },
};

export default NodePositionService;
