import React, { useEffect, useState } from 'react';
import { ICompanyFormation } from '../../../../../../../Interfaces/ICompanyFormation';
import { IShareHolderFullObject } from '../../../../../../../Interfaces/IShareHolder';
import { ICompanyFormationShare } from '../../../../../../../Interfaces/ICompanyFormationShare';
import { IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Add, Replay } from '@mui/icons-material';
import { SharesChildRow } from './SharesChildRow';
import { roundFunctionNormal } from '../../help_functions';

export interface IProps {
  shareHolderObject: IShareHolderFullObject;
  companyFormationObject: ICompanyFormation;
  companyFormationShareArray: ICompanyFormationShare[];
  setCompanyFormationShareArray: Function;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
}

export const SharesMainRow: React.FC<IProps> = props => {
  const [ownShares, setOwnShares] = useState(
    props.companyFormationShareArray.filter(
      x => x.idShareHolder === props.shareHolderObject.idShareHolder
    )
  );

  const setShareHolderObject = (shareHolderObject: IShareHolderFullObject) => {
    props.setShareHolderArray([
      ...props.shareHolderArray.map(x =>
        x.idShareHolder === shareHolderObject.idShareHolder
          ? shareHolderObject
          : x
      ),
    ]);
  };

  const handleChangeProz = (values: any) => {
    console.log(values);
    const value = values.floatValue / 100;
    console.log(value);
    if (ownShares.length === 1) {
      const valueTotal =
        Math.round(value * props.companyFormationObject.ShareCapital * 100) /
        100;
      const valuePerShare =
        Math.round((valueTotal / ownShares[0].AmountOfShares) * 1000) / 1000;
      setOwnShares([
        {
          ...ownShares[0],
          ValuePerShare: valuePerShare,
        },
      ]);
    }

    setShareHolderObject({
      ...props.shareHolderObject,
      Shares: value,
    });
  };

  const handleAddShare = () => {
    let minId =
      Math.min(
        ...props.companyFormationShareArray.map(x => x.idCompanyFormationShare)
      ) - 1;

    if (minId >= 0) {
      minId = -1;
    }

    setOwnShares([
      ...ownShares,
      {
        idCompanyFormationShare: minId,
        AmountOfShares: 0,
        idCompanyFormation: -1,
        idShareHolder: props.shareHolderObject.idShareHolder,
        ValuePerShare: 0,
      } as ICompanyFormationShare,
    ]);
  };

  useEffect(() => {
    props.setCompanyFormationShareArray([
      ...props.companyFormationShareArray.filter(
        x => x.idShareHolder !== props.shareHolderObject.idShareHolder
      ),
      ...ownShares,
    ]);
  }, [ownShares]);

  return (
    <>
      <TableRow>
        <TableCell>
          {props.shareHolderObject.idBusinessForm === 1
            ? `${props.shareHolderObject.FirstName} ${props.shareHolderObject.LastName}`
            : `${props.shareHolderObject.CompanyObject.Company}`}
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <NumericFormat
            value={props.shareHolderObject.Shares * 100}
            suffix=' %'
            fixedDecimalScale
            decimalScale={2}
            thousandsGroupStyle='thousand'
            thousandSeparator='.'
            decimalSeparator=','
            fullWidth
            customInput={TextField}
            allowNegative={false}
            label='Gesamteinlage in %'
            size='small'
            onValueChange={handleChangeProz}
            isAllowed={values => {
              const { floatValue } = values;

              if (floatValue && floatValue > 100) {
                return false;
              }
              return true;
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 200 }}>
          <NumericFormat
            value={roundFunctionNormal(
              props.shareHolderObject.Shares *
                props.companyFormationObject.ShareCapital
            )}
            suffix=' €'
            decimalScale={0}
            thousandsGroupStyle='thousand'
            thousandSeparator='.'
            decimalSeparator=','
            fullWidth
            customInput={TextField}
            allowNegative={false}
            label='Gesamteinlage in EUR'
            size='small'
            disabled
            error={
              roundFunctionNormal(
                props.shareHolderObject.Shares *
                  props.companyFormationObject.ShareCapital
              ) %
                1 !==
              0
            }
          />
        </TableCell>

        <TableCell sx={{ width: 100 }}>
          {isNaN(
            ownShares.map(x => x.AmountOfShares).reduce((a, b) => a + b, 0)
          )
            ? 0
            : ownShares.map(x => x.AmountOfShares).reduce((a, b) => a + b, 0)}
        </TableCell>
        <TableCell sx={{ width: 200 }}>
          <NumericFormat
            value={
              ownShares
                .map(x => x.AmountOfShares * x.ValuePerShare)
                .reduce((a, b) => a + b, 0) || 0
            }
            suffix=' €'
            fixedDecimalScale
            decimalScale={2}
            thousandsGroupStyle='thousand'
            thousandSeparator='.'
            decimalSeparator=','
            fullWidth
            customInput={TextField}
            allowNegative={false}
            label='Gesamtnennbetrag Anteil(e)'
            size='small'
            disabled
          />
        </TableCell>

        <TableCell sx={{ width: 90 }}>
          <IconButton onClick={handleAddShare}>
            <Add />
          </IconButton>

          <IconButton
            onClick={() => {
              setShareHolderObject({
                ...props.shareHolderObject,
                Shares: 0,
              });

              setOwnShares(
                ownShares.map(x => ({
                  ...x,
                  AmountOfShares: 1,
                  ValuePerShare: 0,
                }))
              );
            }}
          >
            <Replay />
          </IconButton>

          {/*

          <IconButton
            onClick={() => {
              props.setShareHolderArray(
                props.shareHolderArray.filter(
                  (x) =>
                    x.idShareHolder !== props.shareHolderObject.idShareHolder
                )
              );
              props.setCompanyFormationShareArray(
                props.companyFormationShareArray.filter(
                  (x) =>
                    x.idShareHolder !== props.shareHolderObject.idShareHolder
                )
              );
            }}
          >
            <Delete />
          </IconButton>
                    */}
        </TableCell>
      </TableRow>

      {ownShares.length === 1 ? (
        <SharesChildRow
          index={0}
          key={`childrow-${ownShares[0].idCompanyFormationShare}`}
          shareObject={ownShares[0]}
          companyFormationShareArray={ownShares}
          setCompanyFormationShareArray={setOwnShares}
          totalValue={
            props.shareHolderObject.Shares *
            props.companyFormationObject.ShareCapital
          }
        />
      ) : (
        ownShares.map((x, i) => (
          <SharesChildRow
            index={i}
            key={`childrow-${x.idCompanyFormationShare}`}
            shareObject={x}
            companyFormationShareArray={ownShares}
            setCompanyFormationShareArray={setOwnShares}
            totalValue={
              props.shareHolderObject.Shares *
              props.companyFormationObject.ShareCapital
            }
          />
        ))
      )}
    </>
  );
};
