import React, { useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';

import { Delete, Edit } from '@mui/icons-material';
import { IChildren } from '../../../../../../../Interfaces/IChildren';
import { IInheritHasPerson } from '../../../../../../../Interfaces/IPerson';
import { IPersonHasDocument } from '../../../../../../../Interfaces/IPersonHasDocument';
import { IMarriage } from '../../../../../../../Interfaces/IMarriage';
import { InheritPerson } from '../InheritPerson';
import { IInherit } from '../../../../../../../Interfaces/IInherit';
import { checkIsDisabled } from '../../../../../../core/getDisabledHelper_Inherit';
import { DialogResponsive } from '../../../../../../core/DialogResponsive';
import {
  getInheritPerson,
  getRequiredFiles,
  removeChildrenRecursive,
  removePersonHasDocumentRecursive,
  removePersonRecursive,
} from '../../../../../../../services/dialog.service';

interface IProps {
  index: number;
  inheritObject: IInherit;
  currentChildren: IChildren;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  parentPersonArray: IInheritHasPerson[];
  marriageArray: IMarriage[];
  childrenArray: IChildren[];
  setChildrenArray: Function;
}

export const ChildrenRow: React.FC<IProps> = ({
  index,
  inheritObject,
  currentChildren,
  personArray,
  setPersonArray,
  personHasDocument,
  setPersonHasDocument,
  parentPersonArray,
  marriageArray,
  childrenArray,
  setChildrenArray,
}) => {
  const [currentIChildren, setCurrentIChildren] = useState(currentChildren);
  const [childrenPerson, setChildrenPerson] = useState(
    getInheritPerson(currentIChildren.Children_idPerson, personArray)
  );
  //
  const [editChildren, setEditChildre] = useState(currentIChildren);
  const [editChildrenPerson, setEditChildrenPerson] = useState(childrenPerson);
  //
  const [isDeath, setIsDeath] = useState(false);
  //
  const [openEditChildrenDialog, setOpenEditChildrenDialog] = useState(false);

  const handleOpen = () => {
    setEditChildre(currentIChildren);
    setEditChildrenPerson(childrenPerson);
    setOpenEditChildrenDialog(true);
  };

  const handleSave = () => {
    let newPersonArray = [...personArray];

    newPersonArray = newPersonArray.map(x =>
      x.idPerson === editChildrenPerson.idPerson ? editChildrenPerson : x
    );

    setPersonArray([...newPersonArray]);
    setChildrenArray([
      ...childrenArray.map(x =>
        x.idChildren === editChildren.idChildren ? editChildren : x
      ),
    ]);
    setCurrentIChildren(editChildren);
    setChildrenPerson(editChildrenPerson);
    setOpenEditChildrenDialog(false);
  };

  const handleRemove = () => {
    const currentPersonArray = [...personArray];
    const currentChildrenArray = [...childrenArray];
    const currentPersonHasDocument = [...personHasDocument];

    setPersonArray([
      ...removePersonRecursive(
        childrenPerson.idPerson,
        currentPersonArray,
        currentChildrenArray
      ),
    ]);
    setPersonHasDocument([
      ...removePersonHasDocumentRecursive(
        childrenPerson.idPerson,
        currentPersonHasDocument,
        currentChildrenArray
      ),
    ]);
    setChildrenArray([
      ...removeChildrenRecursive(childrenPerson.idPerson, currentChildrenArray),
    ]);

    setOpenEditChildrenDialog(false);
  };

  return (
    <>
      <DialogResponsive
        open={openEditChildrenDialog}
        onClose={() => {}}
        maxWidth='lg'
        actions={
          <>
            <Button
              variant='outlined'
              onClick={() => setOpenEditChildrenDialog(false)}
            >
              Abbruch
            </Button>
            <Button
              variant='contained'
              disabled={checkIsDisabled(
                inheritObject,
                isDeath,
                editChildrenPerson
              )}
              onClick={handleSave}
            >
              Übernehmen
            </Button>
          </>
        }
        title={`Bearbeiten: ${childrenPerson.FirstName} ${childrenPerson.LastName}`}
      >
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              sx={{ mt: 2 }}
              label='Elternteil(e)'
              select
              value={
                editChildren.Parent1_idPerson === null
                  ? ''
                  : editChildren.Parent2_idPerson === null
                    ? editChildren.Parent1_idPerson
                    : `${editChildren.Parent1_idPerson}_${editChildren.Parent2_idPerson}`
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const splitTest = String(event.target.value).split('_');

                if (splitTest.length === 2) {
                  setEditChildre({
                    ...editChildren,
                    Parent1_idPerson: Number(splitTest[0]),
                    Parent2_idPerson: Number(splitTest[1]),
                  });
                } else {
                  setEditChildre({
                    ...editChildren,
                    Parent1_idPerson: Number(splitTest[0]),
                    Parent2_idPerson: null,
                  });
                }
              }}
              size='small'
              fullWidth
            >
              {marriageArray
                .filter(
                  x =>
                    parentPersonArray
                      .map(x => x.idPerson)
                      .indexOf(x.Person1_idPerson) > -1 ||
                    parentPersonArray
                      .map(x => x.idPerson)
                      .indexOf(x.Person2_idPerson) > -1
                )
                .map(x => {
                  const firstPerson = personArray.find(
                    y => y.idPerson === x.Person1_idPerson
                  );
                  const secondPerson = personArray.find(
                    y => y.idPerson === x.Person2_idPerson
                  );

                  return (
                    <MenuItem
                      key={`idMarraige-${x.idMarriage}`}
                      value={`${x.Person1_idPerson}_${x.Person2_idPerson}`}
                    >
                      <>
                        {firstPerson?.FirstName} {firstPerson?.LastName} und{' '}
                        {secondPerson?.FirstName} {secondPerson?.LastName}
                      </>
                    </MenuItem>
                  );
                })}
              {parentPersonArray && parentPersonArray.length === 2 && (
                <MenuItem
                  key={`idPerson-${parentPersonArray[0].idPerson}`}
                  value={`${parentPersonArray[0].idPerson}_${parentPersonArray[1].idPerson}`}
                >
                  {parentPersonArray[0].FirstName}{' '}
                  {parentPersonArray[0].LastName} und{' '}
                  {parentPersonArray[1].FirstName}{' '}
                  {parentPersonArray[1].LastName}
                </MenuItem>
              )}
              {parentPersonArray.map(x => (
                <MenuItem key={`idPerson-${x.idPerson}`} value={x.idPerson}>
                  <>
                    {x.FirstName} {x.LastName}
                  </>
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item sm={6}>
            <TextField
              sx={{ mt: 2 }}
              label='Adoptiert'
              select
              size='small'
              fullWidth
              value={editChildren.isAdopted ? 'true' : 'false'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEditChildre({
                  ...editChildren,
                  isAdopted: event.target.value === 'true',
                })
              }
            >
              <MenuItem key='isAdopted-true' value='true'>
                Ja
              </MenuItem>
              <MenuItem key='isAdopted-false' value='false'>
                Nein
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={6}>
            <TextField
              sx={{ mt: 2 }}
              label='Zur Adoption freigegeben'
              select
              size='small'
              fullWidth
              value={editChildren.isPutUpForAdoption ? 'true' : 'false'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEditChildre({
                  ...editChildren,
                  isPutUpForAdoption: event.target.value === 'true',
                })
              }
            >
              <MenuItem key='isPutUpForAdoption-true' value='true'>
                Ja
              </MenuItem>
              <MenuItem key='isPutUpForAdoption-false' value='false'>
                Nein
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <InheritPerson
          personArray={personArray}
          key='child'
          currentPerson={editChildrenPerson}
          setCurrentPerson={setEditChildrenPerson}
          inheritObject={inheritObject}
          personHasDocument={personHasDocument}
          setPersonHasDocument={setPersonHasDocument}
          title=''
          showDeathSettings
          requiredFiles={getRequiredFiles(isDeath)}
          setIsDeath={setIsDeath}
        />
      </DialogResponsive>

      <TableRow
        sx={{
          backgroundColor:
            childrenPerson.idPerson === -1 ? '#f2f2f2' : undefined,
        }}
      >
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          {getInheritPerson(currentIChildren.Parent1_idPerson, personArray) ===
          null ? (
            <>ERROR</>
          ) : (
            `${
              getInheritPerson(currentIChildren.Parent1_idPerson, personArray)
                .FirstName
            } ${
              getInheritPerson(currentIChildren.Parent1_idPerson, personArray)
                .LastName
            }`
          )}
        </TableCell>
        <TableCell>
          {currentIChildren.Parent2_idPerson ? (
            `${
              getInheritPerson(currentIChildren.Parent2_idPerson, personArray)
                .FirstName
            } ${
              getInheritPerson(currentIChildren.Parent2_idPerson, personArray)
                .LastName
            }`
          ) : (
            <>keine Daten vorhanden</>
          )}
        </TableCell>

        <TableCell>
          {`${childrenPerson.FirstName} ${childrenPerson.LastName}`}
        </TableCell>
        <TableCell>
          <IconButton
            disabled={childrenPerson.idPerson === -1}
            onClick={handleOpen}
          >
            <Edit />
          </IconButton>
          <IconButton
            disabled={childrenPerson.idPerson === -1}
            onClick={handleRemove}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
