import React, { useState } from 'react';
import { ICompanyFormation } from '../../../../../Interfaces/ICompanyFormation';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import PostcodeSearchV2 from '../../../../generic/PostcodeSearchV2';
import { useTranslation } from 'react-i18next';
import { checkTranslation } from '../../../../core/standardFunctions';

interface IProps {
  companyFormationObject: ICompanyFormation;
  setCompanyFormationObject: Function;
  //
  setTxt: Function;
  currentStep: number;
  handleBack: Function;
  handleNext: Function;

  isImportedFromHandelsregister?: boolean;

  //Für CompanyChange Buttons deaktiveren
  buttonsDisabled?: boolean;
  isTranslationEnabled?: boolean;
}

export const RABaseInformation: React.FC<IProps> = props => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(
    props.companyFormationObject.AddressStreet === null &&
      props.companyFormationObject.AddressStreetNr === null &&
      props.companyFormationObject.Postcode_AddressidPostcode === null
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    props.setCompanyFormationObject({
      ...props.companyFormationObject,
      AddressStreetNr: null,
      AddressStreet: null,
      Postcode_AddressidPostcode: null,
    });
  };

  const checkTransaltionEnabled = () => {
    if (
      !props.isTranslationEnabled ||
      props.isTranslationEnabled === undefined
    ) {
      return false;
    }
    if (props.isTranslationEnabled) {
      return true;
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.handleNext();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mt: 5 }} />
        <Typography variant='h4' sx={{ mb: 3 }}>
          {checkTranslation(
            t,
            'headline_dialog_baseinformation',
            'Grundinformationen'
          )}
        </Typography>
        <Typography variant='h6' sx={{ mb: 3 }}>
          {checkTranslation(t, 'input_ra_name', 'Vereinsname')}
        </Typography>

        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              label={checkTranslation(t, 'input_ra_name', 'Vereinsname')}
              size='small'
              fullWidth
              value={props.companyFormationObject.CompanyName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.setCompanyFormationObject({
                  ...props.companyFormationObject,
                  CompanyName: event.target.value,
                })
              }
              required
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 5 }} />
        <Typography variant='h6' sx={{ mb: 3 }}>
          {checkTranslation(t, 'headline_ra_headquarter', 'Sitz')}
          <FormGroup sx={{ float: 'right' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={checkTranslation(
                t,
                'label_address_identical',
                'Sitz und Anschrift sind identisch'
              )}
            />
          </FormGroup>
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <TextField
              label={checkTranslation(t, 'input_hq_street', 'Straße')}
              size='small'
              fullWidth
              value={props.companyFormationObject.Street}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.setCompanyFormationObject({
                  ...props.companyFormationObject,
                  Street: event.target.value,
                })
              }
              required
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label={checkTranslation(t, 'input_hq_house_number', 'Nr.')}
              size='small'
              fullWidth
              value={props.companyFormationObject.StreetNr}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.setCompanyFormationObject({
                  ...props.companyFormationObject,
                  StreetNr: event.target.value,
                })
              }
              required
            />
          </Grid>

          <Grid item sm={12}>
            <PostcodeSearchV2
              idPostcode={props.companyFormationObject.idPostcode}
              setIdPostcode={(idPostcode: number) =>
                props.setCompanyFormationObject({
                  ...props.companyFormationObject,
                  idPostcode: idPostcode,
                })
              }
              required
            />
          </Grid>
        </Grid>

        <Collapse in={!checked}>
          <Box sx={{ mt: 5 }} />
          <Typography variant='h6' sx={{ mb: 3 }}>
            {checkTranslation(t, 'input_ra_address', 'Anschrift')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={8}>
              <TextField
                label={checkTranslation(t, 'input_ra_street', 'Straße')}
                size='small'
                fullWidth
                value={
                  props.companyFormationObject.AddressStreet === null
                    ? ''
                    : props.companyFormationObject.AddressStreet
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.setCompanyFormationObject({
                    ...props.companyFormationObject,
                    AddressStreet:
                      event.target.value === '' ? null : event.target.value,
                  })
                }
                required={!checked}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                label={checkTranslation(t, 'input_ra_house_number', 'Nr.')}
                size='small'
                fullWidth
                value={
                  props.companyFormationObject.AddressStreetNr === null
                    ? ''
                    : props.companyFormationObject.AddressStreetNr
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.setCompanyFormationObject({
                    ...props.companyFormationObject,
                    AddressStreetNr:
                      event.target.value === '' ? null : event.target.value,
                  })
                }
                required={!checked}
              />
            </Grid>

            <Grid item sm={12}>
              <PostcodeSearchV2
                label={
                  checkTransaltionEnabled() ? t('postcode') : 'Postleitzahl'
                }
                idPostcode={
                  props.companyFormationObject.Postcode_AddressidPostcode
                }
                setIdPostcode={(idPostcode: number) =>
                  props.setCompanyFormationObject({
                    ...props.companyFormationObject,
                    Postcode_AddressidPostcode: idPostcode,
                  })
                }
                required={!checked}
                allowNull
              />
            </Grid>
          </Grid>
        </Collapse>

        {(props.buttonsDisabled === undefined ||
          props.buttonsDisabled === false) && (
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
            <Button
              variant='outlined'
              disabled={props.currentStep === 0}
              onClick={() => props.handleBack()}
              sx={{ mr: 1 }}
            >
              {checkTranslation(t, 'button_ra_back', 'Zurück')}
            </Button>

            <Box sx={{ flex: '1 1 auto' }} />
            <Button type='submit' variant='contained'>
              {checkTranslation(t, 'button_ra_next', 'Weiter')}
            </Button>
          </Box>
        )}
      </form>
    </>
  );
};
