import {
  Apartment,
  Bungalow,
  Diversity3,
  Gavel,
  HistoryEdu,
} from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IConfig } from '../../Interfaces/IConfig';

interface IProps {
  configObject: IConfig;
}

export const DialogSelecter: React.FC<IProps> = ({ configObject }) => {
  const { i18n, t } = useTranslation();

  const [openSecond, setOpenSecond] = useState(false);
  const [openThird, setOpenThird] = useState(false);
  const [openFour, setOpenFour] = useState(false);
  const [openFifth, setOpenFifth] = useState(false);

  const navigate = useNavigate();

  const [foundingOptionDialogOpen, setFoundingOptionDialogOpen] =
    useState(false);
  const [
    registeredassociationOptionDialogOpen,
    setRegisteredassociationOptionDialogOpenDialogOpen,
  ] = useState(false);

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  const transitionAfterDowntimeSecond = async () => {
    await delay(500);
    setOpenSecond(true);
  };
  const transitionAfterDowntimeThird = async () => {
    await delay(1000);
    setOpenThird(true);
  };
  const transitionAfterDowntimeFour = async () => {
    await delay(1500);
    setOpenFour(true);
  };
  const transitionAfterDowntimeFifth = async () => {
    await delay(2000);
    setOpenFifth(true);
  };

  useEffect(() => {
    transitionAfterDowntimeSecond();
    transitionAfterDowntimeThird();
    transitionAfterDowntimeFour();
    transitionAfterDowntimeFifth();
  }, []);

  const handleCloseRegisteredassociationOptionDialog = () => {
    setRegisteredassociationOptionDialogOpenDialogOpen(false);
  };

  const handleCloseFoundingOptionDialog = () => {
    setFoundingOptionDialogOpen(false);
  };

  const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  return (
    <>
      {/**
            <Box sx={{float: "right"}}>
            <select defaultValue={i18n.language} onChange={onChangeLang}>
                <option key="de" value="de">deutsch</option>
                <option key="en" value="en">englisch</option>
            </select>
            </Box>
                 */}

      <Box sx={{ p: 3 }}>
        <img
          width={250}
          src={`data:${configObject.logo_mime};base64,${configObject.logo}`}
        />

        <Dialog
          open={foundingOptionDialogOpen}
          onClose={handleCloseFoundingOptionDialog}
          PaperProps={{
            style: {
              minWidth: '500px',
              minHeight: '100px',
              paddingTop: '10px',
            },
          }}
        >
          <DialogTitle>
            Sie wollen eine neue Gesellschaft gründen oder Änderungen zu Ihrer
            bestehenden Gesellschaft veranlassen?
          </DialogTitle>
          <DialogContent>
            <Button
              sx={{ float: 'right' }}
              onClick={() => navigate('/founding')}
            >
              Gründen
            </Button>
            <Button
              sx={{ float: 'left' }}
              onClick={() => navigate('/companychange')}
            >
              Ändern
            </Button>
          </DialogContent>
        </Dialog>

        <Dialog
          open={registeredassociationOptionDialogOpen}
          onClose={handleCloseRegisteredassociationOptionDialog}
          PaperProps={{
            style: {
              minWidth: '500px',
              minHeight: '100px',
              paddingTop: '10px',
            },
          }}
        >
          <DialogTitle>
            Wollen Sie einen neuen Verein gründen oder Angaben Ihres bestehenden
            Vereins verändern?
          </DialogTitle>
          <DialogContent>
            <Button
              sx={{ float: 'right' }}
              onClick={() => navigate('/registeredassociation/founding')}
            >
              Gründen
            </Button>
            <Button
              sx={{ float: 'left' }}
              onClick={() => navigate('/registeredassociation/change')}
            >
              Ändern
            </Button>
          </DialogContent>
        </Dialog>

        <Grid
          container
          spacing={2}
          direction='column'
          alignItems='center'
          style={{ minHeight: '100vh' }}
        >
          <Grid item sm={12}>
            <Typography variant='h5'>
              {JSON.stringify(t)}
              {t('headline_select_dialog')}
            </Typography>
          </Grid>

          <Grid item sm={12}>
            <Typography sx={{ mb: 5, textAlign: 'center' }}>
              {t('description_select_dialog')}
            </Typography>
          </Grid>

          <Grid item sm={6}>
            <Grow in={true} timeout={1000}>
              <Card sx={{ width: 500 }}>
                {/* ButtonBase macht die gesamte Karte klickbar */}
                <ButtonBase
                  sx={{ width: '100%', display: 'block', textAlign: 'initial' }}
                  onClick={() => setFoundingOptionDialogOpen(true)}
                >
                  <CardContent>
                    <Apartment sx={{ fontSize: 50 }} />
                    <Typography variant='h5' component='div'>
                      Unternehmen
                    </Typography>
                    <Typography variant='body2'>
                      Sie möchten eine neue Gesellschaft gründen oder Änderungen
                      auf den Weg bringen? Unser Dialog führt Sie Schritt für
                      Schritt zum Ziel.
                    </Typography>
                    <Button sx={{ float: 'right' }}>Dialog starten</Button>
                  </CardContent>
                </ButtonBase>
              </Card>
            </Grow>
          </Grid>

          <Grid item sm={6}>
            <Grow in={openSecond} timeout={1000}>
              <Card sx={{ width: 500 }}>
                <ButtonBase
                  sx={{ width: '100%', display: 'block', textAlign: 'initial' }}
                  onClick={() =>
                    setRegisteredassociationOptionDialogOpenDialogOpen(true)
                  }
                >
                  {' '}
                  <CardContent>
                    <Diversity3 sx={{ fontSize: 50 }} />
                    <Typography variant='h5' component='div'>
                      Vereine
                    </Typography>
                    <Typography variant='body2'>
                      Sie wollen einen neuen Verein gründen oder Änderungen
                      eintragen lassen? Dann folgen Sie unserem Dialog!
                    </Typography>
                    <Button sx={{ float: 'right' }}>Dialog starten</Button>
                    {/*
                                        <Button sx={{float: "right"}} onClick={() => navigate("/registeredassociation/founding")}>Dialog
                                        starten</Button>
                                         */}
                  </CardContent>
                </ButtonBase>
              </Card>
            </Grow>
          </Grid>

          <Grid item sm={6}>
            <Grow in={openThird} timeout={1000}>
              <Card sx={{ width: 500 }}>
                <ButtonBase
                  sx={{ width: '100%', display: 'block', textAlign: 'initial' }}
                  onClick={() => navigate('/inherit')}
                >
                  <CardContent>
                    <HistoryEdu sx={{ fontSize: 50 }} />
                    <Typography variant='h5' component='div'>
                      Erbscheinsantrag
                    </Typography>
                    <Typography variant='body2'>
                      Sie benötigen einen Erbschein? Unser Dialog begleitet Sie
                      durch den Antrag.
                    </Typography>
                    <Button sx={{ float: 'right' }}>Dialog starten</Button>
                  </CardContent>
                </ButtonBase>
              </Card>
            </Grow>
          </Grid>

          {/*
                     <Grid item sm={6}>
                        <Grow in={openFour} timeout={1000}>
                            <Card sx={{width: 500}}>
                                <CardContent>
                                    <Bungalow sx={{fontSize: 50}}/>
                                    <Typography variant="h5" component="div">Unternehmensstruktur</Typography>
                                    <Typography variant="body2">
                                        Nutzen Sie unseren interaktiven Dialog, wenn Sie ihre Untenehmensstruktur hinterlegen wollen.
                                    </Typography>
                                    <Button sx={{float: "right"}} onClick={() => navigate("/corporatestructure")}>Dialog
                                        starten</Button>
                                </CardContent>
                            </Card>
                        </Grow>
                    </Grid> 
                    */}

          <Grid item sm={6}>
            <Grow in={openFour} timeout={1000}>
              <Card sx={{ width: 500 }}>
                <ButtonBase
                  sx={{ width: '100%', display: 'block', textAlign: 'initial' }}
                  onClick={() => navigate('/purchase')}
                >
                  {' '}
                  <CardContent>
                    <Bungalow sx={{ fontSize: 50 }} />
                    <Typography variant='h5' component='div'>
                      Kaufvertrag
                    </Typography>
                    <Typography variant='body2'>
                      Sie wollen ein (bebautes oder unbebautes) Grundstück, eine
                      Eigentumswohnung oder ein Erbbaurecht kaufen oder
                      verkaufen? Nutzen Sie unseren Dialog, um zügig einen
                      Vetragsentwurf zu erhalten.
                    </Typography>
                    <Button sx={{ float: 'right' }}>Dialog starten</Button>
                  </CardContent>
                </ButtonBase>
              </Card>
            </Grow>
          </Grid>

          <Grid item sm={6}>
            <Grow in={openFifth} timeout={1000}>
              <Card sx={{ width: 500 }}>
                <ButtonBase
                  sx={{ width: '100%', display: 'block', textAlign: 'initial' }}
                  onClick={() => navigate('/mortgage')}
                >
                  <CardContent>
                    <Gavel sx={{ fontSize: 50 }} />
                    <Typography variant='h5' component='div'>
                      Grundschuld
                    </Typography>
                    <Typography variant='body2'>
                      Sie möchten eine Grundschuld bestellen oder löschen?
                      Nutzen Sie dafür unseren Dialog!
                    </Typography>
                    <Button sx={{ float: 'right' }}>Dialog starten</Button>
                  </CardContent>
                </ButtonBase>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
