import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Edge, getRectOfNodes, getTransformForBounds, Node } from 'reactflow';
import { IInheritHasPerson } from '../../../../../../Interfaces/IPerson';
import { IMarriage } from '../../../../../../Interfaces/IMarriage';
import { IChildren } from '../../../../../../Interfaces/IChildren';
import { IPersonHasDocument } from '../../../../../../Interfaces/IPersonHasDocument';
import {
  FamilyTreeEdgeType,
  FamilyTreePersonNode,
  FamilyTreePersonNodeType,
  FamilyTreeService,
} from '../../../../../../services/familyTree.service';
import { FormConfig, TreeEditor } from '../../../../../core/TreeEditor';
import PersonEditForm from './EditorDialogs/PersonEditForm';
import { TestatorNode } from './FormatNodes/TestatorNode';
import { GenericPersonNode } from './FormatNodes/GenericPersonNode';
import FormatMarriageEdges from './FormatEdges/FormatMarriageEdges';
import { toPng } from 'html-to-image';
import { IDocument } from '../../../../../../Interfaces/IDocument';

export interface IProps {
  personArray: IInheritHasPerson[];
  marriageArray: IMarriage[];
  childrenArray: IChildren[];
  setPersonArray: (persons: IInheritHasPerson[]) => void;
  setChildrenArray: Function;
  setMarriageArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  setFamilyTreeData: Function;
}

export const FamilyTree: React.FC<IProps> = props => {
  /**
   * Nodetitle Function
   * @param node
   * @returns
   */
  const getGenderName = (node: Node<FamilyTreePersonNode>) => {
    return FamilyTreeService.getGenderNaming(
      node.type || '',
      node.data.person.idGender
    );
  };

  /**
   * All dialogForms and Titles
   */
  const forms: { [key: string]: FormConfig } = {
    [FamilyTreePersonNodeType.TESTATOR_NODE]: {
      form: PersonEditForm,
      title: getGenderName,
    },
    [FamilyTreePersonNodeType.MARRIAGE_NODE]: {
      form: PersonEditForm,
      title: getGenderName,
    },
    [FamilyTreePersonNodeType.CHILD_NODE]: {
      form: PersonEditForm,
      title: getGenderName,
    },
    [FamilyTreePersonNodeType.ENKEL_NODE]: {
      form: PersonEditForm,
      title: getGenderName,
    },
    [FamilyTreePersonNodeType.PARENT_NODE]: {
      form: PersonEditForm,
      title: getGenderName,
    },
    [FamilyTreePersonNodeType.GESCHWISTER_NODE]: {
      form: PersonEditForm,
      title: getGenderName,
    },
    [FamilyTreePersonNodeType.GESCHWISTERKIND_NODE]: {
      form: PersonEditForm,
      title: getGenderName,
    },
    [FamilyTreePersonNodeType.URENKEL_NODE]: {
      form: PersonEditForm,
      title: getGenderName,
    },
    [FamilyTreePersonNodeType.URURENKEL_NODE]: {
      form: PersonEditForm,
      title: getGenderName,
    },
    [FamilyTreePersonNodeType.GROßNEFFE_NODE]: {
      form: PersonEditForm,
      title: getGenderName,
    },
    [FamilyTreePersonNodeType.URGROßNEFFE_NODE]: {
      form: PersonEditForm,
      title: getGenderName,
    },
    [FamilyTreePersonNodeType.OTHERPERSON_NODE]: {
      form: PersonEditForm,
      title: 'sonstige Person',
    },
  };

  /**
   * set the new id for the document "Stammbaum" Function
   * @returns
   */
  const initialDocumentId = () => {
    let minId = Math.min(...props.personHasDocument.map(x => x.idDocument)) - 1;
    if (minId > 0) {
      minId = -1;
    }

    return minId;
  };

  /**
   * Function to find the existing document "Stammbaum.png" if exists
   */
  const existingDocument = props.personHasDocument.find(
    document => document.FileName === 'Stammbaum.png'
  );

  /**
   * Const CurrentDocument (document already exists or new document"Stammbaum")
   */
  const [currentObject, setCurrentObject] = useState<IPersonHasDocument>(
    existingDocument
      ? existingDocument
      : {
          FileName: 'Stammbaum.png',
          idDocument: initialDocumentId(),
          idMIMEType: 3,
          idPerson: props.personArray[0].idPerson, // id Erblasser
          idPersonDocumentType: 6,
          Data: '',
        }
  );
  const [editorHeight, setEditorHeight] = useState('300px');
  /**
   * const to re-render
   * TODO: find better solution
   */
  const [renderTreeEditor, setRenderTreeEditor] = useState(true);

  /**
   * update the height of Reactflow
   */
  useEffect(() => {
    setEditorHeight(props.personArray.length === 1 ? '300px' : '500px');
  }, [props.personArray]);

  /**
   * Edge-Styles-for-each-type
   */
  const edgeTypes = useMemo(
    () => ({
      [FamilyTreeEdgeType.Marriage_EDGE]: FormatMarriageEdges,
    }),
    []
  );

  /**
   * Node-Styles-for-each-type
   */
  const nodeTypes = useMemo(
    () => ({
      [FamilyTreePersonNodeType.TESTATOR_NODE]: TestatorNode,
      [FamilyTreePersonNodeType.MARRIAGE_NODE]: GenericPersonNode,
      [FamilyTreePersonNodeType.CHILD_NODE]: GenericPersonNode,
      [FamilyTreePersonNodeType.ENKEL_NODE]: GenericPersonNode,
      [FamilyTreePersonNodeType.PARENT_NODE]: GenericPersonNode,
      [FamilyTreePersonNodeType.GESCHWISTER_NODE]: GenericPersonNode,
      [FamilyTreePersonNodeType.GESCHWISTERKIND_NODE]: GenericPersonNode,
      [FamilyTreePersonNodeType.URENKEL_NODE]: GenericPersonNode,
      [FamilyTreePersonNodeType.URURENKEL_NODE]: GenericPersonNode,
      [FamilyTreePersonNodeType.GROßNEFFE_NODE]: GenericPersonNode,
      [FamilyTreePersonNodeType.URGROßNEFFE_NODE]: GenericPersonNode,
      [FamilyTreePersonNodeType.OTHERPERSON_NODE]: GenericPersonNode,
    }),
    []
  );

  /**
   * Create Png Function
   * @param nodes
   */
  const imageWidth = 1024;
  const imageHeight = 768;

  const createPng = (nodes: Node[]) => {
    const nodesBounds = getRectOfNodes(nodes);
    const transform = getTransformForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.5,
      2
    );

    const viewportElement = document.querySelector(
      '.react-flow__viewport'
    ) as HTMLElement;
    if (viewportElement) {
      toPng(viewportElement, {
        backgroundColor: 'white',
        width: imageWidth,
        height: imageHeight,
        style: {
          width: `${imageWidth}px`,
          height: `${imageHeight}px`,
          transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
        },
      }).then(uploadImage);
    }
  };

  /**
   * convert png to File Function
   * @param dataUrl
   */
  function uploadImage(dataUrl: string) {
    const file = dataURLtoFile(dataUrl, 'stammbaum.png');
    saveDocument(file);
  }

  function dataURLtoFile(dataUrl: string, filename: string): File {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  /**
   * Save File(Png) in personHasDocument
   * @param file
   */
  const saveDocument = async (file: File) => {
    const tmpObject: IPersonHasDocument = {
      ...currentObject,
      Data: await fileToBase64(file),
    };

    const tmpObjectDocument: IDocument = {
      idDocument: -1,
      FileName: 'Stammbaum.png',
      idMIMEType: 5,
      Data: await fileToBase64(file),
    };
    props.setFamilyTreeData(tmpObjectDocument);

    const findObject = props.personHasDocument.find(
      x => x.idDocument === tmpObject.idDocument
    );

    if (findObject === undefined) {
      props.setPersonHasDocument([...props.personHasDocument, tmpObject]);
    } else {
      props.setPersonHasDocument([
        ...props.personHasDocument.map(x =>
          x.idDocument === tmpObject.idDocument ? tmpObject : x
        ),
      ]);
    }
  };

  async function fileToBase64(file: File) {
    const result_base64 = await new Promise(resolve => {
      const fileReader = new FileReader();
      fileReader.onload = e => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
    return String(result_base64).split(',')[1];
  }

  /**
   * Create Nodes and Edges
   */
  const { nodes, edges } = FamilyTreeService.generateNodesAndEdges({
    personArray: props.personArray,
    marriageArray: props.marriageArray,
    childrenArray: props.childrenArray,
  });

  const [nodesState, setNodes] = useState<Node[]>(nodes);
  const [edgesState, setEdges] = useState<Edge[]>(edges);

  useEffect(() => {
    const { nodes, edges } = FamilyTreeService.generateNodesAndEdges({
      personArray: props.personArray,
      marriageArray: props.marriageArray,
      childrenArray: props.childrenArray,
    });
    //createPng(nodes);
    setNodes(nodes);
    setEdges(edges);
  }, [props.childrenArray, props.marriageArray, props.personArray]);

  useEffect(() => {
    // Temporarily remove the TreeEditor component to force a re-render
    // TODO: temorary fix. fix at a later time
    setRenderTreeEditor(false);
    const timer = setTimeout(() => {
      setRenderTreeEditor(true);
    }, 100); // Adjust the timeout duration as needed

    return () => clearTimeout(timer);
  }, [nodesState, edgesState]);

  /**
   * Update Function
   * @param currentNode
   */

  const updatePersonArray = (currentNode: Node<FamilyTreePersonNode>) => {
    if (currentNode) {
      const updatedPersons = props.personArray.map(person =>
        person.idPerson === currentNode.data.person.idPerson
          ? ({
              ...currentNode.data.person,
            } as IInheritHasPerson)
          : person
      );
      props.setPersonArray(updatedPersons);
    }
  };

  /**
   * createPngOfTree Function (TreeEditor)
   * @param nodes
   */
  const createPngOfTree = (nodes: Node[]) => {
    createPng(nodes);
  };

  return (
    <Box style={{ width: '100%', height: editorHeight }}>
      {renderTreeEditor && nodesState && edgesState && (
        <TreeEditor
          nodeData={nodesState}
          edgeData={edgesState}
          customNodeComponents={nodeTypes}
          customEdgeComponents={edgeTypes}
          showAddButton={false}
          allowEdgeDoubleClickEdit={false}
          allowOnConnect={false}
          forms={forms}
          handleEditNodeOnSaveOutside={updatePersonArray}
          showDownloadButton={false} // TODO: set False
          creatPngOfTree={createPngOfTree}
        />
      )}
    </Box>
  );
};
