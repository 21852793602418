import React, { useEffect, useState } from 'react';
import { IRealEstate } from '../../../../../../Interfaces/IRealEstate';
import { IPurchaseContractHasPerson } from '../../../../../../Interfaces/IPerson';
import { Typography } from '@mui/material';
import { getPurchaseContractPerson } from '../Person/PurchaseContractPersonOverview';
import { PurchaseContractPersonEdit } from '../Person/PurchaseContractPersonEdit';

interface IProps {
  realEstateOnject: IRealEstate;
  setRealEstateObject: Function;
  personArray: IPurchaseContractHasPerson[];
  setPersonArray: Function;
}

export const RealEstateManager: React.FC<IProps> = props => {
  const [personEditObject, setPersonEditObject] = useState(() => {
    if (props.realEstateOnject.Manager_idPerson !== null) {
      return getPurchaseContractPerson(
        props.realEstateOnject.Manager_idPerson,
        10,
        props.personArray
      );
    } else {
      const newObject = getPurchaseContractPerson(
        -1,
        10,
        props.personArray,
        true
      );
      props.setRealEstateObject({
        ...props.realEstateOnject,
        Manager_idPerson: newObject.idPerson,
      });
      return newObject;
    }
  });

  useEffect(() => {
    const testObject = props.personArray.find(
      x => x.idPerson === personEditObject.idPerson
    );

    if (testObject === undefined) {
      props.setPersonArray([...props.personArray, personEditObject]);
    } else {
      props.setPersonArray([
        ...props.personArray.map(x =>
          x.idPerson === personEditObject.idPerson ? personEditObject : x
        ),
      ]);
    }
  }, [personEditObject]);

  return (
    <>
      <Typography variant='h5' sx={{ mt: 2, mb: 1 }}>
        Verwaltung
        <br />
        <Typography>
          Gibt es eine Hausverwaltung für das Objekt? Bitte tragen Sie ggf. die
          entsprechenden Kontaktinformationen ein.
        </Typography>
      </Typography>

      <PurchaseContractPersonEdit
        currentPerson={personEditObject}
        setCurrentPerson={setPersonEditObject}
        personArray={props.personArray}
        showBankInformation={false}
        showTaxInformation={false}
      />
    </>
  );
};
