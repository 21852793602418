import React from 'react';
import { IPerson } from '../../../../../../../Interfaces/IPerson';
import { Grid, Typography } from '@mui/material';
import { ICompany } from '../../../../../../../Interfaces/ICompany';

interface IProps {
  beneficiary: IPerson | ICompany;
}

export const MortgagePersonSummary: React.FC<IProps> = props => {
  return (
    <>
      {props.beneficiary && (
        <>
          <Grid container spacing={2} sx={{ ml: 0, mt: 2 }}>
            <Typography variant='h6' sx={{ mt: 5 }}>
              Begünstigter
            </Typography>
            <Grid item sm={12}>
              <hr />
            </Grid>
            {props.beneficiary && (
              <>
                <Grid item sm={4}>
                  <b>Unternehmen oder Privatperson?</b>
                </Grid>
                <Grid item sm={8}>
                  {'idCompany' in props.beneficiary
                    ? 'Unternehmen'
                    : 'Privatperson'}
                </Grid>
              </>
            )}

            {'idCompany' in props.beneficiary &&
              props.beneficiary.Company.length > 0 && (
                <>
                  <Grid item sm={4}>
                    <b>Firmenname:</b>
                  </Grid>
                  <Grid item sm={8}>
                    {props.beneficiary.Company}
                  </Grid>
                </>
              )}

            {'idPerson' in props.beneficiary &&
              props.beneficiary.FirstName.length > 0 && (
                <>
                  <Grid item sm={4}>
                    <b>Vorname:</b>
                  </Grid>
                  <Grid item sm={8}>
                    {props.beneficiary.FirstName}
                  </Grid>
                </>
              )}

            {'idPerson' in props.beneficiary &&
              props.beneficiary.LastName.length > 0 && (
                <>
                  <Grid item sm={4}>
                    <b>Nachname:</b>
                  </Grid>
                  <Grid item sm={8}>
                    {props.beneficiary.LastName}
                  </Grid>
                </>
              )}

            {props.beneficiary.Email && props.beneficiary.Email.length > 0 && (
              <>
                <Grid item sm={4}>
                  <b>Email:</b>
                </Grid>
                <Grid item sm={8}>
                  {props.beneficiary.Email}
                </Grid>
              </>
            )}

            {props.beneficiary.PhoneNumber &&
              props.beneficiary.PhoneNumber.length > 0 && (
                <>
                  <Grid item sm={4}>
                    <b>Telefon:</b>
                  </Grid>
                  <Grid item sm={8}>
                    {props.beneficiary.PhoneNumber}
                  </Grid>
                </>
              )}

            {props.beneficiary.Street &&
              props.beneficiary.Street.length > 0 && (
                <>
                  <Grid item sm={4}>
                    <b>Straße:</b>
                  </Grid>
                  <Grid item sm={8}>
                    {props.beneficiary.Street}
                  </Grid>
                </>
              )}

            {props.beneficiary.StreetNr &&
              props.beneficiary.StreetNr.length > 0 && (
                <>
                  <Grid item sm={4}>
                    <b>Hausnummer:</b>
                  </Grid>
                  <Grid item sm={8}>
                    {props.beneficiary.StreetNr}
                  </Grid>
                </>
              )}

            {props.beneficiary.Postcode &&
              props.beneficiary.Postcode.length > 0 && (
                <>
                  <Grid item sm={4}>
                    <b>Postleitzahl:</b>
                  </Grid>
                  <Grid item sm={8}>
                    {props.beneficiary.Postcode}
                  </Grid>
                </>
              )}

            {props.beneficiary.City && props.beneficiary.City.length > 0 && (
              <>
                <Grid item sm={4}>
                  <b>Ort:</b>
                </Grid>
                <Grid item sm={8}>
                  {props.beneficiary.City}
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
