import React, { useEffect, useState } from 'react';
import {
  IContact,
  IContactFullObject,
} from '../../../../../../Interfaces/IContact';
import { IPerson } from '../../../../../../Interfaces/IPerson';
import { IPersonHasDocument } from '../../../../../../Interfaces/IPersonHasDocument';
import { ICompany } from '../../../../../../Interfaces/ICompany';
import { ClientInformation } from '../../../../../generic/ClientInformation';
import { uploadFetch } from '../../../../../../hooks/useFetch';
import { Dialog, DialogContent } from '@mui/material';
import { CustomCircularProgress } from '../../../../../generic/CustomCircularProgress';
import {
  IMortgage,
  IMortgageFullObject,
} from '../../../../../../Interfaces/IMortgage';
import { IConfig } from '../../../../../../Interfaces/IConfig';

interface IProps {
  configObject: IConfig;
  mortgageObject: IMortgage;
  personArray: IPerson[];
  personHasDocument: IPersonHasDocument[];
  setMortgageObject: Function;
  handleNext: Function;
  handleBack: Function;
}

export const MortgageContact: React.FC<IProps> = props => {
  const [isLoading, setIsLoading] = useState(false);

  const [personObject, setPersonObject] = useState<IContact>({
    idContact: -1,
    idCompany: -1,
    idGender: 4,
    idAcademicTitle: 1,
    FirstName: '',
    LastName: '',
    Email: '',
  } as IContact);

  const [companyObject, setCompanyObject] = useState<ICompany>({
    idCompany: -1,
    Company: '',
    idBusinessForm: 1,
    idPostcode: null,
    Street: '',
    StreetNr: '',
    StreetAditional: '',
    PhoneNumber: '',
    Website: '',
    RegisterCourt: null,
    RegisterNumber: null,
    TaxNumber: null,
    SalesTaxNumber: null,
  });

  async function handleSubmit() {
    setIsLoading(true);
    const uploadObject = {
      ...props.mortgageObject,
      PersonArray: props.personArray,
      PersonHasDocument: props.personHasDocument,
      ContactObject: {
        ...personObject,
        CompanyObject: companyObject,
        isReceivingEmailEnabled: false,
      } as IContactFullObject,
    } as IMortgageFullObject;
    uploadFetch(
      '/legaltransaction/createMortgage',
      true,
      uploadObject,
      () => {},
      () => {},
      () => {
        setIsLoading(false);
        props.handleNext();
      }
    );
  }

  useEffect(() => {
    //props.setMortgageObject({...props.mortgageObject, ContactObject: {...personObject, CompanyObject: companyObject, isReceivingEmailEnabled: props.mortgageObject.ContactObject?.isReceivingEmailEnabled} as IContactFullObject})
  }, [personObject, companyObject]);

  return (
    <>
      <Dialog open={isLoading} maxWidth='xs' fullWidth>
        <DialogContent>
          <CustomCircularProgress />
        </DialogContent>
      </Dialog>
      <ClientInformation
        contactFullObject={
          {
            ...personObject,
            CompanyObject: companyObject,
          } as IContactFullObject
        }
        setContactFullObject={(localFullObject: IContactFullObject) => {
          setPersonObject(localFullObject);
          setCompanyObject(localFullObject.CompanyObject);
        }}
        handleBack={() => props.handleBack()}
        handleSave={handleSubmit}
        isTranslationEnabled={false}
        configObject={props.configObject}
        disableHasAccount={
          props.configObject.Module.find(x => x === 'purchase_contract') ===
          undefined
        }
      />
    </>
  );
};
