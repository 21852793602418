import React, { useEffect, useState } from 'react';
import { ICompanyFormationHasShareHolder } from '../../../../../Interfaces/ICompanyFormationHasShareHolder';
import { IShareHolderFullObject } from '../../../../../Interfaces/IShareHolder';
import { TableCell, TableRow, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
  shareHolderRelation: ICompanyFormationHasShareHolder;
  shareHolderArray: IShareHolderFullObject[];
  shareHolderRelationArray: ICompanyFormationHasShareHolder[];
  setShareHolderRelation: Function;
}

export const RABoardMemeberRow: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const [shareHolderRelation, setShareHolderRelation] = useState(
    props.shareHolderRelation
  );
  const [shareHolder, setShareHolder] = useState(
    props.shareHolderArray.find(
      x => x.idShareHolder === props.shareHolderRelation.idShareHolder
    )
  );

  useEffect(() => {
    setShareHolder(
      props.shareHolderArray.find(
        x => x.idShareHolder === props.shareHolderRelation.idShareHolder
      )
    );
  }, [props.shareHolderArray]);

  useEffect(() => {
    props.setShareHolderRelation([
      ...props.shareHolderRelationArray.map(x =>
        x.idShareHolder === shareHolderRelation.idShareHolder
          ? shareHolderRelation
          : x
      ),
    ]);
  }, [shareHolderRelation]);

  if (shareHolder === undefined) {
    return <>Fehler!</>;
  } else {
    return (
      <>
        <TableRow>
          <TableCell>
            {shareHolder.FirstName} {shareHolder.LastName}
          </TableCell>
          <TableCell sx={{ display: 'flex' }}>
            <span style={{ marginTop: 15, marginRight: 20, color: 'black' }}>
              dem
              {/* 
                                (shareHolder.idGender === 1) ? "dem"
                                : (shareHolder.idGender === 2) ? "der"
                                : "de*"
                            */}
            </span>
            <TextField
              label={t('Funktion')}
              variant='standard'
              size='small'
              value={
                shareHolderRelation.FreeText === null
                  ? ''
                  : shareHolderRelation.FreeText
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolderRelation({
                  ...shareHolderRelation,
                  FreeText: event.target.value,
                })
              }
              error={
                shareHolderRelation.FreeText === null ||
                shareHolderRelation.FreeText === ''
              }
            />
            <span style={{ marginTop: 20, marginRight: 10 }}>,</span>
          </TableCell>
        </TableRow>
      </>
    );
  }
};
