import {
  AppBar,
  Box,
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { GridCloseIcon } from '@mui/x-data-grid';
import React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface IProps {
  open: boolean;
  onClose: Function;
  title: string | React.ReactElement;
  maxWidth?: Breakpoint;
  children?: React.ReactNode;
  actions?: React.ReactNode;
}

export const DialogResponsive: React.FC<IProps> = ({
  open,
  onClose,
  title,
  maxWidth,
  children,
  actions,
}) => {
  const isDesktop = useMediaQuery('(min-width:900px)');

  if (isDesktop) {
    return (
      <>
        <Dialog
          open={open}
          onClose={() => onClose()}
          maxWidth={maxWidth}
          fullWidth={maxWidth !== undefined}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }} />
            {children}
          </DialogContent>
          {actions !== undefined && <DialogActions>{actions}</DialogActions>}
        </Dialog>
      </>
    );
  } else {
    return (
      <>
        <Dialog
          fullScreen
          open={open}
          onClose={() => onClose()}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={() => onClose()}
                aria-label='close'
              >
                <GridCloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ m: 2 }}>{children}</Box>
          {actions !== undefined && <DialogActions>{actions}</DialogActions>}
        </Dialog>
      </>
    );
  }
};
