import React, { useState } from 'react';
import { IRealEstateHasInventoryOption } from '../../../../../../Interfaces/IRealEstateHasInventoryOption';
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { IRealEstate } from '../../../../../../Interfaces/IRealEstate';
import { IRealEstateUseType } from '../../../../../../Interfaces/IRealEstateUseType';
import { NumericFormat } from 'react-number-format';

interface IProps {
  currentObject: IRealEstate;
  setCurrentObject: Function;
  realEstateUseTypeArray: IRealEstateUseType[];
  realEstateHasInventroyOptionArray: IRealEstateHasInventoryOption[];
  setHasWarningPrice: Function;
  marginTop?: number;
}

export const RealEstatePriceUseType: React.FC<IProps> = props => {
  const [hasWarning, setHasWarning] = useState(false);

  return (
    <>
      <Typography
        variant='h5'
        sx={{ mt: props.marginTop === undefined ? 2 : props.marginTop, mb: 1 }}
      >
        Nutzung
        <br />
      </Typography>

      <Box sx={{ mt: 5 }} />
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <NumericFormat
            value={props.currentObject.Price}
            suffix=' €'
            fixedDecimalScale
            thousandsGroupStyle='thousand'
            thousandSeparator='.'
            decimalSeparator=','
            fullWidth
            customInput={TextField}
            onValueChange={values => {
              if (values.floatValue !== undefined) {
                setHasWarning(false);
                props.setHasWarningPrice(false);
                props.setCurrentObject({
                  ...props.currentObject,
                  Price: values.floatValue,
                });
              } else {
                setHasWarning(true);
                props.setHasWarningPrice(true);
              }
            }}
            allowNegative={false}
            label='Kaufpreis'
            size='small'
            error={hasWarning}
          />
        </Grid>

        <Grid item sm={6}>
          <NumericFormat
            value={props.currentObject.PriceInventoryOption}
            suffix=' €'
            fixedDecimalScale
            thousandsGroupStyle='thousand'
            thousandSeparator='.'
            decimalSeparator=','
            fullWidth
            customInput={TextField}
            onValueChange={values => {
              if (values.floatValue !== undefined) {
                props.setCurrentObject({
                  ...props.currentObject,
                  PriceInventoryOption: values.floatValue,
                });
              } else {
                props.setCurrentObject({
                  ...props.currentObject,
                  PriceInventoryOption: null,
                });
              }
            }}
            allowNegative={false}
            label='Anteil der mitverkauften Gegenstände am Kaufpreis'
            size='small'
            disabled={props.realEstateHasInventroyOptionArray.length === 0}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label='Vermietung/Verpachtung'
            size='small'
            fullWidth
            required
            value={
              props.currentObject.idRealEstateUseType < 1
                ? ''
                : props.currentObject.idRealEstateUseType
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                idRealEstateUseType: Number(event.target.value),
              })
            }
            select
          >
            {props.realEstateUseTypeArray.map(x => (
              <MenuItem
                key={`idRealEstateUseType-${x.idRealEstateUseType}`}
                value={x.idRealEstateUseType}
              >
                {x.RealEstateUseType}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={12}>
          <TextField
            label='Mängel oder Einschränkungen der Nutzbarkeit'
            size='small'
            fullWidth
            value={
              props.currentObject.Defect === null
                ? ''
                : props.currentObject.Defect
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                Defect: event.target.value === '' ? null : event.target.value,
              })
            }
            multiline
            rows={3}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label='Besonderheiten'
            size='small'
            fullWidth
            value={
              props.currentObject.Particularities === null
                ? ''
                : props.currentObject.Particularities
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                Particularities:
                  event.target.value === '' ? null : event.target.value,
              })
            }
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </>
  );
};
