import React, { useEffect, useState } from 'react';
import {
  IInheritHasPerson,
  IPerson,
  IPurchaseContractHasPerson,
} from '../../Interfaces/IPerson';
import {
  Alert,
  Collapse,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import PostcodeSearch from '../generic/PostcodeSearch';
import { IPostcode } from '../../Interfaces/IPostcode';
import { useTranslation } from 'react-i18next';
import { checkTranslation } from '../core/standardFunctions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { academicTitles, countries, genders } from '../../constants';

interface IProps {
  personObject: IPerson;
  personArray: IInheritHasPerson[] | IPerson[] | IPurchaseContractHasPerson[];
  setPersonObject: Function;
  titleAdress?: string;
  showDeathSettings?: boolean;
  forceDeathSettings?: boolean;
  setIsDeath?: Function;
  disableEdit?: boolean;
  minimalRequiremend?: boolean;

  forceAdress?: boolean;
  disableDeathDateWarning?: boolean;

  disableContactInformations?: boolean;

  showTaxNumber?: boolean;
  showBankInformations?: boolean;
}

const handlePostcode = (localObject: IPerson) => {
  if (
    localObject.idPostcode != null &&
    localObject.idPostcode > 0 &&
    localObject.City != undefined &&
    localObject.Postcode != undefined
  ) {
    return {
      idPostcode: localObject.idPostcode,
      City: localObject.City,
      Postcode: localObject.Postcode,
      idCountry: 1,
    } as IPostcode;
  } else {
    return null;
  }
};

export const GridBaseData: React.FC<IProps> = props => {
  const currentYear = dayjs();
  const { t } = useTranslation();

  //
  const [postcodeObject, setPostcodeObject] = useState<
    IPostcode | null | undefined
  >(handlePostcode(props.personObject));
  const [isDeathSetingsEnabled, setIsDeathSetingsEnabled] = useState(
    props.personObject.DateOfDeath !== null &&
      props.personObject.DateOfDeath !== '' &&
      props.personObject.DateOfDeath !== undefined
  );
  const [isOpenWarningBornAfterDeath, setisOpenWarningBornAfterDeath] =
    useState(false);

  const setPostcodeObjectWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject != undefined) {
      props.setPersonObject({
        ...props.personObject,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as IPerson);
    }
    setPostcodeObject(postcodeObject);
  };

  useEffect(() => {
    if (props.setIsDeath !== undefined) {
      props.setIsDeath(isDeathSetingsEnabled);
    }
  }, [isDeathSetingsEnabled]);

  useEffect(() => {
    if (props.personArray != undefined && props.personArray.length > 0) {
      if (props.personArray[0].DateOfDeath != undefined) {
        const dateOfDeath = new Date(props.personArray[0].DateOfDeath);
        const birthdayDecendant = new Date(props.personObject.Birthday);

        if (dateOfDeath < birthdayDecendant) {
          setisOpenWarningBornAfterDeath(true);
        }
        if (dateOfDeath > birthdayDecendant) {
          setisOpenWarningBornAfterDeath(false);
        }
      }
    }
  }, [props.personObject.Birthday]);

  const handleDateChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      // Hier das Datum in einen deutschen Datumsstring umwandeln
      const dateString = newValue.format('YYYY-MM-DD'); // Deutsches Datumsformat
      props.setPersonObject({
        ...props.personObject,
        Birthday: dateString,
      });
    } else {
      props.setPersonObject({
        ...props.personObject,
        Birthday: '',
      });
    }
  };

  const handleDateOfDeathChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      // Hier das Datum in einen deutschen Datumsstring umwandeln
      const dateString = newValue.format('YYYY-MM-DD'); // Deutsches Datumsformat
      props.setPersonObject({
        ...props.personObject,
        DateOfDeath: dateString,
      });
    } else {
      props.setPersonObject({
        ...props.personObject,
        DateOfDeath: '',
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant='h6' sx={{ mt: 3 }}>
            Personendaten
          </Typography>
        </Grid>

        <Grid item xs={6} md={2}>
          <TextField
            label={checkTranslation(t, 'Gender', 'Geschlecht')}
            size='small'
            fullWidth
            required
            select
            disabled={
              props.disableEdit !== undefined && props.disableEdit === true
            }
            value={
              props.personObject.idGender < 0 ? '' : props.personObject.idGender
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setPersonObject({
                ...props.personObject,
                idGender: Number(event.target.value),
              })
            }
          >
            {genders.map(x => (
              <MenuItem key={`idGender-${x.idGender}`} value={x.idGender}>
                {x.Gender}
              </MenuItem>
            ))}
            required
          </TextField>
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            label='Titel'
            size='small'
            fullWidth
            select
            disabled={
              props.disableEdit !== undefined && props.disableEdit === true
            }
            value={props.personObject.idAcademicTitle}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setPersonObject({
                ...props.personObject,
                idAcademicTitle: Number(event.target.value),
              })
            }
          >
            {academicTitles.map(x => (
              <MenuItem
                key={`idAcademicTitle-${x.idAcademicTitle}`}
                value={x.idAcademicTitle}
              >
                {x.AcademicTitle}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label='Vorname(n)'
            size='small'
            fullWidth
            disabled={
              props.disableEdit !== undefined && props.disableEdit === true
            }
            value={props.personObject.FirstName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setPersonObject({
                ...props.personObject,
                FirstName: event.target.value,
              })
            }
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label='Nachname'
            size='small'
            fullWidth
            disabled={
              props.disableEdit !== undefined && props.disableEdit === true
            }
            value={props.personObject.LastName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setPersonObject({
                ...props.personObject,
                LastName: event.target.value,
              })
            }
            required
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <TextField
            label='Staatsangehörigkeit'
            size='small'
            fullWidth
            select
            disabled={
              props.disableEdit !== undefined && props.disableEdit === true
            }
            value={
              props.personObject.idCountry === null
                ? 1
                : props.personObject.idCountry
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setPersonObject({
                ...props.personObject,
                idCountry: Number(event.target.value),
              })
            }
          >
            {countries.map(x => (
              <MenuItem key={`idCountry-${x.idCountry}`} value={x.idCountry}>
                {x.Nationality}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            label='Geburtsname'
            size='small'
            fullWidth
            disabled={
              props.disableEdit !== undefined && props.disableEdit === true
            }
            value={
              props.personObject.Surname === null
                ? ''
                : props.personObject.Surname
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setPersonObject({
                ...props.personObject,
                Surname: event.target.value === '' ? null : event.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Collapse
            in={
              isOpenWarningBornAfterDeath &&
              (props.disableDeathDateWarning === undefined ||
                props.disableDeathDateWarning === false)
            }
          >
            <br></br>
            <Alert severity='warning'>
              Falls der Geburtstag des Erbens nach dem Todestag des Erblassers
              liegt, ist eine gesonderte Prüfung notwendig.
            </Alert>
            <br></br>
          </Collapse>
        </Grid>

        <Grid item xs={6} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
            <DatePicker
              label='Geburtstag'
              maxDate={currentYear}
              openTo='year'
              views={['year', 'month', 'day']}
              value={
                props.personObject.Birthday
                  ? dayjs(props.personObject.Birthday)
                  : null
              }
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  size: 'small',
                  fullWidth: true,
                  required: true,
                  disabled:
                    props.disableEdit !== undefined &&
                    props.disableEdit === true,
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            label='Geburtsort'
            size='small'
            fullWidth
            disabled={
              props.disableEdit !== undefined && props.disableEdit === true
            }
            value={
              props.personObject.Birthplace === null
                ? ''
                : props.personObject.Birthplace
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setPersonObject({
                ...props.personObject,
                Birthplace:
                  event.target.value === '' ? null : event.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={6}>
          {props.showDeathSettings !== undefined &&
            props.showDeathSettings === true && (
              <FormControlLabel
                sx={{ float: 'left' }}
                control={
                  <Switch
                    disabled={
                      props.disableEdit !== undefined &&
                      props.disableEdit === true
                    }
                    checked={isDeathSetingsEnabled}
                    onChange={() => {
                      if (isDeathSetingsEnabled === true) {
                        props.setPersonObject({
                          ...props.personObject,
                          DateOfDeath: null,
                          PlaceOfDeath: null,
                        });
                      }
                      setIsDeathSetingsEnabled(!isDeathSetingsEnabled);
                    }}
                    name='gilad'
                  />
                }
                label='Verstorben'
              />
            )}
        </Grid>

        <Grid item xs={12} md={12}>
          <Collapse
            in={
              (props.forceDeathSettings !== undefined &&
                props.forceDeathSettings === true) ||
              isDeathSetingsEnabled
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant='h6' sx={{ mt: 3 }}>
                  Datum und Ort des Todes
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale='de'
                >
                  <DatePicker
                    label='Sterbedatum'
                    maxDate={currentYear}
                    openTo='year'
                    views={['year', 'month', 'day']}
                    value={
                      props.personObject.DateOfDeath
                        ? dayjs(props.personObject.DateOfDeath)
                        : null
                    }
                    onChange={handleDateOfDeathChange}
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                        required:
                          (props.forceDeathSettings !== undefined &&
                            props.forceDeathSettings === true) ||
                          isDeathSetingsEnabled,
                        disabled:
                          props.disableEdit !== undefined &&
                          props.disableEdit === true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Sterbeort'
                  size='small'
                  fullWidth
                  disabled={
                    props.disableEdit !== undefined &&
                    props.disableEdit === true
                  }
                  //required={((props.forceDeathSettings !== undefined && props.forceDeathSettings === true) || isDeathSetingsEnabled)}
                  value={
                    props.personObject.PlaceOfDeath === null
                      ? ''
                      : props.personObject.PlaceOfDeath
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.setPersonObject({
                      ...props.personObject,
                      PlaceOfDeath:
                        event.target.value === '' ? null : event.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Collapse>

          {props.showTaxNumber !== undefined &&
            props.showTaxNumber === true && (
              <>
                <Grid item xs={12}>
                  <Typography variant='h6' sx={{ mt: 3 }}>
                    Steueridentifikationsnummer
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Steueridentifikationsnummer'
                    size='small'
                    fullWidth
                    disabled={
                      props.disableEdit !== undefined &&
                      props.disableEdit === true
                    }
                    value={
                      props.personObject.TaxNumber === null
                        ? ''
                        : props.personObject.TaxNumber
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      props.setPersonObject({
                        ...props.personObject,
                        TaxNumber:
                          event.target.value === '' ? null : event.target.value,
                      })
                    }
                  />
                </Grid>
              </>
            )}
        </Grid>

        {(!isDeathSetingsEnabled ||
          (props.forceAdress !== undefined && props.forceAdress === true)) && (
          <>
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ mt: 3 }}>
                {props.titleAdress === undefined
                  ? 'Anschrift'
                  : props.titleAdress}
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <TextField
                label='Straße'
                size='small'
                fullWidth
                required={
                  (!isDeathSetingsEnabled ||
                    (props.forceAdress !== undefined &&
                      props.forceAdress === true)) &&
                  (props.minimalRequiremend === undefined ||
                    props.minimalRequiremend === false)
                }
                disabled={
                  props.disableEdit !== undefined && props.disableEdit === true
                }
                value={props.personObject.Street}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPersonObject({
                    ...props.personObject,
                    Street: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4} md={3}>
              <TextField
                label='Hausnummer'
                size='small'
                fullWidth
                required={
                  (!isDeathSetingsEnabled ||
                    (props.forceAdress !== undefined &&
                      props.forceAdress === true)) &&
                  (props.minimalRequiremend === undefined ||
                    props.minimalRequiremend === false)
                }
                disabled={
                  props.disableEdit !== undefined && props.disableEdit === true
                }
                value={props.personObject.StreetNr}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPersonObject({
                    ...props.personObject,
                    StreetNr: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Adresszusatz'
                size='small'
                fullWidth
                disabled={
                  props.disableEdit !== undefined &&
                  props.disableEdit === true &&
                  (props.minimalRequiremend === undefined ||
                    props.minimalRequiremend === false)
                }
                value={
                  props.personObject.StreetAditional === null
                    ? ''
                    : props.personObject.StreetAditional
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPersonObject({
                    ...props.personObject,
                    StreetAditional:
                      event.target.value === '' ? null : event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <PostcodeSearch
                postcodeObject={postcodeObject}
                setPostcodeObject={setPostcodeObjectWrapper}
                required={
                  (!isDeathSetingsEnabled ||
                    (props.forceAdress !== undefined &&
                      props.forceAdress === true)) &&
                  (props.minimalRequiremend === undefined ||
                    props.minimalRequiremend === false)
                }
                disabledEdit={props.disableEdit}
              />
            </Grid>
          </>
        )}

        {(props.disableContactInformations === undefined ||
          props.disableContactInformations === false) && (
          <>
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ mt: 3 }}>
                Kontaktinformationen
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='E-Mail'
                size='small'
                fullWidth
                value={
                  props.personObject.Email === null
                    ? ''
                    : props.personObject.Email
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPersonObject({
                    ...props.personObject,
                    Email:
                      event.target.value === '' ? null : event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Telefonnummer'
                size='small'
                fullWidth
                value={
                  props.personObject.PhoneNumber === null
                    ? ''
                    : props.personObject.PhoneNumber
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPersonObject({
                    ...props.personObject,
                    PhoneNumber:
                      event.target.value === '' ? null : event.target.value,
                  })
                }
              />
            </Grid>
          </>
        )}

        {props.showBankInformations !== undefined &&
          props.showBankInformations === true && (
            <>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mt: 3 }}>
                  Kontoinformationen
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  label='IBAN'
                  size='small'
                  fullWidth
                  disabled={
                    props.disableEdit !== undefined &&
                    props.disableEdit === true
                  }
                  value={
                    props.personObject.IBAN === null
                      ? ''
                      : props.personObject.IBAN
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.setPersonObject({
                      ...props.personObject,
                      IBAN:
                        event.target.value === '' ? null : event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label='BIC'
                  size='small'
                  fullWidth
                  disabled={
                    props.disableEdit !== undefined &&
                    props.disableEdit === true
                  }
                  value={
                    props.personObject.BIC === null
                      ? ''
                      : props.personObject.BIC
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.setPersonObject({
                      ...props.personObject,
                      BIC:
                        event.target.value === '' ? null : event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Bank'
                  size='small'
                  fullWidth
                  disabled={
                    props.disableEdit !== undefined &&
                    props.disableEdit === true
                  }
                  value={
                    props.personObject.Bank === null
                      ? ''
                      : props.personObject.Bank
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.setPersonObject({
                      ...props.personObject,
                      Bank:
                        event.target.value === '' ? null : event.target.value,
                    })
                  }
                />
              </Grid>
            </>
          )}
      </Grid>
    </>
  );
};
