import React, { useState } from 'react';
import { IRealEstate } from '../../../../../../Interfaces/IRealEstate';
import { Grid, TextField, Typography } from '@mui/material';
import DistrictCourtSearch from '../../../../../generic/DistrictCourtSearch';
import { ICourt } from '../../../../../../Interfaces/IAemter';

interface IProps {
  currentObject: IRealEstate;
  setCurrentObject: Function;
  marginTop?: number;
}

export const RealEstateLandRegister: React.FC<IProps> = props => {
  const handleDistrictCourt = (localObject: IRealEstate) => {
    if (localObject.idCourt !== null && localObject.idCourt > 0) {
      return props.currentObject.Court;
    } else {
      return null;
    }
  };

  const setDistrictCourtObjectWrapper = (
    CourtObject: ICourt | null | undefined
  ) => {
    props.setCurrentObject({
      ...props.currentObject,
      idCourt: CourtObject?.idCourt,
      Court: CourtObject,
    } as IRealEstate);
  };

  const [districtCourtObject, setDistrictCourtObject] = useState<
    ICourt | null | undefined
  >(handleDistrictCourt(props.currentObject));

  return (
    <>
      <Typography
        variant='h5'
        sx={{ mt: props.marginTop === undefined ? 2 : props.marginTop, mb: 2 }}
      >
        Grundbuchinformationen
      </Typography>
      <Typography variant='body1' mb={2}>
        Falls Ihnen die Daten über den GB-Eintrag Ihres Grundstückes vorliegen,
        trage Sie diese bitte ein. Andernfalls können diese Informationen
        nachgereicht oder durch die Mitarbeiter des Notarbüros Ermittelt werden.
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <DistrictCourtSearch
            districtCourtObject={districtCourtObject}
            setDistrictCourtObject={setDistrictCourtObjectWrapper}
            setDistrictCourt={setDistrictCourtObject}
          />
        </Grid>
        <Grid item sm={8}>
          <TextField
            label='Grundbuch'
            size='small'
            fullWidth
            value={props.currentObject.LandRegister}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                LandRegister: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            label='Blatt'
            size='small'
            fullWidth
            value={props.currentObject.SheetLandRegister}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                SheetLandRegister: event.target.value,
              })
            }
          />
        </Grid>

        <Grid item sm={6}>
          <TextField
            label='Flurstück'
            size='small'
            fullWidth
            value={props.currentObject.LandParcel}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                LandParcel: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            label='Flur'
            size='small'
            fullWidth
            value={props.currentObject.Parcel}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                Parcel: event.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
