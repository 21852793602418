import { Box, Button, Typography } from '@mui/material';
import React from 'react';

interface IProps {
  handleBack: Function;
  handleNext: Function;
}

export const StartFounding: React.FC<IProps> = props => {
  return (
    <>
      <Box sx={{ mt: 5 }} />
      <Typography variant='h4' sx={{ mb: 3 }}>
        Unternehmensgründung
      </Typography>

      <Typography>
        Herzlich willkommen!
        <br />
        Mit Hilfe unseres Dialogs können Sie die Gründung Ihres eigenes
        Unternehmens vorbereiten.
        <p />
        Brauchen Sie zwischendurch eine Pause? Sie können Ihre Eingaben
        jederzeit über das Feld <i>Sichern</i> exportieren und speichern. Setzen
        Sie Ihre Eingaben dann über die Klickfläche <i>Wiederherstellung</i>{' '}
        fort.
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button onClick={() => props.handleNext()} variant='contained'>
          Weiter
        </Button>
      </Box>
    </>
  );
};
