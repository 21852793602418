import React from 'react';
import { IDocument } from '../../../../../../../Interfaces/IDocument';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

interface IProps {
  documentArray: IDocument[];
}

export const MortgageDocumentSummary: React.FC<IProps> = props => {
  return (
    <>
      <Typography variant='h6' sx={{ mt: 5 }}>
        Dokumente
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nr.:</TableCell>
            <TableCell>Dateiname</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.documentArray.map((x, i) => (
            <TableRow>
              <TableCell>{i + 1}</TableCell>
              <TableCell>{x.FileName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
