import React, { useState } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { Delete, Download, Search } from '@mui/icons-material';
import { IDocument } from '../../Interfaces/IDocument';
import { getFetch } from '../../hooks/useFetch';
import { useTranslation } from 'react-i18next';
import { checkTranslation } from '../core/standardFunctions';

interface IProps {
  documentObject: IDocument;
  documentArray: IDocument[];
  setDocumentArray: Function;
  allowRemove: boolean;
  justButtons?: boolean;
}

export const DocumentRow: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const [documentObject, setDocumentObject] = useState(props.documentObject);
  //
  const [isOpenDownloaded, setIsOpenDownloaded] = useState(false);

  const base64Download = (localDocumentObject: IDocument) => {
    if (localDocumentObject.Data !== undefined) {
      const byteCharacters = atob(localDocumentObject.Data);
      // Each character's code point (charCode) will be the value of the byte.
      // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
      const byteArray = new Uint8Array(byteNumbers);
      // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
      const blob = new Blob([byteArray], { type: 'text/csv' });
      // Erstelle Link zum BLOB
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = localDocumentObject.FileName;
      a.click();
    }
  };

  const wrapperDownload = (localDocumentObject: IDocument) => {
    setDocumentObject(localDocumentObject);
    base64Download(localDocumentObject);
  };

  const handleDownload = () => {
    if (documentObject.Data !== undefined) {
      base64Download(documentObject);
    } else {
      getFetch('/document/', documentObject.idDocument, wrapperDownload);
    }
  };

  const handleRemove = () => {
    props.setDocumentArray([
      ...props.documentArray.filter(
        x => x.idDocument !== documentObject.idDocument
      ),
    ]);
  };

  if (props.justButtons) {
    return (
      <>
        {documentObject.idDocument > 0 && (
          <IconButton
            title='Anzahl Downloads'
            onClick={() => setIsOpenDownloaded(true)}
          >
            <Search />
          </IconButton>
        )}
        <IconButton title={t('file_download')} onClick={handleDownload}>
          <Download />
        </IconButton>
        {props.allowRemove && (
          <IconButton
            title={checkTranslation(t, 'file_delete', 'Datei löschen')}
            onClick={handleRemove}
          >
            <Delete />
          </IconButton>
        )}
      </>
    );
  } else {
    return (
      <>
        <TableRow>
          <TableCell width={'33,3%'}>
            {documentObject.idDocument < 0 ? (
              <>Neu</>
            ) : (
              documentObject.idDocument
            )}
          </TableCell>
          <TableCell width={'33,3%'}>{documentObject.FileName}</TableCell>
          <TableCell width={'33,3%'}>
            {documentObject.idDocument > 0 && (
              <IconButton
                title='Anzahl Downloads'
                onClick={() => setIsOpenDownloaded(true)}
              >
                <Search />
              </IconButton>
            )}
            <IconButton
              title={checkTranslation(t, 'file_download', 'Herunterladen')}
              onClick={handleDownload}
            >
              <Download />
            </IconButton>
            <IconButton
              title={checkTranslation(t, 'file_delete', 'Löschen')}
              onClick={handleRemove}
            >
              <Delete />
            </IconButton>
          </TableCell>
        </TableRow>
      </>
    );
  }
};
