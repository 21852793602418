import React from 'react';
import { ICompanyFormationQuestion } from '../../../../../../Interfaces/ICompanyFormationQuestion';
import { ICompanyFormationOption } from '../../../../../../Interfaces/ICompanyFormationOption';
import { Box, Grid } from '@mui/material';
import { ICompanyFormationAnswer } from '../../../../../../Interfaces/ICompanyFormationAnswer';

interface IProps {
  companyFormationAnswerArray: ICompanyFormationAnswer[];
  companyFormationQuestionArray: ICompanyFormationQuestion[];
  companyFormationOptionArray: ICompanyFormationOption[];
}

export const CompanyFormationAnswerSummary: React.FC<IProps> = props => {
  return (
    <>
      <Box sx={{ mt: 2 }} />
      <Grid container sx={{ pl: 10 }} spacing={2}>
        {props.companyFormationAnswerArray.map(
          (companyFormationAnswerObject, index) => {
            const foundQuestion = props.companyFormationQuestionArray.find(
              x =>
                x.idCompanyFormationQuestion ===
                companyFormationAnswerObject.idCompanyFormationQuestion
            );
            const foundOption = props.companyFormationOptionArray.find(
              x =>
                x.idCompanyFormationOption ===
                companyFormationAnswerObject.idCompanyFormationOption
            );

            if (foundQuestion === undefined) {
              return (
                <Grid item sm={12} key={index}>
                  <b>Fehler</b>
                </Grid>
              );
            } else if (
              foundOption === undefined ||
              foundOption.idCompanyFormationOption === -1
            ) {
              return (
                <React.Fragment key={index}>
                  <Grid item sm={4}>
                    <b>{foundQuestion.CompanyFormationQuestion}:</b>
                  </Grid>
                  <Grid item sm={8}>
                    -
                  </Grid>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <Grid item sm={4}>
                    <b>{foundQuestion.CompanyFormationQuestion}:</b>
                  </Grid>
                  <Grid item sm={8}>
                    {foundOption.CompanyFormationOption}
                  </Grid>
                  {(Boolean(foundOption.allowNumberValue) ||
                    Boolean(foundOption.allowTextfield)) && (
                    <>
                      <Grid item sm={4}></Grid>
                      <Grid item sm={8}>
                        {foundOption.allowNumberValue
                          ? companyFormationAnswerObject.NumberValue
                          : companyFormationAnswerObject.TextValue}
                      </Grid>
                    </>
                  )}
                </React.Fragment>
              );
            }
          }
        )}
      </Grid>
    </>
  );
};

{
  /*
                {props.companyFormationAnswerArray.map(companyFormationAnswerObject => {
                    let foundQuestion = props.companyFormationQuestionArray.find(x => x.idCompanyFormationQuestion === companyFormationAnswerObject.idCompanyFormationQuestion);
                    let foundOption = props.companyFormationOptionArray.find(x => x.idCompanyFormationOption === companyFormationAnswerObject.idCompanyFormationOption);

                    if (foundQuestion === undefined) {
                        return <Grid item sm={12}><b>Fehler</b></Grid>
                    }
                    else if (foundOption === undefined || foundOption.idCompanyFormationOption === -1) {
                        return(
                            <>
                                <Grid item sm={4}><b>{foundQuestion.CompanyFormationQuestion}:</b></Grid>
                                <Grid item sm={8}>-</Grid>
                            </>
                        )
                    }
                    else {
                        return(
                            <>
                                <Grid item sm={4}><b>{foundQuestion.CompanyFormationQuestion}:</b></Grid>
                                <Grid item sm={8}>{foundOption.CompanyFormationOption}</Grid>
                                {(Boolean(foundOption.allowNumberValue) || Boolean(foundOption.allowTextfield)) &&
                                    <>
                                    <Grid item sm={4}></Grid>
                                    <Grid item sm={8}>
                                        {(foundOption.allowNumberValue) ? companyFormationAnswerObject.NumberValue : companyFormationAnswerObject.TextValue}
                                    </Grid>
                                    </>
                                }
                            </>
                        )
                    }
                })}
*/
}
