import React, { useState } from 'react';
import CSS from 'csstype';
import { IConfig } from './Interfaces/IConfig';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  useMediaQuery,
} from '@mui/material';

interface IProps {
  configObject: IConfig;
}

export const CopyrightLextorByte: React.FC<IProps> = ({ configObject }) => {
  const isDesktop = useMediaQuery('(min-width:900px)');

  const [isOpenDsgvo, setIsOpenDsgvo] = useState(false);
  const [isOpenInprint, setIsOpenInprint] = useState(false);

  const containerStyles: CSS.Properties = {
    position: 'relative',
    //height: "100vh",
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  };

  const copyrightStyles2: CSS.Properties = {
    position: 'fixed',
    bottom: '1%',
    transform: 'translateY(50%)',
    background:
      'linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
    //padding: "20px",
    fontSize: '8px',
  };

  const containerStylesLinks: CSS.Properties = {
    position: 'relative',
    //height: "100vh",
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'right',
    marginRight: '300px',
  };

  const copyrightStyles2Links: CSS.Properties = {
    position: 'fixed',
    bottom: '1.3%',
    transform: 'translateY(50%)',
    background:
      'linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
    //padding: "20px",
    fontSize: '12px',
  };

  const containerMobileStyles = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff', // Hintergrundfarbe, optional
    padding: '5px', // Innenabstand, optional
    zIndex: 1000, // Sicherstellen, dass es über anderen Elementen angezeigt wird
    fontSize: '8px',
    display: 'flex',
  };

  return (
    <>
      <Dialog open={isOpenDsgvo} onClose={() => setIsOpenDsgvo(false)}>
        <DialogTitle>Datenschutzerklärung</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: configObject.gdpr_html }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenDsgvo(false)}>Okay</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isOpenInprint} onClose={() => setIsOpenInprint(false)}>
        <DialogTitle>Impressum</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: configObject.gdpr_html }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenInprint(false)}>Okay</Button>
        </DialogActions>
      </Dialog>

      {isDesktop ? (
        <>
          <div className='containerStyles' style={containerStyles}>
            <div className='copyrightStyles2' style={copyrightStyles2}>
              &#169; LeXtorByte UG (haftungsbeschränkt)
            </div>
          </div>

          <div className='containerStylesLinks' style={containerStylesLinks}>
            <div
              className='copyrightStyles2Links'
              style={copyrightStyles2Links}
            >
              <Link
                sx={{ ml: 2, cursor: 'pointer' }}
                onClick={() => setIsOpenDsgvo(true)}
              >
                Datenschutz
              </Link>
              <Link
                sx={{ ml: 2, cursor: 'pointer' }}
                onClick={() => setIsOpenInprint(true)}
              >
                Impressum
              </Link>
            </div>
          </div>
        </>
      ) : (
        <>
          <Box sx={{ pt: 10 }} />

          <Box className='containerMobileStyles' sx={containerMobileStyles}>
            <Box>&#169; LeXtorByte UG (haftungsbeschränkt)</Box>
            <Box>
              <Link
                sx={{ ml: 2, cursor: 'pointer' }}
                onClick={() => setIsOpenDsgvo(true)}
              >
                Datenschutz
              </Link>
            </Box>
            <Box>
              <Link
                sx={{ ml: 2, cursor: 'pointer' }}
                onClick={() => setIsOpenInprint(true)}
              >
                Impressum
              </Link>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
