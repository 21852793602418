import React, { useState, useEffect, useRef } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { IMortgage } from '../../../../../../Interfaces/IMortgage';
import { IPerson } from '../../../../../../Interfaces/IPerson';
import CompanyModifiedBaseInformation from '../Components/Owner/CompanyModifiedBaseInformation';
import { GridBaseData } from '../../../../../Person/GridBaseData';
import { ICompany } from '../../../../../../Interfaces/ICompany';
import { NumericFormat } from 'react-number-format';

interface IProps {
  handleBack: Function;
  handleNext: Function;
  mortgageObject: IMortgage;
  setMortgageObject: Function;
}

const transcriptOptions = [
  'simple_transcription',
  'certified_copy',
  'simple_execution',
  'enforceable_copy',
] as const;

type TranscriptOption = (typeof transcriptOptions)[number];

export const getEmptyCompany = (idCompany: number) => {
  return {
    idCompany: idCompany,
    Company: '',
    idBusinessForm: 1,
    Street: '',
    StreetNr: '',
    StreetAditional: '',
    idPostcode: -1,
    RegisterNumber: null,
    RegisterCourt: null,
    TaxNumber: null,
    SalesTaxNumber: null,

    PhoneNumber: '',
    Website: '',
    Email: '',

    Postcode: '',
    City: '',
  } as ICompany;
};

export const getMortgagePerson2 = (idPerson: number) => {
  return {
    idPerson: idPerson,
    idGender: 4,
    idAcademicTitle: 1,
    idPostcode: -1,
    idCountry: 1,
    FirstName: '',
    LastName: '',
    Surname: null,
    idBusinessForm: 1,
    Email: null,
    PhoneNumber: null,

    Postcode: '',
    City: '',
    Street: '',
    StreetAditional: '',
    StreetNr: '',

    TaxNumber: null,
    IBAN: null,
    BIC: null,
    Bank: null,
  } as IPerson;
};

export const MortgageBeneficiary: React.FC<IProps> = props => {
  const formRef = useRef<HTMLFormElement>(null);

  const [creditor, setCreditor] = useState<IPerson | ICompany | null>(
    props.mortgageObject.BeneficiaryDetails || null
  );

  const [isPrivatePerson, setIsPrivatePerson] = useState<
    boolean | null | undefined
  >(
    props.mortgageObject.BeneficiaryDetails &&
      ('idPerson' in props.mortgageObject.BeneficiaryDetails ||
        'idCompany' in props.mortgageObject.BeneficiaryDetails)
      ? 'idPerson' in props.mortgageObject.BeneficiaryDetails
        ? true
        : false
      : null
  );

  const [paragraph873, setParargraph873] = useState(
    props.mortgageObject.Paragraph
  );

  const initialCheckedOptions: Record<TranscriptOption, boolean> = {
    simple_transcription:
      props.mortgageObject.Transcripts?.includes('simple_transcription') ||
      false,
    certified_copy:
      props.mortgageObject.Transcripts?.includes('certified_copy') || false,
    simple_execution:
      props.mortgageObject.Transcripts?.includes('simple_execution') || false,
    enforceable_copy:
      props.mortgageObject.Transcripts?.includes('enforceable_copy') || false,
  };

  const [checkedOptions, setCheckedOptions] = useState(initialCheckedOptions);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedOptions({
      ...checkedOptions,
      [event.target.name as TranscriptOption]: event.target.checked,
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'true') {
      setIsPrivatePerson(true);
      setCreditor(getMortgagePerson2(-1));
    } else if (event.target.value === 'false') {
      setIsPrivatePerson(false);
      setCreditor(getEmptyCompany(-1));
    }
  };

  useEffect(() => {
    const selectedTranscripts = transcriptOptions.filter(
      option => checkedOptions[option]
    );

    props.setMortgageObject({
      ...props.mortgageObject,
      BeneficiaryDetails: {
        ...creditor,
      },
      Paragraph: paragraph873,
      Transcripts: selectedTranscripts,
    });
  }, [creditor, paragraph873, checkedOptions]);

  const handleSubmit = () => {
    props.handleNext();
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSubmit();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements).reverse();

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  const isFormValid = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      return true;
    } else {
      return false;
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <form ref={formRef}>
        <Box sx={{ mt: 6 }} />
        <Typography variant='h4'>Begünstigter des Grundpfands</Typography>
        <Typography sx={{ mb: 2, mt: 6 }}>
          Geben Sie den Namen und die Adresse (ggf. Geburtsdatum) des Gläubigers
          (Kreditgebers) ein. Ist der Kreditgeber ein Unternehmen, geben Sie
          bitte den Firmennamen und die Adresse ein.
        </Typography>
        <FormControl component='fieldset'>
          <RadioGroup
            value={isPrivatePerson === null ? '' : isPrivatePerson?.toString()}
            onChange={handleRadioChange}
          >
            <Alert severity={'info'} icon={false} sx={{ mb: 2 }}>
              <FormControlLabel
                value='false'
                control={<Radio required />}
                label={
                  <Typography component='span'>
                    Gläubiger ist ein Unternehmen.
                  </Typography>
                }
              />
            </Alert>
            <Alert severity={'info'} icon={false}>
              <FormControlLabel
                value='true'
                control={<Radio required />}
                label={
                  <Typography component='span'>
                    Gläubiger ist ein Privatperson.
                  </Typography>
                }
              />
            </Alert>
          </RadioGroup>
        </FormControl>

        <Box sx={{ mt: 6 }} />
        {creditor && 'idPerson' in creditor && (
          <GridBaseData
            personObject={creditor as IPerson}
            personArray={[]}
            setPersonObject={setCreditor}
            disableContactInformations={true}
          />
        )}
        {creditor && 'idCompany' in creditor && (
          <CompanyModifiedBaseInformation
            companyObject={creditor as ICompany}
            setCompanyObject={setCreditor}
          />
        )}

        <Box sx={{ mt: 6 }} />
        <Typography sx={{ mb: 2 }}>
          Über welchen Betrag soll die Grundschuld bestellt werden?
        </Typography>
        <Grid item xs={6} sm={4}>
          <NumericFormat
            label='Betrag der Grundschuld'
            size='small'
            suffix='€'
            fixedDecimalScale
            decimalScale={2}
            thousandsGroupStyle='thousand'
            thousandSeparator='.'
            decimalSeparator=','
            fullWidth
            required
            value={props.mortgageObject.AmountMortgage}
            customInput={TextField}
            inputRef={inputRef} // Referenz zum Textfeld
            onValueChange={values => {
              const { floatValue } = values;
              const cursorPosition = inputRef.current?.selectionStart;
              props.setMortgageObject({
                ...props.mortgageObject,
                AmountMortgage: floatValue !== undefined ? floatValue : null,
              });
              // Nach dem Setzen des Werts die Cursor-Position wiederherstellen
              setTimeout(() => {
                if (inputRef.current && cursorPosition) {
                  inputRef.current.setSelectionRange(
                    cursorPosition,
                    cursorPosition
                  );
                }
              }, 0); // Die Wiederherstellung erfolgt nach einem Render-Zyklus
            }}
            allowNegative={false}
          />
        </Grid>

        <Box sx={{ mt: 6 }} />
        <Typography sx={{ mb: 2 }}>
          Wie hoch sind die sog. Grundschuldzinsen (in %)?
        </Typography>
        <Grid item xs={6} sm={4}>
          <NumericFormat
            value={props.mortgageObject.MortgageInterest || ''}
            suffix='%'
            decimalSeparator='.'
            label='Grundschuldzinsen'
            size='small'
            fullWidth
            required
            customInput={TextField}
            decimalScale={2} // Maximale Anzahl der Dezimalstellen
            fixedDecimalScale={true} // Immer zwei Dezimalstellen anzeigen
            allowNegative={false} // Negative Werte nicht erlauben
            isAllowed={values => {
              const { floatValue } = values;

              if (floatValue && floatValue >= 100) {
                return false;
              }
              return true;
            }}
            onValueChange={values => {
              const { floatValue } = values;
              props.setMortgageObject({
                ...props.mortgageObject,
                MortgageInterest: floatValue !== undefined ? floatValue : null,
              });
            }}
          />
        </Grid>

        <Box sx={{ mt: 6 }} />
        <Typography sx={{ mb: 2 }}>Gibt es Nebenleistungen?</Typography>
        <Grid item xs={6} sm={4}>
          <TextField
            label='Nebenleistungen'
            size='small'
            fullWidth
            rows={3}
            multiline
            value={props.mortgageObject.AdditionalServices}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setMortgageObject({
                ...props.mortgageObject,
                AdditionalServices: event.target.value,
              })
            }
          />
        </Grid>
        <Box sx={{ mt: 6 }} />

        <Box sx={{ mt: 6 }}>
          <Typography sx={{ mb: 2 }}>
            Welche Abschriften soll der Gläubiger erhalten?
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedOptions.simple_transcription}
                  onChange={handleChange}
                  name='simple_transcription'
                />
              }
              label='Einfache Abschrift'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedOptions.certified_copy}
                  onChange={handleChange}
                  name='certified_copy'
                />
              }
              label='Beglaubigte Abschrift'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedOptions.simple_execution}
                  onChange={handleChange}
                  name='simple_execution'
                />
              }
              label='Einfache Ausfertigung'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedOptions.enforceable_copy}
                  onChange={handleChange}
                  name='enforceable_copy'
                />
              }
              label='Vollstreckbare Ausfertigung'
            />
          </FormGroup>
        </Box>

        <Box sx={{ mt: 6 }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            variant='outlined'
            onClick={() => props.handleBack()}
            sx={{ mr: 1 }}
          >
            Zurück
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            onClick={() => {
              handleOnSave();
            }}
            variant={isFormValid() ? 'contained' : 'outlined'}
          >
            Weiter
          </Button>
        </Box>
      </form>
    </>
  );
};
