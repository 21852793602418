import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { IInheritHasPerson } from '../../../../../../Interfaces/IPerson';
import { GridBaseData } from '../../../../../Person/GridBaseData';
import { IRequiredFiles, InheritDocument } from './InheritDocument';
import { IPersonHasDocument } from '../../../../../../Interfaces/IPersonHasDocument';
import { IInherit } from '../../../../../../Interfaces/IInherit';

interface IProps {
  title: string;
  currentPerson: IInheritHasPerson;
  setCurrentPerson: Function;
  inheritObject: IInherit;
  setInheritObject?: Function;
  requiredFiles: IRequiredFiles[];
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  titleAdress?: string;
  showDeathSettings?: boolean;
  forceDeathSettings?: boolean;
  setIsDeath?: Function;
  disableEdit?: boolean;
  personArray: IInheritHasPerson[];
  forceAdress?: boolean;
  disableDeathDateWarning?: boolean;
}

export const InheritPerson: React.FC<IProps> = ({
  title,
  currentPerson,
  setCurrentPerson,
  inheritObject,
  setInheritObject,
  requiredFiles,
  personHasDocument,
  setPersonHasDocument,
  titleAdress,
  showDeathSettings,
  forceDeathSettings,
  setIsDeath,
  disableEdit,
  personArray,
  forceAdress,
  disableDeathDateWarning,
}) => {
  const [isOpenWarning, setIsOpenWarning] = useState(false);

  return (
    <>
      {false && inheritObject.idPerson !== currentPerson.idPerson && (
        <Dialog open={isOpenWarning} onClose={() => setIsOpenWarning(false)}>
          <DialogContent>
            <Alert severity='warning'>
              Sobald Sie diese Option aktiveren, sind nur noch die Felder{' '}
              <i>Geschlecht</i>, <i>Vorname</i> und <i>Nachname</i> pflicht.
              <br />
              Wir weisen dringlichst darauf hin, dass Sie diese Option nur im
              Sonderfall aktivieren sollten, um eine schnelle und reibungslose
              Abwicklung zu gewährleisetn.
            </Alert>
            <Box sx={{ mt: 2 }} />
            Sollen die Pflichtfelder aktiviert oder deaktiviert werden?
          </DialogContent>

          <DialogActions>
            <Button
              variant='outlined'
              onClick={() => {
                setIsOpenWarning(false);
              }}
            >
              Deaktivieren
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                setIsOpenWarning(false);
              }}
            >
              Aktivieren
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Typography variant='h5'>{title}</Typography>

      <GridBaseData
        personObject={currentPerson}
        personArray={personArray}
        setPersonObject={setCurrentPerson}
        showDeathSettings={showDeathSettings}
        forceDeathSettings={forceDeathSettings}
        titleAdress={titleAdress}
        setIsDeath={setIsDeath}
        disableEdit={disableEdit}
        forceAdress={forceAdress}
        disableDeathDateWarning={disableDeathDateWarning}
        minimalRequiremend={inheritObject.idPerson !== currentPerson.idPerson}
        disableContactInformations
      />

      {setInheritObject !== undefined && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5' sx={{ mt: 10 }}>
              Testament bzw. Erbvertrag
            </Typography>
          </Grid>

          <Grid item xs={12}>
            Liegt ein Testament oder ein Erbvertrag vor?
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                variant={inheritObject.hasTestament ? 'outlined' : 'contained'}
                onClick={() =>
                  setInheritObject!({
                    ...inheritObject,
                    hasTestament: false,
                  })
                }
                sx={{ mr: 1 }}
              >
                Nein
              </Button>

              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                variant={inheritObject.hasTestament ? 'contained' : 'outlined'}
                onClick={() =>
                  setInheritObject!({
                    ...inheritObject,
                    hasTestament: true,
                  })!
                }
              >
                Ja
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={inheritObject.hasTestament}>
              <InheritDocument
                key={`InheritDocument-testament`}
                idPerson={currentPerson.idPerson}
                requiredFile={
                  {
                    idPersonDocumentType: 5,
                    isOptional: false,
                    Title: 'Testament',
                  } as IRequiredFiles
                }
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
                disableEdit={disableEdit}
              />
            </Collapse>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        {requiredFiles.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant='h5' sx={{ mt: 10 }}>
                Benötigte Dokumente
              </Typography>
            </Grid>
            {requiredFiles.map((x, i) => (
              <InheritDocument
                key={`InheritDocument-${i}`}
                idPerson={currentPerson.idPerson}
                requiredFile={x}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
                disableEdit={disableEdit}
              />
            ))}
          </>
        )}
      </Grid>
      <Box sx={{ mt: 10 }} />
    </>
  );
};
