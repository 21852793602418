import React, { useState } from 'react';
import { IInheritHasPerson } from '../../../../../../../Interfaces/IPerson';
import { IChildren } from '../../../../../../../Interfaces/IChildren';
import { InheritPerson } from '../InheritPerson';
import {
  Box,
  Button,
  Fade,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { IPersonHasDocument } from '../../../../../../../Interfaces/IPersonHasDocument';
import { checkIsDisabled } from '../../../../../../core/getDisabledHelper_Inherit';
import { IInherit } from '../../../../../../../Interfaces/IInherit';
import { IMarriage } from '../../../../../../../Interfaces/IMarriage';
import { DialogResponsive } from '../../../../../../core/DialogResponsive';
import {
  getChildren,
  getInheritPerson,
  getRequiredFiles,
} from '../../../../../../../services/dialog.service';

interface IProps {
  inheritObject: IInherit;
  setIsOpenNew: Function;
  parentPersonArray: IInheritHasPerson[];
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray: IMarriage[];
}

export const ChildrenAddDialog: React.FC<IProps> = ({
  inheritObject,
  setIsOpenNew,
  parentPersonArray,
  personArray,
  setPersonArray,
  childrenArray,
  setChildrenArray,
  personHasDocument,
  setPersonHasDocument,
  marriageArray,
}) => {
  const [idPersonParrent, setIdPersonParrent] = useState<number | null>(null);
  const [idPersonParrentSecond, setIdPersonParrentSecond] = useState<
    number | null
  >(null);
  const [currentPage, setCurrentPage] = useState(
    idPersonParrent === null ? 0 : 1
  );
  //
  const [currentChildren, setCurrentChildren] = useState(
    getChildren(true, -1, childrenArray)
  );
  const [currentPerson, setCurrentPerson] = useState(
    getInheritPerson(-1, personArray, true)
  );
  const [currentPersonHasDocument, setCurrentPersonHasDocument] = useState<
    IPersonHasDocument[]
  >([]);
  const [isDeath, setIsDeath] = useState(false);

  const getIsDisabled = () => {
    return (
      (currentPage === 0 && idPersonParrent === null) ||
      currentPage === 1 ||
      currentPage === 2 ||
      (currentPage === 3 &&
        checkIsDisabled(inheritObject, isDeath, currentPerson))
    );
  };

  const handleNext = () => {
    if (currentPage === 3 && idPersonParrent !== null) {
      const newChild = {
        ...currentChildren,
        Parent1_idPerson: idPersonParrent,
        Parent2_idPerson: idPersonParrentSecond,
        Children_idPerson: currentPerson.idPerson,
      } as IChildren;
      setChildrenArray([...childrenArray, newChild]);
      setPersonArray([...personArray, currentPerson]);
      setPersonHasDocument([...personHasDocument, ...currentPersonHasDocument]);
      setIsOpenNew(false);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <DialogResponsive
        open={true}
        onClose={() => {}}
        maxWidth={currentPage < 3 ? 'sm' : 'lg'}
        title='Kind hinzufügen'
        actions={
          <>
            <Button variant='outlined' onClick={() => setIsOpenNew(false)}>
              Abbruch
            </Button>
            <Button
              variant='contained'
              onClick={handleNext}
              disabled={getIsDisabled()}
            >
              {currentPage === 3 ? 'Übernehmen' : 'Weiter'}
            </Button>
          </>
        }
      >
        <Box sx={{ mt: 2 }} />

        <Fade in={currentPage === 0} mountOnEnter unmountOnExit>
          <Box>
            <Typography variant='body1' sx={{ flaot: 'center' }}>
              Bitte wählen Sie das Elternteil/die Elternteile aus.
            </Typography>
            <TextField
              sx={{ mt: 2 }}
              label='Elternteil(e)'
              select
              value={
                idPersonParrent === null
                  ? ''
                  : idPersonParrentSecond === null
                    ? idPersonParrent
                    : `${idPersonParrent}_${idPersonParrentSecond}`
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const splitTest = String(event.target.value).split('_');

                if (splitTest.length === 2) {
                  setIdPersonParrent(Number(splitTest[0]));
                  setIdPersonParrentSecond(Number(splitTest[1]));
                } else {
                  setIdPersonParrent(Number(event.target.value));
                  setIdPersonParrentSecond(null);
                }
              }}
              size='small'
              fullWidth
              required
            >
              {marriageArray
                .filter(
                  x =>
                    parentPersonArray
                      .map(x => x.idPerson)
                      .indexOf(x.Person1_idPerson) > -1 ||
                    parentPersonArray
                      .map(x => x.idPerson)
                      .indexOf(x.Person2_idPerson) > -1
                )
                .map(x => {
                  const firstPerson = personArray.find(
                    y => y.idPerson === x.Person1_idPerson
                  );
                  const secondPerson = personArray.find(
                    y => y.idPerson === x.Person2_idPerson
                  );

                  return (
                    <MenuItem
                      key={`idMarraige-${x.idMarriage}`}
                      value={`${x.Person1_idPerson}_${x.Person2_idPerson}`}
                    >
                      <>
                        {firstPerson?.FirstName} {firstPerson?.LastName} und{' '}
                        {secondPerson?.FirstName} {secondPerson?.LastName}
                      </>
                    </MenuItem>
                  );
                })}
              {parentPersonArray && parentPersonArray.length === 2 && (
                <MenuItem
                  key={`idPerson-${parentPersonArray[0].idPerson}`}
                  value={`${parentPersonArray[0].idPerson}_${parentPersonArray[1].idPerson}`}
                >
                  {parentPersonArray[0].FirstName}{' '}
                  {parentPersonArray[0].LastName} und{' '}
                  {parentPersonArray[1].FirstName}{' '}
                  {parentPersonArray[1].LastName}
                </MenuItem>
              )}
              {parentPersonArray.map(x => (
                <MenuItem key={`idPerson-${x.idPerson}`} value={x.idPerson}>
                  <>
                    {x.FirstName} {x.LastName}
                  </>
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Fade>

        <Fade in={currentPage === 1} mountOnEnter unmountOnExit>
          <Box
            display='flex'
            height={80}
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='body1' sx={{ flaot: 'center' }}>
              Ist das Kind adoptiert?
              <Box
                display='flex'
                alignItems='right'
                justifyContent='right'
                sx={{ flaot: 'right' }}
              >
                <Button
                  onClick={() => {
                    setCurrentChildren({ ...currentChildren, isAdopted: true });
                    setCurrentPage(3);
                  }}
                >
                  Ja
                </Button>
                <Button
                  onClick={() => {
                    setCurrentChildren({
                      ...currentChildren,
                      isAdopted: false,
                    });
                    setCurrentPage(2);
                  }}
                >
                  Nein
                </Button>
              </Box>
            </Typography>
          </Box>
        </Fade>

        <Fade in={currentPage === 2} mountOnEnter unmountOnExit>
          <Box
            display='flex'
            height={80}
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='body1' sx={{ flaot: 'center' }}>
              Wurde das Kind zur Adoption freigegeben?
              <Box
                display='flex'
                alignItems='right'
                justifyContent='right'
                sx={{ flaot: 'right' }}
              >
                <Button
                  onClick={() => {
                    setCurrentChildren({
                      ...currentChildren,
                      isPutUpForAdoption: true,
                    });
                    setCurrentPage(3);
                  }}
                >
                  Ja
                </Button>
                <Button
                  onClick={() => {
                    setCurrentChildren({
                      ...currentChildren,
                      isPutUpForAdoption: false,
                    });
                    setCurrentPage(3);
                  }}
                >
                  Nein
                </Button>
              </Box>
            </Typography>
          </Box>
        </Fade>

        <Fade in={currentPage === 3} mountOnEnter unmountOnExit>
          <Box>
            <InheritPerson
              currentPerson={currentPerson}
              setCurrentPerson={setCurrentPerson}
              inheritObject={inheritObject}
              personArray={personArray}
              personHasDocument={currentPersonHasDocument}
              setPersonHasDocument={setCurrentPersonHasDocument}
              title=''
              showDeathSettings
              requiredFiles={getRequiredFiles(isDeath)}
              setIsDeath={setIsDeath}
            />
          </Box>
        </Fade>
      </DialogResponsive>
    </>
  );
};
