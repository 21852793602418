import React, { useEffect, useState } from 'react';
import { IInheritHasPerson } from '../../../../../../../Interfaces/IPerson';
import { IChildren } from '../../../../../../../Interfaces/IChildren';
import { CustomeSlide } from '../../../../../../core/CustomeSlide';
import { IPersonHasDocument } from '../../../../../../../Interfaces/IPersonHasDocument';
import { ChildrenTable } from './ChildrenTable';
import { Box, Button } from '@mui/material';
import { IInherit } from '../../../../../../../Interfaces/IInherit';
import { IMarriage } from '../../../../../../../Interfaces/IMarriage';
import {
  getCurrentChildrenPage,
  getDeathChildrenArray,
  getLocalChildrenArray,
} from '../../../../../../../services/dialog.service';

interface IProps {
  inheritObject: IInherit;
  parrentPersonArray: IInheritHasPerson[];
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray: IMarriage[];
  handleNext: Function;
  handleBack: Function;
  depth?: number;
}

export const ChildrenDriver: React.FC<IProps> = ({
  inheritObject,
  parrentPersonArray,
  personArray,
  setPersonArray,
  childrenArray,
  setChildrenArray,
  personHasDocument,
  setPersonHasDocument,
  marriageArray,
  handleBack,
  depth,
  handleNext,
}) => {
  const [currentChildrenPage, setCurrentChildrenPage] = useState(
    getCurrentChildrenPage(personArray, childrenArray, parrentPersonArray)
  );
  //
  const [deathChildren, setDeathChildren] = useState<IInheritHasPerson[]>([]);

  const handleNextDialog = () => {
    // if: wenn tote kinder, dann Iteriere
    if (deathChildren.length > 0) {
      setCurrentChildrenPage(1);
      // elif: wenn Children Leer, dann springe auf Eltern/Fehler
    } else if (
      getLocalChildrenArray(childrenArray, parrentPersonArray).filter(
        x => x.Children_idPerson !== -1
      ).length === 0
    ) {
      handleNext();
    } else if (inheritObject.hasTestament) {
      handleNext(6);
    }
    // elfi: wenn alle Kinder leben
    else if (deathChildren.length === 0) {
      handleNext(7);
    } else {
      handleNext();
    }
  };

  const handleBackForChild = () => {
    setCurrentChildrenPage(0);
  };

  useEffect(() => {
    setDeathChildren(
      getDeathChildrenArray(personArray, childrenArray, parrentPersonArray)
    );
  }, [childrenArray, personArray, parrentPersonArray]);

  return (
    <>
      <CustomeSlide
        currentPage={currentChildrenPage}
        pageNumber={0}
        lastCurrentPage={0}
        appear={false}
      >
        <>
          <ChildrenTable
            inheritObject={inheritObject}
            firstParentArray={parrentPersonArray}
            personArray={personArray}
            setPersonArray={setPersonArray}
            personHasDocument={personHasDocument}
            setPersonHasDocument={setPersonHasDocument}
            childrenArray={childrenArray}
            setChildrenArray={setChildrenArray}
            marraigeArray={marriageArray}
          />
          <Box sx={{ mt: 10 }} />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              variant='outlined'
              onClick={() => handleBack()}
              sx={{ mr: 1 }}
            >
              Zurück
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              type='submit'
              variant='contained'
              onClick={handleNextDialog}
            >
              Weiter
            </Button>
          </Box>
        </>
      </CustomeSlide>

      <CustomeSlide
        currentPage={currentChildrenPage}
        pageNumber={1}
        lastCurrentPage={1}
      >
        <ChildrenDriver
          inheritObject={inheritObject}
          key={`children-child-${deathChildren.length}-${
            depth === undefined ? 0 : depth + 1
          }`}
          depth={depth === undefined ? 0 : depth + 1}
          parrentPersonArray={deathChildren}
          personArray={personArray}
          setPersonArray={setPersonArray}
          personHasDocument={personHasDocument}
          setPersonHasDocument={setPersonHasDocument}
          childrenArray={childrenArray}
          setChildrenArray={setChildrenArray}
          marriageArray={marriageArray}
          handleBack={handleBackForChild}
          handleNext={handleNext}
        />
      </CustomeSlide>
    </>
  );
};
