import { IRequiredFiles } from '../components/public/Dialog/InheritNew/Childs/Components/InheritDocument';
import { IChildren } from '../Interfaces/IChildren';
import { IInherit } from '../Interfaces/IInherit';
import { IMarriage } from '../Interfaces/IMarriage';
import { IInheritHasPerson, IPerson } from '../Interfaces/IPerson';
import { IPersonHasDocument } from '../Interfaces/IPersonHasDocument';
import { IPostcode } from '../Interfaces/IPostcode';

export const getNewInheritPerson = {
  idPerson: -1,
  Birthday: '',
  Birthplace: '',
  DateOfDeath: null,
  FirstName: '',
  idAcademicTitle: 1,
  idCountry: 1,
  idGender: -1,
  idInherit: -1,
  idPostcode: null,
  LastName: '',
  PlaceOfDeath: null,
  Street: '',
  Relation_idPerson: null,
  StreetAditional: '',
  StreetNr: '',
  Surname: null,
  idBusinessForm: 1,
  isStranger: false,

  Email: null,
  PhoneNumber: null,

  TaxNumber: null,
  IBAN: null,
  BIC: null,
  Bank: null,
} as IInheritHasPerson;

export const getNewInherit = {
  hasTestament: false,
  idLegalTransaction: -1,
  idInherit: -1,
  idPerson: -1,
  annotations: '',
} as IInherit;

export const getDeathParentArray = (
  childrenArray: IChildren[],
  personArray: IInheritHasPerson[]
) => {
  const foundChildren = childrenArray.find(x => x.Children_idPerson === -1);

  if (foundChildren === undefined) {
    return [] as IInheritHasPerson[];
  } else {
    const returnArray: IInheritHasPerson[] = [];
    const foundFirstParent = personArray.find(
      x => x.idPerson === foundChildren?.Parent1_idPerson
    );
    const foundSecondParent = personArray.find(
      x => x.idPerson === foundChildren?.Parent2_idPerson
    );

    if (
      foundFirstParent !== undefined &&
      foundFirstParent.DateOfDeath !== null
    ) {
      returnArray.push(foundFirstParent);
    }

    if (
      foundSecondParent !== undefined &&
      foundSecondParent.DateOfDeath !== null
    ) {
      returnArray.push(foundSecondParent);
    }

    return returnArray;
  }
};

export const getInheritPerson = (
  idPerson: number,
  personArray: IInheritHasPerson[],
  isNew?: boolean
) => {
  const testObject = personArray.find(x => x.idPerson === idPerson);
  if ((isNew === undefined || isNew === false) && testObject !== undefined) {
    return testObject;
  } else {
    let minId = Math.min(...personArray.map(x => x.idPerson)) - 1;
    if (minId >= -1) {
      minId = -2;
    }

    return {
      idPerson: minId,
      Birthday: '',
      Birthplace: '',
      DateOfDeath: null,
      FirstName: '',
      idAcademicTitle: 1,
      idCountry: 1,
      idGender: -1,
      idInherit: -1,
      idPostcode: null,
      LastName: '',
      PlaceOfDeath: null,
      Street: '',
      Relation_idPerson: null,
      StreetAditional: '',
      StreetNr: '',
      Surname: null,
      idBusinessForm: 1,
      isInherit: false,
      isStranger: false,

      Email: null,
      PhoneNumber: null,

      TaxNumber: null,
      IBAN: null,
      BIC: null,
      Bank: null,
    } as IInheritHasPerson;
  }
};

export const getMarriage = (
  isNew: boolean,
  idMarriage: number,
  marriageArray: IMarriage[],
  idPerson: number
) => {
  const testObject = marriageArray.find(x => x.idMarriage === idMarriage);

  if (isNew === false && testObject !== undefined) {
    return testObject;
  } else {
    let minId = Math.min(...marriageArray.map(x => x.idMarriage)) - 1;
    if (minId > 0) {
      minId = -1;
    }
    return {
      idMarriage: minId,
      Person1_idPerson: idPerson,
      Person2_idPerson: -1,
      DateOfMarriage: null,
      Devorce_idDocument: null,
      DateOfDevorce: null,
      Marriage_idDocument: -1,
      isDivorced: false,
    } as IMarriage;
  }
};

export const handlePostcode = (localObject: IPerson) => {
  if (
    localObject.idPostcode != null &&
    localObject.idPostcode > 0 &&
    localObject.City != undefined &&
    localObject.Postcode != undefined
  ) {
    return {
      idPostcode: localObject.idPostcode,
      City: localObject.City,
      Postcode: localObject.Postcode,
      idCountry: 1,
    } as IPostcode;
  } else {
    return null;
  }
};

export const getLocalChildrenArray = (
  childrenArray: IChildren[],
  parrentPersonArray: IInheritHasPerson[]
) => {
  return childrenArray.filter(
    x =>
      parrentPersonArray.map(y => y.idPerson).indexOf(x.Parent1_idPerson) > -1
  );
};

export const getDeathChildrenArray = (
  personArray: IInheritHasPerson[],
  childrenArray: IChildren[],
  parrentPersonArray: IInheritHasPerson[]
) => {
  const testPersonArray = personArray.filter(
    x =>
      getLocalChildrenArray(childrenArray, parrentPersonArray)
        .map(y => y.Children_idPerson)
        .indexOf(x.idPerson) > -1
  );
  return [
    ...testPersonArray
      .filter(x => x.idPerson !== -1)
      .filter(x => x.DateOfDeath !== null),
  ];
};

export const getCurrentChildrenPage = (
  personArray: IInheritHasPerson[],
  childrenArray: IChildren[],
  parrentPersonArray: IInheritHasPerson[]
) => {
  return personArray
    .filter(
      x =>
        getLocalChildrenArray(childrenArray, parrentPersonArray)
          .map(y => y.Children_idPerson)
          .indexOf(x.idPerson) > -1
    )
    .filter(x => x.idPerson !== -1)
    .filter(x => x.DateOfDeath !== null).length === 0
    ? 0
    : 1;
};

export const removePersonRecursive = (
  idPerson: number,
  personArray: IInheritHasPerson[],
  childrenArray: IChildren[]
) => {
  personArray = personArray.filter(x => x.idPerson !== idPerson);

  childrenArray.forEach(x => {
    if (x.Parent1_idPerson === idPerson) {
      personArray = removePersonRecursive(
        x.Children_idPerson,
        personArray,
        childrenArray
      );
    }
  });

  return personArray;
};

export const removePersonHasDocumentRecursive = (
  idPerson: number,
  personHasDocument: IPersonHasDocument[],
  childrenArray: IChildren[]
) => {
  personHasDocument = personHasDocument.filter(x => x.idPerson !== idPerson);
  childrenArray
    .filter(x => x.Parent1_idPerson === idPerson)
    .map(x => {
      const localArray = removePersonHasDocumentRecursive(
        x.Children_idPerson,
        personHasDocument,
        childrenArray
      );
      personHasDocument = [...localArray];
    });
  return personHasDocument;
};

export const removeChildrenRecursive = (
  idPerson: number,
  childrenArray: IChildren[]
) => {
  childrenArray = childrenArray.filter(x => x.Children_idPerson !== idPerson);
  childrenArray
    .filter(x => x.Parent1_idPerson === idPerson)
    .map(x => {
      const localArray = removeChildrenRecursive(
        x.Children_idPerson,
        childrenArray
      );
      childrenArray = [...localArray];
    });
  return childrenArray;
};

export const getRequiredFiles = (isDeath: boolean) => {
  if (isDeath) {
    return [
      { Title: 'Geburtsurkunde', isOptional: false, idPersonDocumentType: 4 },
      { Title: 'Sterbeurkunde', isOptional: false, idPersonDocumentType: 1 },
    ] as IRequiredFiles[];
  } else {
    return [
      { Title: 'Geburtsurkunde', isOptional: false, idPersonDocumentType: 4 },
    ] as IRequiredFiles[];
  }
};

export const getChildren = (
  isNew: boolean,
  idChildren: number,
  childrenArray: IChildren[]
) => {
  const testObject = childrenArray.find(x => x.idChildren === idChildren);
  /*  Test Push Git*/
  if (isNew === false && testObject !== undefined) {
    return testObject;
  } else {
    let minId = Math.min(...childrenArray.map(x => x.idChildren)) - 1;
    if (minId > 0) {
      minId = -1;
    }
    return {
      idChildren: minId,
      Parent1_idPerson: -1,
      Parent2_idPerson: null,
      Children_idPerson: -1,
      Marriage_idMarriage: null,
      isAdopted: false,
      isPutUpForAdoption: false,
    } as IChildren;
  }
};

export const foundParent = (
  isFirstParent: boolean,
  personArray: IInheritHasPerson[],
  childrenArray: IChildren[]
) => {
  const foundChildren = childrenArray.find(x => x.Children_idPerson === -1);

  if (foundChildren === undefined) {
    return getInheritPerson(-1, personArray, true);
  } else {
    const idPerson = isFirstParent
      ? foundChildren.Parent1_idPerson
      : Number(foundChildren.Parent2_idPerson);
    return getInheritPerson(idPerson, personArray, false);
  }
};
