import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TableCell,
  TableRow,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { IPersonHasDocument } from '../../../../../../../Interfaces/IPersonHasDocument';
import { InheritPerson } from '../InheritPerson';
import { checkIsDisabled } from '../../../../../../core/getDisabledHelper_Inherit';
import { IRequiredFiles } from '../InheritDocument';
import { IMarriage } from '../../../../../../../Interfaces/IMarriage';
import { IInheritHasPerson } from '../../../../../../../Interfaces/IPerson';
import { IInherit } from '../../../../../../../Interfaces/IInherit';
import { DialogResponsive } from '../../../../../../core/DialogResponsive';
import {
  getInheritPerson,
  getMarriage,
} from '../../../../../../../services/dialog.service';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface IProps {
  index: number;
  setIsOpenNew?: Function;
  idMarriage: number;
  inheritObject: IInherit;
  inherit: IInheritHasPerson;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray: IMarriage[];
  setMarriageArray: Function;
}

export const MarriageRow: React.FC<IProps> = ({
  index,
  setIsOpenNew,
  idMarriage,
  inheritObject,
  inherit,
  personArray,
  setPersonArray,
  personHasDocument,
  setPersonHasDocument,
  marriageArray,
  setMarriageArray,
}) => {
  const currentYear = dayjs();
  const [currentMarriage, setCurrentMarriage] = useState(
    getMarriage(
      setIsOpenNew !== undefined,
      idMarriage,
      marriageArray,
      inherit.idPerson
    )
  );
  const [currentPerson, setCurrentPerson] = useState(
    getInheritPerson(
      currentMarriage.Person2_idPerson,
      personArray,
      setIsOpenNew !== undefined
    )
  );
  //
  const [editMarriage, setEditMarriage] = useState(currentMarriage);
  const [editPerson, setEditPerson] = useState(currentPerson);
  //
  const [isDeath, setIsDeath] = useState(false);
  const [isOpen, setIsOpen] = useState(setIsOpenNew !== undefined);

  const handleOpen = () => {
    setEditMarriage(currentMarriage);
    setEditPerson(editPerson);
    setIsOpen(true);
  };

  const handleClose = () => {
    if (setIsOpenNew !== undefined) {
      handleRemove();
      setIsOpenNew(false);
    }
    setIsOpen(false);
  };

  const handleRemove = () => {
    setPersonHasDocument([
      ...personHasDocument.filter(x => x.idPerson !== currentPerson.idPerson),
    ]);
    setPersonArray([
      ...personArray.filter(x => x.idPerson !== currentPerson.idPerson),
    ]);
    setMarriageArray([
      ...marriageArray.filter(x => x.idMarriage !== currentMarriage.idMarriage),
    ]);
    setIsOpen(false);
  };

  const handleSave = () => {
    if (setIsOpenNew !== undefined) {
      const newMarriage = {
        ...editMarriage,
        Person2_idPerson: currentPerson.idPerson,
      } as IMarriage;
      setMarriageArray([...marriageArray, newMarriage]);
      setPersonArray([...personArray, editPerson]);
      setIsOpenNew(false);
    } else {
      setMarriageArray([
        ...marriageArray.map(x =>
          x.idMarriage === editMarriage.idMarriage ? editMarriage : x
        ),
      ]);
      setPersonArray([
        ...personArray.map(x =>
          x.idPerson === editPerson.idPerson ? editPerson : x
        ),
      ]);
      setCurrentMarriage(editMarriage);
      setCurrentPerson(editPerson);
    }
    setIsOpen(false);
  };

  const getRequirredArray = () => {
    if (editMarriage.isDivorced) {
      return [
        { Title: 'Eheurkunde', isOptional: false, idPersonDocumentType: 2 },
        {
          Title: 'Scheidungsurkunde',
          isOptional: false,
          idPersonDocumentType: 3,
        },
      ] as IRequiredFiles[];
    } else {
      return [
        { Title: 'Eheurkunde', isOptional: false, idPersonDocumentType: 2 },
      ] as IRequiredFiles[];
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSave();
  };

  const handleDateOfMarriageChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      // Hier das Datum in einen deutschen Datumsstring umwandeln
      const dateString = newValue.format('YYYY-MM-DD'); // Deutsches Datumsformat
      setEditMarriage({
        ...editMarriage,
        DateOfMarriage: dateString,
      });
    } else {
      setEditMarriage({
        ...editMarriage,
        DateOfMarriage: '',
      });
    }
  };

  const handleDateOfDevorceChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      // Hier das Datum in einen deutschen Datumsstring umwandeln
      const dateString = newValue.format('YYYY-MM-DD'); // Deutsches Datumsformat
      setEditMarriage({
        ...editMarriage,
        DateOfDevorce: dateString,
      });
    } else {
      setEditMarriage({
        ...editMarriage,
        DateOfDevorce: '',
      });
    }
  };

  return (
    <>
      <DialogResponsive
        open={isOpen}
        onClose={handleClose}
        maxWidth='lg'
        title='Ehe'
        actions={
          <>
            <Button variant='outlined' onClick={handleClose}>
              Abbruch
            </Button>
            <Button
              variant='contained'
              disabled={checkIsDisabled(inheritObject, isDeath, editPerson)}
              onClick={handleSave}
            >
              Speichern
            </Button>
          </>
        }
      >
        <form onSubmit={handleSubmit}>
          <Box sx={{ mt: 2, mb: 6 }}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale='de'
                >
                  <DatePicker
                    label='Datum der Eheschließung'
                    maxDate={currentYear}
                    openTo='year'
                    views={['year', 'month', 'day']}
                    value={
                      editMarriage.DateOfMarriage
                        ? dayjs(editMarriage.DateOfMarriage)
                        : null
                    }
                    onChange={handleDateOfMarriageChange}
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                        required: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item sm={12}>
                <FormControlLabel
                  sx={{ float: 'left' }}
                  control={
                    <Switch
                      checked={editMarriage.isDivorced}
                      onChange={() => {
                        if (editMarriage.isDivorced === true) {
                          setEditMarriage({
                            ...editMarriage,
                            DateOfDevorce: null,
                            isDivorced: !editMarriage.isDivorced,
                          });
                        } else {
                          setEditMarriage({
                            ...editMarriage,
                            isDivorced: !editMarriage.isDivorced,
                          });
                        }
                      }}
                      name='gilad'
                    />
                  }
                  label='Geschieden'
                />
              </Grid>
              {editMarriage.isDivorced && (
                <Grid item sm={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale='de'
                  >
                    <DatePicker
                      label='Datum der Scheidung'
                      maxDate={currentYear}
                      openTo='year'
                      views={['year', 'month', 'day']}
                      value={
                        editMarriage.DateOfDevorce
                          ? dayjs(editMarriage.DateOfDevorce)
                          : null
                      }
                      onChange={handleDateOfDevorceChange}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
            </Grid>
          </Box>

          <InheritPerson
            currentPerson={editPerson}
            setCurrentPerson={setEditPerson}
            inheritObject={inheritObject}
            personArray={personArray}
            personHasDocument={personHasDocument}
            setPersonHasDocument={setPersonHasDocument}
            requiredFiles={getRequirredArray()}
            title='Ehepartner'
            showDeathSettings={true}
            setIsDeath={setIsDeath}
          />
        </form>
      </DialogResponsive>

      <TableRow>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          {currentMarriage.DateOfMarriage !== '' &&
          currentMarriage.DateOfMarriage !== undefined &&
          currentMarriage.DateOfMarriage !== null
            ? new Date(currentMarriage.DateOfMarriage).toLocaleDateString(
                'de-DE',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }
              )
            : 'k.A'}
        </TableCell>
        <TableCell>
          {!currentMarriage.isDivorced
            ? '-'
            : currentMarriage.DateOfDevorce !== '' &&
                currentMarriage.DateOfDevorce !== undefined &&
                currentMarriage.DateOfDevorce !== null
              ? new Date(currentMarriage.DateOfDevorce).toLocaleDateString(
                  'de-DE',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }
                )
              : 'k.A'}
        </TableCell>
        <TableCell>
          {`${currentPerson.FirstName} ${currentPerson.LastName}`}
        </TableCell>
        <TableCell>
          <IconButton onClick={handleOpen}>
            <Edit />
          </IconButton>
          <IconButton onClick={handleRemove}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
