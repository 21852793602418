import React from 'react';
import { IDocument } from '../../../../../Interfaces/IDocument';
import { Box, Button, Typography } from '@mui/material';
import { DocumentOverview } from '../../../../Document/DocumentOverview';

interface IProps {
  handleBack: Function;
  handleNext: Function;
  documentArray: IDocument[];
  setDocumentArray: Function;
}

export const PurchaseContractDocuments: React.FC<IProps> = props => {
  return (
    <>
      <Box sx={{ mt: 5 }} />

      <Typography variant='h4'>Dokumente</Typography>
      <Typography sx={{ mb: 3 }}>
        Bitte laden Sie die folgenden Dokumente (sofern zutreffend bzw.
        vorhanden) hoch:
        <ul>
          <li>
            Vollmachten oder Betreuerausweise (bei gesetzlicher/gewillkürter
            Vertretung)
          </li>
          <li>
            Erbscheine/Erbnachweise (bei Veräußerung/Beteiligung von
            Erbengemeinschaften)
          </li>
          <li>
            Selbstauskunft nach dem GwG bei Beteiligung von Gesellschaften
          </li>
          <li>
            Lageplan und Angaben zur Aufteilung der Vermessungskosten bei
            Veräußerung von Teilflächen
          </li>
          <li>Darlehensnummer, Restschuld bei abzulösenden Banken</li>
        </ul>
      </Typography>

      <DocumentOverview
        allowRemove
        allowUpload
        documentArray={props.documentArray}
        setDocumentArray={props.setDocumentArray}
        title=''
      />

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          variant='outlined'
          onClick={() => props.handleBack()}
          sx={{ mr: 1 }}
        >
          Zurück
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          type='submit'
          variant='contained'
          onClick={() => props.handleNext()}
        >
          Weiter
        </Button>
      </Box>
    </>
  );
};
