/* eslint-disable react/prop-types */
import React from 'react';
import {
  getBezierPath,
  getMarkerEnd,
  EdgeLabelRenderer,
  EdgeProps,
  MarkerType,
} from 'reactflow';
import { HeartBroken, Favorite } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { formatDate } from '../../../../../../core/standardFunctions';

const FormatMarriageEdges: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  const edgeStyle = {
    ...style,
    strokeDasharray: data?.isDivorced ? '10' : 'none', // gestrichelt wenn isDevorce true, sonst normal
  };

  return (
    <>
      <path
        id={id}
        style={edgeStyle}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={getMarkerEnd(markerEnd as MarkerType)}
      />
      {data && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 10,
              pointerEvents: 'all',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className='nodrag nopan'
          >
            <text
              x={labelX}
              y={labelY}
              fill='black'
              fontSize='12'
              textAnchor='middle'
            >
              <Box display='flex' alignItems='center'>
                <Favorite fontSize='small' />
                <Typography variant='body2' marginLeft={1}>
                  {formatDate(data.DateOfMarriage)}
                </Typography>
              </Box>
              {data.DateOfDevorce && (
                <Box display='flex' alignItems='center'>
                  <HeartBroken fontSize='small' />
                  <Typography variant='body2' marginLeft={1}>
                    {formatDate(data.DateOfDevorce)}
                  </Typography>
                </Box>
              )}
            </text>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default FormatMarriageEdges;
